import React from 'react';
import { Collapse, Button } from 'antd';
import './about.scss'; 
import FaqData  from '../../data/faq.data';
import { addEvent } from '../../services/event.service'
import EventType from '../../enums/eventType.enum'
import FlowType from '../../enums/flowType.enum'
import { useNavigate } from 'react-router-dom';
import { FaqCategory } from '../../enums/faqCategory.enum'

export const AboutComponent = () => {
    const navigate = useNavigate()

    const getStarted = async () => {

        const url = `/flow/${FlowType.MEMBER}`
    
        try {
          await addEvent({ 
            eventType: EventType.CLICK_HOME_MAIN_CTA,
            meta: {
              url: url
            }
          });
        } catch (error) {
          console.error('Failed to log message to Slack:', error);
        }
    
        navigate(url)
    }

    const chatWithUs = () => {
        window.FrontChat("show")
    }

    return (
        <div className="about-page">


            <div className="mission-section section">
                <div className="max-width">
                    <div className="section-header">    
                        <h2>About Instalab</h2>
                        <div className="section-subheader">
                            Instalab makes proactive health easy and accessible.
                        </div>
                    </div>

                    <div className="section-content manifesto">  
                        <p className="manifesto-statement">
                            At Instalab, we believe <strong>healthcare should empower you</strong>—not overwhelm you. 
                            While the system may be complex, it’s also filled with incredible opportunities to 
                            <em> optimize your health and thrive</em>.
                        </p>
                        <p className="manifesto-statement">
                           We’re here to guide you on a <strong>proactive health journey</strong>, helping you navigate 
                           the system with ease so you can unlock its full potential. From getting you access to<em> advanced testing</em> 
                           {" "}and <em> personalized treatments</em> to <em>expert insights</em>, we make it simple to stay 
                           ahead of your health.
                        </p>
                        <p className="manifesto-statement">
                            Our mission is to shift the focus from <strong>reacting to illness</strong> to 
                            <strong> building lasting wellness</strong>. With Instalab, you’re not just managing your 
                            health—you’re <em>taking charge of it</em>.
                        </p>
                    </div>
                </div>
                <div className="section-cta">
                    <Button
                        type="primary"
                        size="large"
                        onClick={getStarted}
                        className="main-cta-btn"
                    >
                        Get Started Now
                    </Button>
                    </div>
            </div>
                
            <div className="faq-section section">
                <div className="max-width">
                    <div className="section-header">
                    <h2>Frequently asked questions</h2>
                    <div className="section-subheader">
                        If you have any questions, <span className="chat-link" onClick={chatWithUs}>chat with us</span>.
                    </div>
                    </div>

                    <div className="faq-grid">
                    <Collapse 
                        bordered={false}
                        expandIconPosition="end"
                    >
                        {FaqData.filter(faq => faq.categories.some(category => category === FaqCategory.ABOUT)).map((faq, index) => (
                        <Collapse.Panel 
                            key={index} 
                            header={faq.question}
                        >
                            <p className="faq-answer">{faq.answer}</p>
                        </Collapse.Panel>
                        ))}
                    </Collapse>
                    </div>

    
                </div>
            </div>


        
        </div>  
    );
};