import { Button }  from 'antd'
import LatestPosts from "../latestPosts/latestPosts.component"
import Panels from "../panels/panels.component"

export const WaitlistConfirm = () => {
  return (
    <div className="confirm-intake">
      <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button>

      <Panels />

      <LatestPosts />
    </div>
  )
}