import { Button, Col, Row, Carousel, Collapse } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useRef, useEffect, useContext, useState } from 'react'
import './home-v2.scss'
import FlowType from '../../enums/flowType.enum'
import { BlogData } from '../../data/blog.data'
import { ArrowRightOutlined, DownOutlined, CheckOutlined, BarChartOutlined, UserOutlined, CalendarOutlined, CreditCardOutlined } from '@ant-design/icons';
import { SubscribeModal } from "../subscribeModal/subscribeModal.component";
import { addEvent } from '../../services/event.service'
import EventType from '../../enums/eventType.enum'
import FaqData from '../../data/faq.data'
import TestimonialData from '../../data/testimonials.data'
import BlogId from '../../enums/blogId.enum'
import FaqCategory from '../../enums/faqCategory.enum'
import ExampleData from '../../data/example.data'
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { ReactComponent as DoctorIcon } from "../../assets/svg/doctor-2.svg"

export const HomeV2 = () => {
  const navigate = useNavigate()
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const carouselRef = useRef();
  const videoRefs = useRef([]);
  const [visibleExamples, setVisibleExamples] = useState(() => window.innerWidth <= 768 ? 3 : 6);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showGetStarted, setShowGetStarted] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setVisibleExamples(window.innerWidth <= 768 ? 3 : 6);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleViewMore = () => {
    setVisibleExamples(ExampleData.length);
  };

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.querySelector('.introduction');
      if (heroSection) {
        const heroSectionHeight = heroSection.offsetHeight;
        const scrollPosition = window.scrollY;
        setShowGetStarted(scrollPosition > heroSectionHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const joinWaitlist = async () => {
    const url = `/flow/${FlowType.WAITLIST}`
    try {
      await addEvent({ 
        eventType: EventType.CLICK_HOME_JOIN_WAITLIST,
        meta: {
          url: url
        }
      });
    } catch (error) {
      console.error('Failed to log message to Slack:', error);
    }
    navigate(url)
  }

  const getStarted = async () => {

    const url = `/flow/${FlowType.MEMBER}`

    try {
      await addEvent({ 
        eventType: EventType.CLICK_HOME_MAIN_CTA,
        meta: {
          url: url
        }
      });
    } catch (error) {
      console.error('Failed to log message to Slack:', error);
    }

    navigate(url)
  }

  const videos = [
    '/video/statin-min.m4v',
    '/video/galleri-min.m4v',
    '/video/plan-min.m4v',
  ];

  const onVideoEnded = (index) => {
    if (carouselRef.current) {
      if (index === videos.length - 1) {
        carouselRef.current.goTo(0);
      } else {
        carouselRef.current.next();
      }
    }
  };

  const onSlideChange = (current) => {
    videoRefs.current.forEach(video => {
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    });

    if (videoRefs.current[current]) {
      videoRefs.current[current].play();
    }
  };

  const chatWithUs = () => {
    window.FrontChat("show")
}

  const transformationData = [
    {
      title: "Longevity Focused",
      before: "Not getting long-term health guidance?",
      after: "Instalab pairs you with a physician who specializes in preventive medicine and longevity.",
      icon: <DoctorIcon className="anticon" />
    },
    {
      title: "Proactive Planning",
      before: "Not sure what tests to get to stay proactive?",
      after: "Instalab creates custom testing plans to keep your health on track.",
      icon: <BarChartOutlined />
    },
    {
      title: "Physician Approvals",
      before: "Not getting the tests and doctor referrals you want?",
      after: "Instalab quickly approves any test or referral.",
      icon: <CheckOutlined />
    },
    {
      title: "Understanding Results", 
      before: "Feeling lost trying to understand test results?",
      after: "Instalab provides clear explanations and actionable steps after each test.",
      icon: <BarChartOutlined />
    },
    {
      title: "Finding Doctors",
      before: "Endlessly searching for the right doctor?",
      after: "Instalab connects you with doctors who fit your specific needs.",
      icon: <UserOutlined />
    },
    {
      title: "Booking Appointments",
      before: "Playing phone tag with medical offices?",
      after: "Instalab handles all your scheduling needs.",
      icon: <CalendarOutlined />
    },

    {
      title: "Insurance Help",
      before: "Confused by what your insurance covers?",
      after: "Instalab manages prior authorizations and approvals.",
      icon: <CreditCardOutlined />
    },

  ];

  return (
    <>
     <div className="home-v2">

        
        <SubscribeModal
          open={openSubscribe}
          setOpen={setOpenSubscribe}
        />

        <div className="introduction">
          <div className="max-width">
            <Row 
              gutter={48}
              className="introduction-row"
            >
              <Col 
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 15 }}
                xl={{ span: 15 }}
                xxl={{ span: 15 }}
                className="introduction-main-col"
              >
                <div className="introduction-title">
                  Your Personal Health Concierge
                </div>

                <div className="introduction-description">
                From booking tests to finding the right doctor to explaining results, Instalab handles the details so you can focus on achieving your health goals.
                </div>

                <div className="introduction-pricing">
                Just <span className="highlight">$49/month</span> or <span className="highlight">$499/year</span>.
                </div>


                <div className="main-cta-btn-wrapper">

                  {/* <Button
                    className="main-cta-btn"
                    type="primary"
                    onClick={getStarted}
                >
                    Get Started Now
                  </Button> */}

                  <Button
                    className="main-cta-btn"
                    type="primary"
                    onClick={joinWaitlist}
                >
                    Join Waitlist
                  </Button>

                  <div className="main-cta-btn-footer">
                  Trusted by high-performing executives, entrepreneurs, and professional athletes
                  </div>
                </div>
              </Col>

              <Col 
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 9 }}
                xl={{ span: 9 }}
                xxl={{ span: 9 }}
                className="introduction-side-col"
              >
                <div className="phone-container">
                  <div className="phone-frame">
                    <Carousel 
                      effect="fade" 
                      ref={carouselRef}
                      autoplay={false}
                      afterChange={onSlideChange}
                    >
                      {videos.map((video, index) => (
                        <div key={`video-${index}`} className="video-slide">
                          <video
                            ref={el => videoRefs.current[index] = el}
                            key={`video-player-${index}`}
                            autoPlay
                            muted
                            playsInline
                            className="phone-video"
                            onEnded={() => onVideoEnded(index)}
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                          >
                            <source src={video} type="video/mp4" />
                          </video>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>


        <div className="benefits-section section">
          <div className="max-width">
            <div className="section-header">
              <h2>Transform your healthcare experience</h2>
              <p className="section-subheader">
                Tired of managing your healthcare on your own? Here's how Instalab makes it easier:
              </p>
            </div>

            <div className="benefits-container">
              <div className="benefit-list">
                <div className="transformation-header">
                  <div className="header-title"></div>
                  <div className="header-before">
                    <CloseCircleFilled className="before-icon" /> No More of This
                  </div>
                  <div className="header-arrow"></div>
                  <div className="header-after">
                    <CheckCircleFilled className="after-icon" /> Now You Have This
                  </div>
                </div>
                {transformationData.map((item, index) => (
                  <div key={index} className="transformation-item">
                    <div className="transformation-row">
                      <div className="col-title">{item.icon} {item.title}</div>
                      <div className="col-before"><CloseCircleFilled className="before-icon" /> {item.before}</div>
                      <div className="col-arrow">→</div>
                      <div className="col-after"><CheckCircleFilled className="after-icon" /> {item.after}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="benefits-cta">

              {/* <Button
                type="primary"
                size="large"
                onClick={getStarted}
                className="main-cta-btn"
              >
                Get Started Now
              </Button> */}
            </div>
          </div>
        </div>


        <div className="social-proof-section section">
          <div className="max-width">
            <div className="section-header">
              <h2>Why top athletes and executives trust Instalab</h2>
              
            </div>
          </div>
          <div className="trusted-by">
            {/* <div className="trusted-by-label">Trusted by teams and individuals from</div> */}
            <div className="logo-scroll">
              <div className="logo-scroll-content">
                <img src="/img/logos/mlb.png" alt="MLB" />
                <img src="/img/logos/google.png" alt="Google" />
                <img src="/img/logos/jpmorgan.png" alt="JPMorgan" />
                <img src="/img/logos/linkedin.png" alt="LinkedIn" />
                <img src="/img/logos/nfl.png" alt="NFL" />
                <img src="/img/logos/amazon.png" alt="Amazon" />
                <img src="/img/logos/uber.png" alt="Lyft" />
              </div>
              <div className="logo-scroll-content">
                <img src="/img/logos/mlb.png" alt="MLB" />
                <img src="/img/logos/google.png" alt="Google" />
                <img src="/img/logos/jpmorgan.png" alt="JPMorgan" />
                <img src="/img/logos/linkedin.png" alt="LinkedIn" />
                <img src="/img/logos/nfl.png" alt="NFL" />
                <img src="/img/logos/amazon.png" alt="Amazon" />
                <img src="/img/logos/uber.png" alt="Lyft" />
              </div>
            </div>
          </div>

          <div className="max-width">
            <div className="metrics-grid">

              <div className="metric-card">
                <div className="metric-number">24hr</div>
                <div className="metric-label">Average turnaround on requests</div>
              </div>

              <div className="metric-card">
                <div className="metric-number">7hrs+</div>
                <div className="metric-label">Average time saved per month</div>
              </div>

              <div className="metric-card">
                <div className="metric-number">100+</div>
                <div className="metric-label">Best-in-class diagnostics available</div>
              </div>

              <div className="metric-card">
                <div className="metric-number">3000+</div>
                <div className="metric-label">Network of top medical specialists & facilities</div>
              </div>
              <div className="metric-card">
                <div className="metric-number">18k+</div>
                <div className="metric-label">High-achieving patients supported nationwide</div>
              </div>

              <div className="metric-card">
                <div className="metric-number">83%</div>
                <div className="metric-label">Patients improved health outcomes within 6 months</div>
              </div>

              <div className="metric-card">
                <div className="metric-number">91%</div>
                <div className="metric-label">Patients discovered new hidden health risks</div>
              </div>

                  <div className="metric-card">
                <div className="metric-number">99%</div>
                <div className="metric-label">Patient satisfaction rate</div>
              </div>


            </div>

            {/* <div className="section-cta">
              <Button
                type="primary"
                size="large"
                onClick={getStarted}
                className="main-cta-btn"
              >
                Get Started Now
              </Button>
            </div> */}
          </div>


        </div>

        <div className="examples-section section">
          <div className="max-width">
            <div className="section-header">
              <h2>See Instalab in action</h2>
              <p className="section-subheader">Recent tasks completed by Instalab</p>
            </div>

            <div className="tasks-grid">
              {ExampleData.slice(0, visibleExamples).map((example, index) => (
                <div key={index} className="task-card">
                  <div className="task-header">
                    <div className="task-icon">
                      {example.icon}
                    </div>
                    <div className="task-status">
                      <span className="completed-badge">{example.category}</span>
                  </div>
                </div>
                <div className="task-content">
                {example.description}
                </div>
              </div>
              ))}
              
            </div>
            {visibleExamples < ExampleData.length && (
                <div className="view-more section-cta">
                  <Button 
                    onClick={handleViewMore}
                    className="secondary-cta-btn"
                  >
                    View More <DownOutlined className="view-btn-icon" />
                  </Button>
                </div>
              )}
          </div>
        </div>


        <div className="testimonials-section section">
          <div className="max-width">
            <div className="section-header">
              <h2>Real patients with real results</h2>
              <p className="section-subheader">
                Join the fast-growing club of high-performing professionals who trust Instalab with their health.
              </p>
            </div>

            <div className="testimonials-grid">
              {TestimonialData.map((testimonial, index) => (
                <div key={index} className="testimonial-card">
                  <div className="testimonial-content">
                    <p>"{testimonial.text}"</p>
                  </div>
                  <div className="testimonial-author">
                    <div className="author-image">
                      <picture>
                        <source srcSet={testimonial.webp} type="image/webp" />
                        <img src={testimonial.image} alt={testimonial.name} />
                      </picture>
                    </div>
                    <div className="author-info">
                      <h4>{testimonial.name}</h4>
                      <p>{testimonial.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="testimonials-cta">
                {/* <Button
                type="primary"
                size="large"
                onClick={getStarted}
                className="main-cta-btn"
              >
                Join Them Today
              </Button>  */}



            </div>
          </div>
        </div>

        <div className="not-ready-section section">
          <div className="max-width">
            <div className="section-header">
              <h2>Not ready to commit?</h2>
              <p className="section-subheader">
                Explore our ala-carte menu of tests.
              </p>
            </div>

            <div className="not-ready-content">
              <p className="description">
                We understand that memberships aren't for everyone. That's why Instalab offers convenient a-la-carte options for your specific needs—pay only for the test you want.
              </p>
              
              <div className="section-cta">
              <Button
                type="default"
                size="large"
                onClick={() => navigate('/catalog')}
                className="browse-btn"
              >
                Browse Catalog <ArrowRightOutlined />
              </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="tools-section section">
          <div className="max-width">
            <div className="section-header">
              <h2>Free resources to take charge of your health</h2>
              <p className="section-subheader">
                Start optimizing your health today with our tools and research
              </p>
            </div>

            <div className="tools-grid">
              {BlogData.filter(blog => [BlogId.CALCULATOR, BlogId.CAC, BlogId.DEXA].includes(blog.id)).map((blog, index) => (
                <div key={index} className="tool-card" onClick={() => navigate(`/blog/${blog.id}`)}>
                  {blog.img && (
                    <div className="tool-image">
                      <img src={blog.img} alt={blog.title} />
                    </div>
                  )}
                  <div className="tool-content">
                    <h3>{blog.title}</h3>
                    <p>{blog.readTime}</p>
                    <div className="tool-description">{blog.description}</div>
                  </div>
                </div>
              ))}
            </div>

            <div className="section-cta">
            <Button
                type="default"
                size="large"
                onClick={() => navigate('/research')}
                className="secondary-cta-btn"
              >
                Browse Research <ArrowRightOutlined />
              </Button>
            </div>
          </div>
        </div>

        <div className="faq-section section">
          <div className="max-width">
            <div className="section-header">
              <h2>Frequently asked questions</h2>
              <div className="section-subheader">
                        If you have any questions, <span className="chat-link" onClick={chatWithUs}>chat with us</span>.
                    </div>
            </div>

            <div className="faq-grid">
              <Collapse 
                bordered={false}
                expandIconPosition="end"
              >
                {FaqData.filter(faq => faq.categories.includes(FaqCategory.CONCIERGE)).map((faq, index) => (
                  <Collapse.Panel 
                    key={index} 
                    header={faq.question}
                  >
                    <p className="faq-answer">{faq.answer}</p>
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>

            {/* <div className="section-cta">
              <Button
                type="primary"
                size="large"
                onClick={getStarted}
                className="main-cta-btn"
              >
                Get Started Now
              </Button>
            </div> */}
          </div>
        </div>

        <div className="final-cta-section section">
          <div className="max-width">
            <div className="section-header">
              <h2>Streamline your health with Instalab</h2>
              <div className="section-subheader">
                Join the tens of thousands of others who trust Instalab to manage their health with ease.
              </div>

            </div>

            <div className="section-cta">
              {/* <Button
                type="primary"
                size="large"
                onClick={getStarted}
                className="main-cta-btn"
              >
                Get Started Now
              </Button> */}

              <Button
                type="primary"
                size="large"
                onClick={joinWaitlist}
                className="main-cta-btn"
              >
                Join Waitlist
              </Button>
            </div>
          </div>
        </div>

        
        {showGetStarted && isMobile && (
          <div className="get-started-section section" style={{backgroundColor: 'transparent'}}>

            <div className="section-cta">

              <Button
                type="primary"
                className="main-cta-btn"
                onClick={joinWaitlist}
              >
                Join Waitlist <ArrowRightOutlined style={{ marginLeft: '8px' }} />
              </Button>
            </div>
          </div>
        )}

      </div>
    </>
  )
}
