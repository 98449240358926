import React, { useEffect, useState, useContext } from "react";
import { Button, Table, Modal, Input, Form, Select, message, Switch } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import PatientHelper from "../../helpers/patient.helper";
import "./adminProfile.scss";
import { PageHeader } from "../pageHeader/pageHeader.component";
import { AdminLocationModal } from "../adminLocationModal/adminLocationModal.component";
import ObjectType from "../../enums/objectType.enum";
import { updatePatient } from "../../services/patient.service";
import MaskedInput from 'antd-mask-input'
import UserHelper from "../../helpers/user.helper";
import { UserContext } from "../../contexts/user.context";
import FileLink from "../fileLink/fileLink.component";
import { TransferModal } from "../transferModal/transferModal.component";
import axios from 'axios';
import KlaviyoService from "../../services/klaviyo.service.js";
const { Column } = Table;

const select = ''
const populate = [{path:'memberships', populate: [{path:'membershipType'}]}]

export const PhoneEditModal = ({ open, setOpen, patient, onSuccess }) => {
  const [form] = Form.useForm(); // Create form instance

  useEffect(() => {
    // Reset phone number in form when the modal opens
    if (open) {
      form.setFieldsValue({ phone: patient?.phone || "" });
    }
  }, [open, patient, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const updatedPatient = await updatePatient(patient._id, {
        fields: { phone: values.phone },
        select,
        populate
      });
      setOpen(false);
      onSuccess(updatedPatient); // Ensure onSuccess is passed the updated patient
    } catch (error) {
      console.error("Failed to update phone number:", error);
      message.error(error.response?.data?.message || "Failed to update phone number");
    }
  };

  return (
    <Modal
      title="Edit Phone Number"
      visible={open}
      onCancel={() => setOpen(false)}
      onOk={handleSave}
      okText="Save"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please enter a phone number",
            },
            {
              pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
              message:
                "Please enter a valid phone number format: (###) ###-####",
            },
          ]}
        >
          <MaskedInput mask="(000) 000-0000" placeholder="Enter new phone number" />
        </Form.Item>
      </Form>
    </Modal>
  );

};

export const BasicFieldEditModal = ({ open, setOpen, patient, fieldName, onSuccess }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      const initialValue = fieldName === 'name' 
        ? { firstName: patient?.firstName, lastName: patient?.lastName }
        : { [fieldName]: patient?.[fieldName] };
      form.setFieldsValue(initialValue);
    }
  }, [open, patient, fieldName, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const updateData = fieldName === 'name'
        ? { fields: { firstName: values.firstName, lastName: values.lastName },
        select,
        populate }
        : { 
            fields: { 
              [fieldName]: values[fieldName] 
            },
            select,
            populate
          };
      
      const updatedPatient = await updatePatient(patient._id, updateData);

      setOpen(false);
      onSuccess(updatedPatient);
    } catch (error) {
      console.error("Failed to update field:", error);
      if (error.response?.data?.code === 11000) {
        message.error('Account already exists with this email')
      } else {
        message.error(`Failed to update ${fieldName}`);
      }
    }
  };


  const renderFormItem = () => {
    switch (fieldName) {
      case 'name':
        return (
          <>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true, message: "Please enter first name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true, message: "Please enter last name" }]}
            >
              <Input />
            </Form.Item>
          </>
        );
      case 'gender':
        return (
          <Form.Item
            name="gender"
            label="Biological Sex"
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option value="Male">Male</Select.Option>
              <Select.Option value="Female">Female</Select.Option>
            </Select>
          </Form.Item>
        );
      case 'dob':
        return (
          <Form.Item
            name="dob"
            label="Date of Birth"
            rules={[
              { required: true, message: "Please enter date of birth" },
              {
                pattern: /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/,
                message: "Please enter a valid date format: MM/DD/YYYY"
              }
            ]}
          >
            <MaskedInput 
              mask="00/00/0000" 
              placeholder="MM/DD/YYYY"
            />
          </Form.Item>
        );
      case 'email':
        return (
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true },
              { type: 'email', message: 'Please enter a valid email' }
            ]}
          >
            <Input />
          </Form.Item>
        );
      case 'isAthlete':
        return (
          <Form.Item
            name="isAthlete"
            label="Professional Athlete"
          >
            <Select>
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const getModalTitle = () => {
    if (!fieldName) return 'Edit Field';
    return `Edit ${fieldName === 'name' ? 'Name' : fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`;
  };

  return (
    <Modal
      title={getModalTitle()}
      visible={open}
      onCancel={() => setOpen(false)}
      onOk={handleSave}
      okText="Save"
    >
      <Form form={form} layout="vertical">
        {renderFormItem()}
      </Form>
    </Modal>
  );
};

export const AdminProfile = ({ patient, setPatient }) => {
  const [fieldName, setFieldName] = useState(null);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [profile, setProfile] = useState([]);
  const [isBasicFieldModalOpen, setIsBasicFieldModalOpen] = useState(false);
  const { currentUser } = useContext(UserContext);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  useEffect(() => {
    if (!patient) return;
    document.title = `${patient.firstName} ${patient.lastName} - Profile | Instalab`;

    const fetchProfileData = async () => {
      try {
        const weight = await PatientHelper.getWeight(patient);
        
        // Ensure we have location data before rendering
        const physicalLocation = patient.location ? getLocation(patient.location) : '-';
        const shippingLocation = patient.shippingLocation ? getLocation(patient.shippingLocation) : '-';

        let profileData = [
          { 
            key: 'Name', 
            value: (
              <>
                {`${patient.firstName || '-'} ${patient.lastName || ''}`}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('name');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Biological sex', 
            value: (
              <>
                {patient.gender || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('gender');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Date of birth', 
            value: (
              <>
                {patient.dob || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('dob');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Email', 
            value: (
              <>
                {patient.email || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('email');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Phone', 
            value: ( 
              <>
                {patient.phone || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('phone');
                    setIsPhoneModalOpen(true);
                  }}
                />
              </>
            ) 
          },
          { 
            key: 'Physical Address', 
            value: (
              <>
                {physicalLocation}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('location');
                    setIsLocationModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Shipping Address', 
            value: (
              <>
                {shippingLocation}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('shippingLocation');
                    setIsLocationModalOpen(true);
                  }}
                />
              </>
            )
          },
          { key: 'Height', value: patient.height ? `${patient.height} in` : '-' },
          { key: 'Weight', value: patient.weight ? `${weight} lbs` : '-' },

          { 
            key: 'Professional Athlete', 
            value: (
              <>
                {patient.isAthlete ? "Yes" : "No"}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('isAthlete');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          }
        ];

        // Add credit and cash balance rows only for admins
        if (UserHelper.isAdmin(currentUser)) {
          profileData.push(
            { 
              key: 'Credits', 
              value: (
                <>
                  {patient.credits && patient.credits.length > 0 
                    ? (() => {
                        const groupedCredits = patient.credits.reduce((acc, credit) => {
                          if (acc[credit]) {
                            acc[credit] = acc[credit] + 1;
                          } else {
                            acc[credit] = 1;
                          }
                          return acc;
                        }, {});
                        
                        return Object.keys(groupedCredits)
                          .map(credit => `${credit} x ${groupedCredits[credit]}`)
                          .join(', ');
                      })()
                    : '-'
                  }
                  <Button
                    icon={<PlusOutlined />}
                    className="edit-btn"
                    onClick={() => {
                      setIsTransferModalOpen(true);
                    }}
                  />
                </>
              )
            },
            { 
              key: 'Cash Balance', 
              value: `$${(patient.cashBalance || 0).toFixed(2)}`
            }
          );
        }

        if (UserHelper.isAdmin(currentUser) || UserHelper.isProvider(currentUser) || UserHelper.isStaff(currentUser)) {
          profileData.push({
            key: 'Insurance Card',
            value: (
              <>
                {patient.frontInsuranceCard ? <FileLink fileId={patient.frontInsuranceCard} /> : ''}
                {' '}
                {patient.backInsuranceCard ? <FileLink fileId={patient.backInsuranceCard} /> : ''}

                {!patient.frontInsuranceCard && !patient.backInsuranceCard && <>-</>}
              </>
            )
          },
          { 
            key: 'Instalab News Emails', 
            value: <Switch
              checked={patient.email_marketing}
              onChange={(checked) => handleEmailToggle('email_marketing', checked)}
              checkedChildren="Subscribed"
              unCheckedChildren="Unsubscribed"
              size="small"
            />
          },
          { 
            key: 'Instalab Brief Emails', 
            value: <Switch
              checked={patient.email_newsletter}
              onChange={(checked) => handleEmailToggle('email_newsletter', checked)}
              checkedChildren="Subscribed"
              unCheckedChildren="Unsubscribed"
              size="small"
            />
          }
        )
        }

        setProfile(profileData);
      } catch (error) {
        console.error('Error fetching profile data:', error);
        message.error('Failed to load profile data');
      }
    };

    fetchProfileData();
  }, [patient]);


  const refreshLocationData = async (updatedPatient) => {
    const physicalLocation = updatedPatient.location ? getLocation(updatedPatient.location) : 'No address set';
    const shippingLocation = updatedPatient.shippingLocation ? getLocation(updatedPatient.shippingLocation) : 'No address set';

    setProfile(prevProfile => prevProfile.map(item => {
      if (item.key === 'Physical Address') {
        return {
          ...item,
          value: (
            <>
              {physicalLocation}
              <Button
                icon={<EditOutlined />}
                className="edit-btn"
                onClick={() => {
                  setFieldName('location');
                  setIsLocationModalOpen(true);
                }}
              />
            </>
          )
        };
      }
      if (item.key === 'Shipping Address') {
        return {
          ...item,
          value: (
            <>
              {shippingLocation}
              <Button
                icon={<EditOutlined />}
                className="edit-btn"
                onClick={() => {
                  setFieldName('shippingLocation');
                  setIsLocationModalOpen(true);
                }}
              />
            </>
          )
        };
      }
      return item;
    }));
  };

  const handlePatientUpdate = (updatedPatient) => {
    if (fieldName === 'location' || fieldName === 'shippingLocation') {
      // For location updates, just refresh the display
      refreshLocationData(updatedPatient);
    } else {
      // For other fields, update both patient state and display
      setPatient(updatedPatient);
      refreshLocationData(updatedPatient);
    }
  };

  const onTransferSuccess = (transfer) => {
    setPatient(prevPatient => ({
      ...prevPatient,
      credits: [
        ...(prevPatient.credits || []),
        // If transfer.plan is an array, spread it, otherwise use as single value
        ...(Array.isArray(transfer.plan) ? transfer.plan : [transfer.plan || transfer.productTypeCode])
      ]
    }));
  };

  const getLocation = (location) => {
    if (!location) return 'No address set';
    const { streetAddress, streetAddress2, city, state, postalCode } = location;
    if (!streetAddress || !city || !state || !postalCode) return 'Incomplete address';
    
    let address = streetAddress;
    if (streetAddress2) address += ` ${streetAddress2}`;
    return `${address}, ${city}, ${state} ${postalCode}`;
  };

  const handleEmailToggle = async (field, checked) => {
    try {
      // Update the backend
      await updatePatient(patient._id, {
        fields: {
          [field]: checked
        },
      });
      
      // Update local state
      setPatient(prev => ({
        ...prev,
        [field]: checked
      }));

      // Sync with Klaviyo
      try {
        const listType = field === 'email_marketing' ? 'marketing' : 'newsletter';
        if (checked) {
          await KlaviyoService.addToKlaviyo({ 
            email: patient.email, 
            firstName: patient.firstName, 
            lastName: patient.lastName, 
            listType: listType 
          });
        } else {
          await KlaviyoService.removeFromKlaviyo({ 
            email: patient.email, 
            listType: listType 
          });
        }
      } catch (error) {
        console.error('Failed to sync with Klaviyo:', error);
      }

      // Show success message
      const fieldName = field === 'email_marketing' ? 'Marketing Emails' : 'Instalab Brief Emails';
      message.success(`Successfully ${checked ? 'subscribed to' : 'unsubscribed from'} ${fieldName}`);
    } catch (error) {
      console.error('Error updating email preferences:', error);
      message.error('Failed to update. Please try again.');
      
      // Revert the patient state on error
      setPatient(prev => ({
        ...prev,
        [field]: !checked
      }));
    }
  };

  return (
    <div className="admin-profile">
      <PageHeader title='Profile' />

      <AdminLocationModal
        objectId={patient?._id}
        objectType={ObjectType.PATIENT}
        fieldName={fieldName}
        open={isLocationModalOpen}
        setOpen={setIsLocationModalOpen}
        onSuccess={handlePatientUpdate}
      />

      <PhoneEditModal
        open={isPhoneModalOpen}
        setOpen={setIsPhoneModalOpen}
        patient={patient}
        onSuccess={handlePatientUpdate}
      />

      <BasicFieldEditModal
        open={isBasicFieldModalOpen}
        setOpen={setIsBasicFieldModalOpen}
        patient={patient}
        fieldName={fieldName}
        onSuccess={handlePatientUpdate}
      />

      <TransferModal
        userId={patient?._id}
        open={isTransferModalOpen}
        setOpen={setIsTransferModalOpen}
        onSuccess={onTransferSuccess}
      />

      {profile.length > 0 && (
        <div className="profile-section">
          <Table
            loading={!profile.length}
            className='primary-table'
            pagination={false}
            rowKey='key'
            dataSource={profile}
            showHeader={false}
          >
            <Column dataIndex='key' width={150} />
            <Column dataIndex='value' render={(value) => value} />
          </Table>
        </div>
      )}
    </div>
  );
};
