const FlowType = Object.freeze({
  // Memberships
  ULTIMATE_MEMBERSHIP: 'ultimate',
  MEMBER: "member",
  BASIC_MEMBERSHIP: 'basic-member',
  HEART_HEALTH_UPGRADE: 'heart-upgrade',
  HEART_HEALTH_STRIPPED: 'heart-stripped',

  // Test flows
  GALLERI_TEST: "galleri-test",
  COMPREHENSIVE_TEST: "comprehensive-test",
  PRENUVO: "prenuvo",
  TRUAGE_COMPLETE: "truage-complete",
  TRUAGE_PACE: "truage-pace",
  TOXIC_HAIR_TEST: 'toxic-hair-test',
  TOXIC_METALS_TEST: 'toxic-metals-test',
  CAC: 'coronary-calcium-scan',
  CHOLESTEROL_TREAMTENT_TEST: "cholesterol-treatment-test",
  OMEGAQUANT_COMPLETE: 'omega-3-index',
  LONGEVITY_TEST: 'longevity-test',
  LONGEVITY_TEST_PSC: 'longevity-test-psc',
  ATHLETE_TEST: 'athlete-test',
  DEXA: 'dexa',
  RMR: 'rmr',
  DEXA_BODYCOMP: 'dexa-bodycomp',
  VO2MAX: 'vo2max',
  FERTILITY: 'fertility',
  MOLD_TOXINS: "toxic-molds-test",
  SLEEP_APNEA: "sleep-apnea-test",
  BIOBEAT: 'biobeat',
  CLEERLY: 'cleerly',
  CARDIAC_MRI: 'cardiac-mri',
  TESTOSTERONE: 'testosterone',
  TESTOSTERONE_PANEL: 'testosterone-panel',
  CGM: 'cgm',
  HEART_HEALTH_TEST: 'heart-health-test',
  CUSTOM_TEST: 'custom-test',
  LONGEVITY_GENETIC_TEST: 'longevity-genetic-test',
  DIURNAL_CORTISOL_PROFILE: 'diurnal-cortisol-profile',
  HEART_HEALTH_GENETIC_TEST: 'genetic-test',
  PERFORMANCE_PANEL: 'performance-panel',
  FIT_STOOL: 'fit',

  LDCT: 'ldct',
  COLONOSCOPY: 'colonoscopy',
  MAMMOGRAM: 'mammogram',
  PAP_SMEAR: 'pap-smear',
  HPV_TEST: 'hpv-test',
  DRE: 'dre',
  SKIN_CANCER_EXAM: 'skin-cancer-exam',
  ENDOSCOPY: 'endoscopy',


  // Consults
  HEART_MEMBER_CONSULT: "heart-consult",
  LONGEVITY_CONSULT: "longevity-consult",
  ATHLETE_CONSULT: 'athlete-consult',
  CARDIOLOGY_CONSULT: 'cardiology-consult',
  GASTROENTEROLOGY_CONSULT: 'gastroenterology-consult',
  DERMATOLOGY_CONSULT: 'dermatology-consult',
  NEUROLOGY_CONSULT: 'neurology-consult',
  UROLOGY_CONSULT: 'urology-consult',
  GYNECOLOGY_CONSULT: 'gynecology-consult',
  ENDOCRINOLOGY_CONSULT: 'endocrinology-consult',
  RHEUMATOLOGY_CONSULT: 'rheumatology-consult',
  ORTHOPEDICS_CONSULT: 'orthopedics-consult',
  DENTAL_CONSULT: 'dental-consult',
  OPTHALMOLOGY_CONSULT: 'ophthalmology-consult',  
  UROGYNECOLOGY_CONSULT: 'urogynecology-consult',
  ENT_CONSULT: 'ent-consult',
  SPERM_TEST: 'sperm-test',
  // Treatments
  LEQVIO: "leqvio",
  CHOLESTEROL: "cholesterol",

  // Misc
  HEART_HEALTH_INTAKE: 'heart-intake',
  SUPPLEMENTS: "supplements",
  INTAKE: "intake", 
  GIFT: "gift",
  QUIZ: 'quiz',
  UPGRADE: 'upgrade',
  INVITE: 'invite',
  REQUEST: 'request',
  REDEEM: 'redeem',
  PACKAGE: 'package',
  WAITLIST: 'waitlist',
  // Pro flows
  PRO_SIGNUP: "pro-signup",
  PRO_CONSULT: "pro-consult",
  PRO_DRAW: 'pro-draw',
  PRO_LONGEVITY_PANEL: 'pro-longevity-panel',
  PRO_ATHLETE_PANEL: 'pro-athlete-panel',
  PRO_SLEEP_APNEA: 'pro-sleep-apnea',
  PRO_AVAILABILITY: 'pro-availability',
  PRO_MOBILE_BLOOD_DRAW: 'pro-mobile-blood-draw',


  // DEMO - FOR TESTING ONLY
  DEMO: 'demo'
})

export default FlowType