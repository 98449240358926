import { Spin, message, Alert } from "antd"
import { useState, useEffect, useContext, useRef } from "react"
import { useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import { getProductTypeByCode } from "../../services/productType.service";
import { createPaymentIntent } from '../../services/stripe.service';
import { UserContext } from "../../contexts/user.context";
import "./applePayForm.scss" 
import ProductHelper from "../../helpers/product.helper";
import { addStripeProduct } from "../../services/product.service";
import ProductTypeCode from "../../enums/productTypeCode.enum";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const ExpressCheckoutComponent = ({ amount, clientSecret, onNextStep, productTypeCode, flow, onSuccess }) => {
    const stripe = useStripe();
    const [error, setError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [elements, setElements] = useState(null);
    const [isElementMounted, setIsElementMounted] = useState(false);
    
    useEffect(() => {
        if (!stripe || !amount || !clientSecret) {
            return;
        }

        const elements = stripe.elements({
            mode: 'payment',
            amount: amount,  // amount in cents
            currency: 'usd',
            setupFutureUsage: 'off_session',
            appearance: { 
                theme: 'stripe',
                variables: {
                    borderRadius: '8px',
                }
            },
        });

        setElements(elements);

        const expressCheckoutElement = elements.create('expressCheckout', {
            buttonHeight: 48,
            buttonTheme: {
                applePay: 'black',
            },
            buttonType: {
                applePay: 'plain',
            },

            paymentMethods: {
                applePay: 'always',
                amazonPay: 'never',
                googlePay: 'never',
                paypal: 'never',
                link: 'never',
            }
        });

        expressCheckoutElement.on('confirm', async (event) => {
            setIsProcessing(true);
            try {

                const result = await stripe.confirmPayment({
                    elements,
                    clientSecret,
                    redirect: 'if_required',
                    confirmParams: { return_url: window.location.href }
                });
                
                const { paymentIntent } = result;
                
                if (paymentIntent && paymentIntent.payment_method) {
                    try {

                  
                        let fields = {
                            paymentIntent: paymentIntent.id,
                            flowId: flow._id,
                            paymentMethod: paymentIntent.payment_method,
                            patient: flow?.user?._id,
                            type: Array.isArray(productTypeCode) ? productTypeCode : [productTypeCode]

                        };

                        await addStripeProduct({ fields });

                        message.success("Payment successful!");
                        if (onSuccess) {
                            onSuccess();
                        }
                        onNextStep();
                    } catch (error) {
                        console.error("Error with payment:", error);
                        setIsProcessing(false);
                    } finally {
                        setIsProcessing(false);
                    }
                }
                else {
                    message.error("Payment could not be confirmed.");
                    setError(result.error.message);
                }

            } catch (error) {
                message.error("An unexpected error occurred.");
                console.error("Payment error:", error);
                setError(error.message);
            } finally {
                setIsProcessing(false);
            }
        });

        try {
            expressCheckoutElement.mount('.express-checkout-container');
        } catch (err) {
            setError(err.message);
            console.error('Express Checkout element mount error:', err);
        } finally {
            setIsElementMounted(true);
        }

        return () => {
            try {
                expressCheckoutElement.unmount();
            } catch (err) {
                console.error('Unmount error:', err);
            }
        };
    }, [stripe, amount, clientSecret, productTypeCode, onNextStep, flow]);

    if (error) {
        return <Alert message={error} type="error" className="error-message" />;
    }

    return (
        <div className="express-checkout">
            <div className="express-checkout-container-wrapper">
                {!isElementMounted && <div className="spinner-container">
                    <Spin size="medium" />
                </div>}
                <div className="express-checkout-container" />
            </div>
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export const ExpressCheckoutForm = ({ productTypeCode, onNextStep, flow, onSuccess }) => {
    const [amount, setAmount] = useState(0);
    const [clientSecret, setClientSecret] = useState('');
    const { currentUser, instalabMembership } = useContext(UserContext);
    const prevProductTypeCode = useRef(productTypeCode);
    const isProcessing = useRef(false);

    useEffect(() => {
        const fetchProductTypes = async () => {
            // Helper function to normalize productType format
            const normalizeProductType = (type) => 
                Array.isArray(type) ? type.sort().join(',') : type;

            const currentNormalized = normalizeProductType(productTypeCode);
            const previousNormalized = normalizeProductType(prevProductTypeCode.current);

            // console.log('Comparison check:', {
            //     previous: previousNormalized,
            //     current: currentNormalized,
            //     hasClientSecret: !!clientSecret,
            //     isProcessing: isProcessing.current
            // });

            if (isProcessing.current) {
                // console.log('Already processing, skipping');
                return;
            }

            if (currentNormalized === previousNormalized && clientSecret) {
                // console.log('Skipping fetch - no changes detected');
                return;
            }

            try {
                isProcessing.current = true;
                const codes = Array.isArray(productTypeCode) ? productTypeCode : [productTypeCode];
                const types = await Promise.all(codes.map(code => getProductTypeByCode(code)));
                const totalCost = ProductHelper.getTotalCost(types, instalabMembership, currentUser);
                const amountInCents = totalCost * 100;
                setAmount(amountInCents);
                
                const { clientSecret: newClientSecret } = await createPaymentIntent({
                    amount: amountInCents,
                    currency: "usd",
                    payerId: currentUser?._id,
                    setup_future_usage: 'off_session',
                });

                setClientSecret(newClientSecret);
                // console.log('>>>>New client secret set', newClientSecret);
            } catch (error) {
                console.error("Product fetch error:", error);
            } finally {
                isProcessing.current = false;
            }
        };

        fetchProductTypes();

        return () => {
            prevProductTypeCode.current = productTypeCode;
        };
    }, [productTypeCode, clientSecret, currentUser?._id, instalabMembership]);

    return (
        
        <Elements stripe={stripePromise}>
            <ExpressCheckoutComponent 
                amount={amount} 
                clientSecret={clientSecret}     
                onNextStep={onNextStep}
                productTypeCode={productTypeCode}
                flow={flow}
                onSuccess={onSuccess}
            />
        </Elements>
    );
}; 