const EventType = Object.freeze({
  LOGIN: 0,
  VIEW_REPORT_FORM: 1,
  CLICK_FOLLOWUP: 2,
  CLICK_CONSULTATION: 3,
  CLICK_RESULTS: 4,
  CLICK_AMAZON: 5,
  PLAY_CARDIO_NUTRITION_VIDEO: 6,
  PLAY_CARDIO_EXERCISE_VIDEO: 7,
  CLICK_SHARE: 8,
  CLICK_DOWNLOAD_RESULTS: 9,
  CLICK_SCHEDULE_DRAW: 10,
  CLICK_PRODUCT: 11,
  CLICK_SIGNUP_LONGEVITY_PROGRAM: 12,
  CLICK_SIGNUP_MEMBERSHIP: 13,
  CLICK_SIGNUP_MEMBERSHIP_SIDEBAR: 14,
  CLICK_CONSULTATION_REPORT: 15,
  CLICK_REFERRAL: 16,
  CLICK_REPORT_SUMMARY_BUTTON_REPORT: 17,
  CLICK_REPORT_SUMMARY_BUTTON_PRODUCTS: 18,
  CLICK_REPORT_SUMMARY_BUTTON_SCHEDULE: 19,
  CLICK_REPORT_SUMMARY_BUTTON_HH: 20,
  CLICK_SIGNUP_SIDEBAR: 21,
  CLICK_BUNDLE_ITEM: 22,
  CLICK_HOME_MAIN_CTA: 23,
  CLICK_HOME_JOIN_WAITLIST: 24
});

export default EventType;
