import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

const KlaviyoService = {
  addToKlaviyo: async (params) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/klaviyo/subscribe`, ServiceHelper.getParams(params), {
      headers: ServiceHelper.getHeaders()
    })
    return response.data
  },

  removeFromKlaviyo: async (params) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/klaviyo/unsubscribe`, ServiceHelper.getParams(params), {
      headers: ServiceHelper.getHeaders()
    })
    return response.data
  }
}

export default KlaviyoService