import React, { useContext, useEffect, useState } from "react";
import { Typography, Tabs, Skeleton, FloatButton, message, Button } from "antd";
import "./viewPatient.scss";
import { useParams, useSearchParams } from 'react-router-dom'
import PatientHelper from "../../helpers/patient.helper.js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AdminNotesModal } from "../adminNotesModal/adminNotesModal.component.js"
import { AdminProfile } from "../adminProfile/adminProfile.component.js";
import { Results } from "../results/results.component.js";
import { Gameplan } from "../gameplan/gameplan.component.js";
import { MedicalInfo } from "../medicalInfo/medicalInfo.component.js";
import { AdminNotes } from "../adminNotes/adminNotes.component.js";
import { Files } from "../files/files.component.js";
import { AdminMembershipsTable } from "../adminMembershipsTable/adminMembershipsTable.component.js";
import { AdminAppointmentsTable } from "../adminAppointmentsTable/adminAppointmentsTable.component.js";
import { AdminConsultsTable } from "../adminConsultsTable/adminConsultsTable.component.js";
import { AdminPrescriptionsTable } from "../adminPrescriptionsTable/adminPrescriptionsTable.component.js";
import { AdminReportsTable } from "../adminReportsTable/adminReportsTable.component.js";
import { UniversalReport } from "../universalReport/universalReport.component.js";
import Role from "../../enums/role.enum.js";
import { getPatient } from "../../services/patient.service.js";
import { AdminProductsTable } from "../adminProductsTable/adminProductsTable.component.js";
import { UserContext } from "../../contexts/user.context.js";
import { listReports } from "../../services/report.service.js";
import { listResults } from "../../services/result.service.js";
import Permission from "../../enums/permission.enum.js";
import { AdminReferrals } from "../adminReferrals/adminReferrals.component.js";
import { HeartHealthData } from "../heartHealthData/heartHealthData.component.js";
import { AdminProviders } from "../adminProviders/adminProviders.component.js";
import { addImpersonation } from '../../services/impersonation.service';
import copy from 'copy-to-clipboard';
import { AdminTestkits } from "../adminTestkits/adminTestkits.component.js";

dayjs.extend(utc);
dayjs.extend(timezone);

const { Text } = Typography;

export const ViewPatient = () => {
  const { patientId } = useParams()
  const { currentUser } = useContext(UserContext)
  const [open, setOpen] = useState();
  const [searchParams, setSearchParams] = useSearchParams()
  const [items, setItems] = useState([])
  const [patient, setPatient] = useState()
  const [hasReport, setHasReport] = useState(null)
  const [hasResult, setHasResult] = useState(null)

  useEffect(() => {
    fetchPatientData()
  }, [patientId])

  useEffect(() => {
    fetchItems()
  }, [currentUser, patient, hasReport, hasResult])

  const fetchPatientData = async () => {
    if (patientId) {
      setPatient(await getPatient(patientId, {
        select: 'firstName lastName dob gender email phone location shippingLocation height weight isAthlete credits cashBalance frontInsuranceCard backInsuranceCard email_marketing email_newsletter',
        populate: [{
          path: 'memberships',
          select: '_id status endAt',
          populate: {
            path: 'membershipType',
            select: 'code'
          }
        }]
      }))
      const reports = await listReports({
        filter: {
          patient: {
            $in: [patientId]
          }
        },
      })
      setHasReport(reports?.length ? true : false)
      const results = await listResults({
        filter: {
          patient: {
            $in: [patientId]
          }
        }
      })
      setHasResult(results?.length ? true : false)
    } else {
      setPatient(null)
      setHasReport(null)
      setHasResult(null)
    }
  }


  const fetchItems = () => {
    const profileItem = {
      key: 'Profile',
      label: 'Profile',
      children: <AdminProfile patient={patient} setPatient={setPatient} />
    }

    const appointmentsItem = {
      key: 'Appointments',
      label: 'Appointments',
      children: <AdminAppointmentsTable hasPatient={true} />
    }

    // const consultItem = {
    //   key: 'Consults',
    //   label: 'Consults',
    //   children: <AdminConsultsTable hasPatient={true} />
    // }

    const productsItem = {
      key: 'Products',
      label: 'Products',
      children: <AdminProductsTable hasPatient={true} />
    }

    const gameplanItem = {
      key: 'Game Plan',
      label: 'Game Plan',
      children: <Gameplan />
    }

    const resultItem = {
      key: 'Results',
      label: 'Results',
      children: <Results />
    }

    const intakeItem = {
      key: 'Intake',
      label: 'Intake',
      children: <MedicalInfo />
    }

    const fileItem = {
      key: 'Files',
      label: 'Files',
      children: <Files />
    }

    const noteItem = {
      key: 'Notes',
      label: 'Notes',
      children: <AdminNotes />
    }

    const prescriptionItem = {
      key: 'Prescriptions',
      label: 'Prescriptions',
      children: <AdminPrescriptionsTable hasPatient={true} />
    }

    const membershipItem = {
      key: 'Memberships',
      label: 'Memberships',
      children: <AdminMembershipsTable hasPatient={true} />
    }

    const referralItem = {
      key: 'Referrals',
      label: 'Referrals',
      children: <AdminReferrals hasPatient={true}/>
    }

    const providerItem = {
      key: 'Providers',
      label: 'Providers',
      children: <AdminProviders hasPatient={true}/>
    }

    const kitItem = {
      key: 'Kits',
      label: 'Kits',
      children: <AdminTestkits hasPatient={true}/>
    }

    if (currentUser?.role === Role.PROVIDER) {
      const providerReportItem = {
        key: 'Reports',
        label: 'Reports',
        children: <UniversalReport />
      }
      const { permissions } = currentUser
      let teammateItems = [
        profileItem, 
        appointmentsItem, 
        kitItem,
        hasResult && resultItem, 
        hasReport && providerReportItem, 
        hasReport && gameplanItem, 
        intakeItem,
        fileItem,
        permissions?.includes(Permission.NOTES) && noteItem,
        permissions?.includes(Permission.PRESCRIPTIONS) && prescriptionItem, 
      ]
      setItems(teammateItems)
    } 
    else if (currentUser?.role === Role.STAFF) {
      let staffItems = [
        profileItem,
        intakeItem,
        noteItem,
        fileItem,
        referralItem,
        kitItem,
        appointmentsItem,
        hasResult && resultItem,
      ]

      setItems(staffItems)
    }
    else {
      const adminReportItem = {
        key: 'Reports',
        label: 'Reports',
        children: <AdminReportsTable hasPatient={true} />
      }
      setItems([
        profileItem, 
        appointmentsItem, 
        kitItem,
        resultItem, 
        adminReportItem, 
        gameplanItem, 
        intakeItem, 
        noteItem,
        fileItem, 
        prescriptionItem, 
        productsItem, 
        membershipItem,
        referralItem,
        providerItem,
      ])
    }
  }


  const onImpersonate = async (_id) => {
    try {
      const impersonate = await addImpersonation({ user: _id })
      copy(`${process.env.REACT_APP_CLIENT_URL}/impersonate/${impersonate._id}`)
      message.success('Copied login link')
    } catch (err) {
      message.error('Failed to generate login link')   
    }
  }

  return (
    <div className="view-patient">
      {patient && items?.length > 0 ? <>
        <div className="patient-header-container">
          <div className="patient-header">
            <div className="patient-header-title">
              <Text className="user-name">
                {patient.firstName} {patient.lastName}
              </Text>
              {patient.dob && patient.gender && (
                <Text className="user-demographics">
                  &nbsp;/ {PatientHelper.getAge(patient)} Yr {patient.gender}
                </Text>
              )}
            </div>

            {currentUser?.role === Role.ADMIN && <div style={{ marginLeft: 'auto' }}>
              <Button 
                onClick={() => onImpersonate(patientId)} 
                className="impersonate-button"
              >
                Impersonate
              </Button>
            </div>}


            {(currentUser?.role === Role.ADMIN || currentUser?.permissions?.includes(Permission.NOTES)) && (
              <>
                <AdminNotesModal 
                  patientId={patientId} 
                  open={open} 
                  setOpen={setOpen} 
                  title={`Notes for ${patient.firstName} ${patient.lastName}`} 
                />
                <FloatButton onClick={() => setOpen(true)} />
              </>
            )}
          </div>
        </div>

        <div className="tabs-container">
          <Tabs
            activeKey={searchParams.get('key') || 'Profile'}
            tabPosition='top'
            onTabClick={key => {
              setSearchParams({ key })
            }}
            items={items}
          />
        </div>
      </> : (
        <Skeleton 
          className="view-patient-skeleton"
          active 
          paragraph={{ rows: 10 }} 
        />
      )}
    </div>
  )
};
