import { Typography } from "antd";
import { StickyBlogHeader } from "../shared/stickyBlogHeader/stickyBlogHeader.component";
import { BlogData } from "../../../data/blog.data";
import { useParams } from "react-router-dom";
import classNames from "classnames";

const { Paragraph } = Typography;

export const VapingPost = () => {
  const { blogId } = useParams();

  return (
    <div className={classNames("blog-post", "seed-oil-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          {BlogData.find(b => b.id === blogId).title}
        </Paragraph>

        <Paragraph className="blog-subheading">
          {BlogData.find(b => b.id === blogId).description}
        </Paragraph>
      </div>

      <StickyBlogHeader 
        authorPhoto={BlogData.find(b => b.id === blogId).authorPhoto}
        authorName={BlogData.find(b => b.id === blogId).authorName}
        publishedAt={BlogData.find(b => b.id === blogId).publishedAt}
        readTime={BlogData.find(b => b.id === blogId).readTime}
      />

      <div className="blog-body">
        <Paragraph className="blog-description">
          At Instalab, our primary focus is cardiovascular health. It's striking how many patients believe that switching from cigarettes to vaping has offered them a safe haven from serious cardiovascular risks. While it's true that vaping poses fewer risks than smoking traditional cigarettes, it is far from a safe or effective strategy for those looking to protect their heart health.
        </Paragraph>

        <Paragraph className="blog-title">
          Increased Blood Pressure
        </Paragraph>

        <Paragraph className="blog-description">
          Much like cigarette smoking, vaping has been shown to significantly elevate blood pressure. This rise in blood pressure places excessive force on your artery walls, causing damage that promotes the rapid buildup of plaque. In fact, research shows that for every 20 mmHg increase in systolic blood pressure, the risk of cardiovascular events like heart attacks and strokes doubles.
        </Paragraph>

        <Paragraph className="blog-description">
          Additionally, higher blood pressure forces the heart to work harder to pump blood, which can lead to thickening of the heart muscle—a condition known as left ventricular hypertrophy. This well-recognized phenomenon among cardiologists can progress to heart failure if left untreated.
        </Paragraph>

        <Paragraph className="blog-title">
          Increased Heart Rate
        </Paragraph>

        <Paragraph className="blog-description">
          Vaping doesn't just increase blood pressure; it also elevates heart rate, which places additional strain on your cardiovascular system. A faster heart rate increases your heart's oxygen demand while simultaneously reducing the time available for your arteries to deliver oxygen-rich blood to the body.
        </Paragraph>

        <Paragraph className="blog-description">
          Over time, persistently high heart rates can lead to the development of arrhythmias (irregular heartbeats), which heighten the risk of sudden cardiac arrest. Even in otherwise healthy individuals, research has shown that a resting heart rate exceeding 80 beats per minute is associated with a higher risk of cardiovascular mortality.
        </Paragraph>

        <Paragraph className="blog-description">
          When combined, elevated blood pressure and heart rate amplify the strain on the cardiovascular system—a combination that is a well-established predictor of cardiovascular events and mortality.
        </Paragraph>

        <Paragraph className="blog-title">
          Comparing Cigarettes to Vaping
        </Paragraph>

        <Paragraph className="blog-description">
          It is true that smokers who switch from traditional cigarettes to e-cigarettes often experience measurable improvements in cardiovascular health. Studies have found significant improvements in endothelial function and arterial stiffness within weeks of switching. These benefits were observed regardless of whether the vapes contained nicotine or not. Similarly, vaping has been shown to have less harmful effects on blood pressure and oxidative stress compared to smoking.
        </Paragraph>

        <Paragraph className="blog-description">
          However, it's crucial to frame these findings in the right context. Vaping is not a "healthy" alternative; it's simply less harmful than traditional smoking. In terms of cardiovascular health, switching from cigarettes to vaping is not a transition from bad to good—it's a shift from incredibly harmful to moderately harmful. And quitting smoking entirely is one of the most effective steps you can take to protect your longevity and overall health.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Arastoo, S., Haptonstall, K., Choroomi, Y., Moheimani, R., Nguyen, K., Tran, E., Gornbein, J., & Middlekauff, H. (2020). Acute and Chronic Sympathomimetic Effects of E-Cigarette and Tobacco Cigarette Smoking: Role of Nicotine and Non-Nicotine Constituents. American journal of physiology. Heart and circulatory physiology. <a onClick={() => window.open('https://doi.org/10.1152/ajpheart.00192.2020', '_blank')}>https://doi.org/10.1152/ajpheart.00192.2020</a>
            </li>
            <li>
              George, J., Hussain, M., Vadiveloo, T., Ireland, S., Hopkinson, P., Struthers, A., Donnan, P., Khan, F., & Lang, C. (2019). Cardiovascular Effects of Switching From Tobacco Cigarettes to Electronic Cigarettes. Journal of the American College of Cardiology, 74, 3112 - 3120. <a onClick={() => window.open('https://doi.org/10.1016/j.jacc.2019.09.067', '_blank')}>https://doi.org/10.1016/j.jacc.2019.09.067</a>
            </li>
            <li>
              Kelesidis, T., Tran, E., Arastoo, S., Lakhani, K., Heymans, R., Gornbein, J., & Middlekauff, H. (2020). Elevated Cellular Oxidative Stress in Circulating Immune Cells in Otherwise Healthy Young People Who Use Electronic Cigarettes in a Cross‐Sectional Single‐Center Study: Implications for Future Cardiovascular Risk. Journal of the American Heart Association: Cardiovascular and Cerebrovascular Disease, 9. <a onClick={() => window.open('https://doi.org/10.1161/JAHA.120.016983', '_blank')}>https://doi.org/10.1161/JAHA.120.016983</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
