import { useContext } from "react";
import { Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PosthogHelper from "../../../helpers/posthog.helper";
import classNames from "classnames";
import { UserContext } from "../../../contexts/user.context";
import { StickyBlogHeader } from "../shared/stickyBlogHeader/stickyBlogHeader.component";
import { BlogData } from "../../../data/blog.data";
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from 'chart.js';
import colors from '../../../colors.scss';
import annotationPlugin from 'chartjs-plugin-annotation';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  annotationPlugin
);

const { Paragraph } = Typography;

const getRiskLevel = (mgYears) => {
  const riskLevels = [
    { threshold: 3072, risk: 1 },
    { threshold: 3835, risk: 2 },
    { threshold: 4597, risk: 4 },
    { threshold: 5360, risk: 8 },
    { threshold: 6122, risk: 16 },
    { threshold: 6885, risk: 32 },
    { threshold: 7647, risk: 64 }
  ];

  if (mgYears < riskLevels[0].threshold) return '<1';
  
  const risk = riskLevels
    .filter(level => mgYears >= level.threshold)
    .reduce((highest, current) => 
      current.threshold > highest.threshold ? current : highest, 
      { threshold: 0, risk: 0 }
    );
    
  return risk ? risk.risk : '<1';
};

export const CalculatorPost = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { blogId } = useParams();

  return (
    <div className={classNames("blog-post", "calculator-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          {BlogData.find(b => b.id === blogId).title}
        </Paragraph>

        <Paragraph className="blog-subheading">
          {BlogData.find(b => b.id === blogId).description}
        </Paragraph>
      </div>

      <StickyBlogHeader 
        authorPhoto={BlogData.find(b => b.id === blogId).authorPhoto}
        authorName={BlogData.find(b => b.id === blogId).authorName}
        publishedAt={BlogData.find(b => b.id === blogId).publishedAt}
        readTime={BlogData.find(b => b.id === blogId).readTime}
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          The True Culprit: ApoB
        </Paragraph>
        <Paragraph className="blog-description">
          To understand why these calculators fall short, you need to know about ApoB. This protein is at the core of CVD. Every cholesterol particle capable of causing plaque buildup in your arteries—like LDL, VLDL, and lipoprotein(a)—carries ApoB. Think of these particles as tiny, sticky invaders. They slip into your artery walls, get trapped, and trigger chronic inflammation.
        </Paragraph>
        <Paragraph className="blog-description">
          At low levels, your body can handle this. Immune cells called macrophages act like cleanup crews, clearing out trapped ApoB particles and keeping your arteries healthy. But when ApoB levels get too high, the system becomes overwhelmed. These macrophages start dying and piling up in your artery walls, snowballing into bigger plaques over time.
        </Paragraph>
        <Paragraph className="blog-description">
          Your body does its best to patch things up, but these plaques can eventually rupture, causing a blood clot that blocks flow to your heart or brain. That's how heart attacks and strokes happen. That's how over 700,000 Americans die every year.
        </Paragraph>

        <Paragraph className="blog-title">
          Tracking ApoB Over a Lifetime
        </Paragraph>
        <Paragraph className="blog-description">
          <i>ApoB is the single most important biomarker for understanding your cardiovascular risk.</i> The higher your ApoB levels, the more particles are attacking your arteries and driving plaque formation. It provides far more insight than just measuring LDL or total cholesterol levels.
        </Paragraph>
        <Paragraph className="blog-description">
          Most experts agree that keeping ApoB below <b>80 mg/dL</b> is ideal, but this highlights an important limitation of one-time risk calculators and single ApoB readings—they only provide a snapshot. A single reading might show your risk is low for now, but it says nothing about the damage already done or the risk that's quietly building for the future.
        </Paragraph>

        <Paragraph className="blog-title">
          Calculating Lifetime Exposure
        </Paragraph>

        <Paragraph className="blog-description">
          Let me explain this with my own numbers. The first time I measured my ApoB, my level was <b>92 mg/dL</b>—not far from optimal. Most doctors would've looked at that and said, "You're fine, nothing to worry about." And based on the standard risk charts, they wouldn't have been wrong.
        </Paragraph>

        <div style={{ margin: '20px 0', padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '8px' }}>
          <Line
            data={{
              labels: ['', 'March 30, 2022', ''],
              datasets: [
                {
                  label: 'ApoB',
                  data: [null, 92, null],
                  borderColor: colors.warning,
                  backgroundColor: `${colors.warning}33`,
                  pointBackgroundColor: colors.warning_bg_transparent,
                  pointBorderColor: colors.warning,
                  pointBorderWidth: 2,
                  pointRadius: 6,
                  pointHoverRadius: 8,
                  pointStyle: 'circle',
                  borderWidth: 2,
                  fill: false,
                },
                {
                  label: 'High Risk (>120)',
                  data: [120],
                  borderColor: 'rgba(237, 110, 127, 0.3)',
                  backgroundColor: colors.error_bg_transparent,
                  fill: '+1',
                  pointRadius: 0,
                },
                {
                  label: 'Borderline Risk (80-120)',
                  data: [80],
                  borderColor: 'rgba(245, 191, 71, 0.3)',
                  backgroundColor: colors.warning_bg_transparent,
                  fill: '+1',
                  pointRadius: 0,
                },
                {
                  label: 'Optimal (<80)',
                  data: [60],
                  borderColor: 'rgba(12, 163, 127, 0.3)',
                  backgroundColor: colors.success_bg_transparent,
                  fill: '+1',
                  pointRadius: 0,
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                title: {
                  display: true,
                  text: 'First ApoB Test Value',
                  font: {
                    size: 16,
                    weight: 'bold'
                  },
                  padding: {
                    bottom: 20
                  }
                },
                tooltip: {
                  enabled: true,
                  position: 'nearest',
                  external: (context) => {
                    // Force show tooltip for the first (and only) data point
                    if (!context.tooltip.dataPoints) {
                      const dataPoint = {
                        dataIndex: 1,  // Center point index
                        dataset: context.chart.data.datasets[0],
                        label: 'March 30, 2022',
                      };
                      context.tooltip.setActiveElements([dataPoint]);
                      context.tooltip.update();
                    }
                  },
                  callbacks: {
                    label: (context) => `ApoB: ${context.raw} mg/dL (Borderline Risk)`,
                    title: () => 'March 30, 2022'
                  },
                  displayColors: false,
                  padding: 10,
                  titleFont: { weight: 'bold' },
                },
                annotation: {
                  annotations: {
                    highRiskBox: {
                      type: 'box',
                      yMin: 120,
                      yMax: 140,
                      backgroundColor: colors.error_bg_transparent,
                      borderColor: 'transparent',
                    },
                    moderateRiskBox: {
                      type: 'box',
                      yMin: 80,
                      yMax: 120,
                      backgroundColor: colors.warning_bg_transparent,
                      borderColor: 'transparent',
                    },
                    optimalRiskBox: {
                      type: 'box',
                      yMin: 0,
                      yMax: 80,
                      backgroundColor: colors.success_bg_transparent,
                      borderColor: 'transparent',
                    },
                  }
                },
                legend: {
                  position: 'bottom',
                  labels: {
                    usePointStyle: true,
                    padding: 20,
                  },
                },
              },
              scales: {
                y: {
                  min: 0,
                  max: 140,
                  title: {
                    display: true,
                    text: 'ApoB (mg/dL)',
                  },
                  grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                  },
                },
                x: {
                  grid: {
                    display: false,
                  },
                  ticks: {
                    display: false,
                  }
                },
              },
              interaction: {
                intersect: false,
                mode: 'index',
              },
            }}
            style={{ height: '400px' }}
          />
        </div>

        <Paragraph className="blog-description">
          But that number doesn't tell the full story. Let's break it down: if I was born with an ApoB level of <b>40 mg/dL</b> (the average at birth) and it steadily rose to <b>92 mg/dL</b> by age 30, my cumulative exposure would already be <b>1,976 mg-years</b>. That's how much stress my arteries endured by that point in my life. And if I had done nothing to lower it, that number would've kept climbing quickly, year after year.
        </Paragraph>

        <div style={{ margin: '20px 0', padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '8px' }}>
          <Line
            data={{
              labels: Array.from({length: 30+1}, (_, i) => i),
              datasets: [{
                label: 'Cumulative Exposure',
                data: [
                  0, 41, 83, 128, 174, 222, 271, 322, 375, 430,
                  486, 544, 604, 666, 729, 794, 861, 929, 999, 1071,
                  1145, 1220, 1297, 1376, 1456, 1539, 1623, 1708, 1796, 1885,
                  1976
                ],
                borderColor: '#1677ff',
                backgroundColor: 'rgba(0,0,0,0)',
                fill: 'start',
                tension: 0,
              }]
            }}
            options={{
              clip: false,
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                title: {
                  display: true,
                  text: 'Cumulative ApoB Exposure Over Time',
                  font: {
                    size: 16,
                    weight: 'bold'
                  },
                  padding: {
                    bottom: 20
                  }
                },
                tooltip: {
                  callbacks: {
                    label: (context) => `Exposure: ${Math.round(context.raw).toLocaleString()} mg-years`,
                    title: (context) => `${context[0].label} years old`
                  }
                }
              },
              scales: {
                y: {
                  title: {
                    display: true,
                    text: 'Cumulative Exposure (mg-years)',
                  },
                  min: 0,
                  max: 2500,
                  grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                  }
                },
                x: {
                  title: {
                    display: true,
                    text: 'Age (years)',
                  },
                  grid: {
                    display: false,
                  },
                  max: 100,
                }
              },
              animation: {
                duration: 0
              },
              interaction: {
                intersect: false,
                mode: 'index',
              },
            }}
            style={{ height: '400px' }}
          />
        </div>

        <Paragraph className="blog-title">
          Cardiovascular Risk Accelerates
        </Paragraph>

        <Paragraph className="blog-description">
          Here's the critical part: risk doesn't increase linearly with cumulative exposure—it accelerates. As ApoB builds up over decades, the damage to your arteries compounds. For me, based on my total exposure, I already faced a <b>1% chance of a heart attack in my early 40s</b>. That might not sound alarming at first glance. But if my ApoB levels had remained unchanged, my cumulative exposure by my 70s would have pushed my heart attack risk to <b>16%</b>.
        </Paragraph>
        
        <Paragraph className="blog-description">
          This isn't just a statistic—it was a wake-up call. It showed me how every year of elevated ApoB silently increases risk, compounding over time. By making lifestyle changes—adjusting my diet and committing to 30 minutes of exercise daily—I was able to lower my ApoB to <b>48 mg/dL</b>. This single change pushed back the timeline for entering a higher-risk threshold by about a decade.
        </Paragraph>

        <div style={{ margin: '20px 0', padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '8px' }}>
          <Line
            data={{
              labels: Array.from({length: 100+1}, (_, i) => i),
              datasets: [
                {
                  label: 'Historical Exposure',
                  data: [
                    0, 41, 83, 128, 174, 222, 271, 322, 375, 430,
                    486, 544, 604, 666, 729, 794, 861, 929, 999, 1071,
                    1145, 1220, 1297, 1376, 1456, 1539, 1623, 1708, 1796, 1885,
                    1976
                  ],
                  borderColor: '#1677ff',
                  backgroundColor: 'rgba(0,0,0,0)',
                  fill: 'start',
                  tension: 0,
                  pointRadius: (context) => {
                    return context.raw && context.dataIndex % 5 === 0 ? 3 : 0;
                  },
                  pointHoverRadius: (context) => {
                    return context.raw && context.dataIndex % 5 === 0 ? 5 : 0;
                  },
                },
                {
                  label: 'Projection (No Change)',
                  data: Array.from({length: 101}, (_, i) => {
                    if (i <= 30) return null;
                    return 1976 + (92 * (i - 30));
                  }),
                  borderColor: 'rgb(237, 110, 127)',
                  borderDash: [5, 5],
                  fill: false,
                  tension: 0,
                  pointRadius: 0,
                },
                {
                  label: 'Projection (Reduced)',
                  data: Array.from({length: 101}, (_, i) => {
                    if (i <= 30) return null;
                    return 1976 + (48 * (i - 30));
                  }),
                  borderColor: '#0ca37f',
                  borderDash: [5, 5],
                  fill: false,
                  tension: 0,
                  pointRadius: 0,
                }
              ]
            }}
            options={{
              clip: false,
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                title: {
                  display: true,
                  text: 'Projected Cumulative Exposure Over Time',
                  font: {
                    size: 16,
                    weight: 'bold'
                  },
                  padding: {
                    bottom: 20
                  }
                },
                legend: {
                  labels: {
                    usePointStyle: false,
                    boxWidth: 40,
                    generateLabels: (chart) => {
                      const datasets = chart.data.datasets;
                      return datasets.map((dataset, i) => ({
                        text: dataset.label,
                        fillStyle: 'transparent',
                        strokeStyle: dataset.borderColor,
                        lineDash: dataset.borderDash || [],
                        lineWidth: 2,
                        hidden: !chart.isDatasetVisible(i),
                        datasetIndex: i,
                        fontColor: 'rgba(0,0,0,0.6)'
                      }));
                    }
                  }
                },
                tooltip: {
                  titleFont: {
                    family: '"Hack", monospace', // Added monospace fallback
                    size: 12
                  },
                  bodyFont: {
                    family: '"Hack", monospace', // Added monospace fallback
                    size: 12
                  },
                  callbacks: {
                    label: (context) => {
                      const datasetIndex = context.datasetIndex;
                      const value = Math.round(context.raw).toLocaleString();
                      const riskLevel = getRiskLevel(context.raw);
                      
                      switch(datasetIndex) {
                        case 0:
                          return [
                            ` Historical Exposure:   ${value} mg-years`,
                            ` Heart Attack Risk:     ${riskLevel}% lifetime risk`,
                          ];
                        case 1:
                          return [
                            ` Projected (No Change): ${value} mg-years`,
                            ` Heart Attack Risk:     ${riskLevel}% lifetime risk`,
                            ` `
                          ];
                        case 2:
                          return [
                            ` Projected (Improved):  ${value} mg-years`,
                            ` Heart Attack Risk:     ${riskLevel}% lifetime risk`
                          ];
                        default:
                          return []
                      }
                    },
                    title: (context) => `${context[0].label} years old`
                  }
                },
              },
              scales: {
                y: {
                  title: {
                    display: true,
                    text: 'Cumulative Exposure (mg-years)',
                  },
                  min: 0,
                  max: 9000,
                  grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                  }
                },
                x: {
                  title: {
                    display: true,
                    text: 'Age (years)',
                  },
                  grid: {
                    display: false,
                  },
                  max: 100,
                  ticks: {
                    callback: (value) => value % 5 === 0 ? value : '',
                    maxRotation: 0,
                    stepSize: 5,  // Force ticks every 5 units
                    autoSkip: false  // Prevent automatic tick skipping
                  }
                }
              },
              animation: {
                duration: 0
              },
              interaction: {
                intersect: false,
                mode: 'index',
              },
            }}
            style={{ height: '400px' }}
          />
        </div>

        <Paragraph className="blog-description">
          That's why tracking cumulative ApoB exposure is so critical. It gives you the clearest picture of how your arteries are aging and how intervening early can shape your future health.
        </Paragraph>

        <Paragraph className="blog-title">
          A Better Risk Calculator
        </Paragraph>
        <Paragraph className="blog-description">
        Despite how important cumulative ApoB exposure is for understanding cardiovascular risk, there hasn't been a tool to help people track it—until now. That's why we've built a <a className="blog-link" onClick={() => {
            PosthogHelper.track(currentUser, `[research] click exposure tool cta: ${blogId}`, true)
            navigate(`/exposure/launch`)
          }}>better risk calculator at Instalab</a>. 
        </Paragraph>
        <Paragraph className="blog-description">
          Our tool doesn't just measure your ApoB today—it helps you calculate your lifetime exposure and see how your risk changes over time. It also helps you track how your interventions—whether it's dietary changes, exercise, or medication—are actively reducing your long-term risk. It turns abstract numbers into something visual, actionable, and real.
        </Paragraph>
        <Paragraph className="blog-description">
          If you're already testing with Instalab, your results are preloaded into the tool. Simply log in and see where you stand. But we also believe this information is too important to limit to just Instalab patients. That's why we've made this tool free and accessible for everyone. If you've had your ApoB tested elsewhere, you can upload your results and still get the same powerful insights.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Packard, C., Weintraub, W., & Laufs, U. (2015). New metrics needed to visualize the long-term impact of early LDL-C lowering on the cardiovascular disease trajectory. Vascular pharmacology, 71, 37-9. <a onClick={() => window.open('https://doi.org/10.1016/j.vph.2015.03.008', '_blank')}>https://doi.org/10.1016/j.vph.2015.03.008</a>
            </li>
            <li>
              Ference, B., Graham, I., Tokgozoglu, L., & Catapano, A. (2018). Impact of Lipids on Cardiovascular Health: JACC Health Promotion Series. Journal of the American College of Cardiology, 72(10), 1141-1156. <a onClick={() => window.open('https://doi.org/10.1016/j.jacc.2018.06.046', '_blank')}>https://doi.org/10.1016/j.jacc.2018.06.046</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}; 