import { useContext, useRef, useState, useEffect } from "react"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { ConfirmRequest } from "./confirmRequest.component"
import { UserContext } from "../../contexts/user.context"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import { RequestIntro } from "./requestIntro.component"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"
import _ from 'lodash';
import { completeFlow } from "../../services/flow.service.js"
import FileTypeCode from "../../enums/fileTypeCode.enum"

export const Step = {
  INTRO: 'intro',
  ACCOUNT: 'account',

  // referral info
  REQUEST: 'request',
  INSURANCE: 'insurance',

  // basic account info 
  NAME: 'name',
  DOB: 'dob',
  LOCATION: 'location', 
  MEMBERSHIP: 'membership',

  CONFIRM: 'confirm',
}

export const RequestFlow = () => {
  const navigate = useNavigate()
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const { instalabMembership } = useContext(UserContext)
  const [productTypes, setProductTypes] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [hasPass, setHasPass] = useState()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser
  const hasCompletedFlowRef = useRef(false)


  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  useEffect(() => {
    fetchSkeleton()
  }, [hasPass, membershipTypes, productTypes, instalabMembership, flow])
  

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.SPECIALIST_REFERRAL,
          ]
        }
      }
    }))
  }

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    }))
  }

  const instructions = [
    {
      sections: [{
        title: 'Submit a request',
        description: <>Tell us about your request.</>
      }, 
    ]
    }, 
    {
      sections: [{
        title: 'We’ll handle the rest',
        description: <>Instalab’s concierge team will reach out to you within 1 business day.</>
      }]
    }
  ]

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.ACCOUNT,
        content: ({ footer }) => <RequestIntro  footer={footer} instructions={instructions}/>
      },

      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.REQUEST,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.REQUEST}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      

      [Step.REQUEST]: {
        type: StepType.TEXTAREA,
        nextStep: Step.NAME,
        buildFlow: true,
        title: `What's your request?`,
        description: `Please be as specific as possible.`,
        field: {
          name: 'request',
          placeholder: 'Type here ...',
          required: true,
        }
      },


      // [Step.INSURANCE]: {
      //   type: StepType.INSURANCE_UPLOAD,
      //   title: 'Upload your insurance card (optional)',
      //   description: `If this request requires insurance, please upload your insurance card. Otherwise, just skip this step.`,
      //   onNextStep: () => {
      //     return Step.NAME
      //   },
      //   hasSkip: true,
      //   buildUser: true,
      //   fields: [
      //     {
      //       name: 'frontInsuranceCard',
      //       fileTypeCode: FileTypeCode.INSURANCE_CARD_FRONT,
      //       upload: true,
      //       title: 'Front of your insurance card',
      //     },
      //     {
      //       name: 'backInsuranceCard',
      //       fileTypeCode: FileTypeCode.INSURANCE_CARD_BACK,
      //       upload: true,
      //       title: 'Back of your insurance card',
      //     }
      //   ],

      // },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },
      // [Step.LOCATION]: {
      //   type: StepType.LOCATION,
      //   buildUser: true,
      //   buildFlow: true,
      //   nextStep: Step.MEMBERSHIP,
      //   title: `What's your current address?`,
      //   description: `We'll find referrals near this address.`,
      //   field: {
      //     name: 'location'
      //   },
      // },

      [Step.MEMBERSHIP]: {
        type: StepType.PAY,
        nextStep: Step.CONFIRM,
        addSubscription: true,
        subscriptionType: MembershipTypeCode.LONGEVITY,
        title: <>Activate Instalab membership for <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span>.</>,
        description: "Concierge requests are exclusive to members. Easily cancel anytime.",
        skipIf: () => {
          return instalabMembership
        }
      },

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: <>All set!</>,
        description: <>We'll email you within 1 business day. If you need help sooner than this, you can also contact us at <a href="mailto:concierge@instalab.com">concierge@instalab.com</a>.</>,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmRequest instructions={instructions} />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },

    })
  }

  return <>
    <Flow 
      skeleton={skeleton} 
      flow={flow}
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}

    />  
  </>
}