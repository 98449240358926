import React, { useContext, useEffect, useState } from "react";
import { Typography, Tabs, Skeleton } from "antd";
import "./settings.scss";
import { useParams, useSearchParams } from 'react-router-dom'
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { UserContext } from "../../contexts/user.context.js";
import { Profile } from "../profile/profile.component.js";
import { Billing } from "../billing/billing.component.js";
import { Memberships } from "../memberships/memberships.component.js";
import { PageHeader } from "../pageHeader/pageHeader.component.js";
import { Notifications } from "../notifications/notifications.components.js";
dayjs.extend(utc);
dayjs.extend(timezone);

const { Text } = Typography;

export const Settings = () => {
  const { currentUser } = useContext(UserContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [items, setItems] = useState([])


  useEffect(() => {
    fetchItems()
  }, [currentUser])


  const fetchItems = () => {
    const profileItem = {
      key: 'Profile',
      label: 'Profile',
      children: <Profile  />
    }

    const billingItem = {
      key: 'Billing',
      label: 'Payments',
      children: <Billing />
    }

    const membershipsItem = {
      key: 'Memberships',
      label: 'Memberships',
      children: <Memberships />
    }

    const notificationsItem = {
      key: 'Emails',
      label: 'Emails',
      children: <Notifications />
    }

    setItems([profileItem, billingItem, membershipsItem, notificationsItem])
  }



  return (
    <div className="settings">
    <PageHeader title='Settings'  description='Manage your account settings and preferences.' />
      {items?.length > 0 ? <>
 

        <div className="tabs-container">
          <Tabs
            activeKey={searchParams.get('key') || 'Profile'}
            tabPosition='top'
            onTabClick={key => {
              setSearchParams({ key })
            }}
            items={items}
          />
        </div>
      </> : (
        <Skeleton 
          className="view-patient-skeleton"
          active 
          paragraph={{ rows: 10 }} 
        />
      )}
    </div>
  )
};
