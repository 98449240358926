import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'
import QueryStringHelper from '../helpers/queryString.helper'

export const listProviders = async (params={}) => {
  const queryString = QueryStringHelper.format(ServiceHelper.getParams(params))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/providers?${queryString}`, {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const getProvider = async (id, params={}) => {
  console.log(params)
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/providers/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const inviteProvider = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/providers/${id}/invite`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const acceptProviderInvite = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/providers/${id}/accept-invite`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addProvider = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/providers`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updateProvider = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/providers/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeProvider = async (id, params={}) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/providers/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}