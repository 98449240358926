import { TestModal } from "../testModal/testModal.component";
import { useState, useEffect } from "react";
import LowercaseTest from "../../enums/lowercaseTest.enum";

export const TestLink = ({testCode, report, tests, results, reports, lowercase=false, content=null}) => {

    const [openModal, setOpenModal] = useState(false);
    const [testId, setTestId] = useState(null);
    const [testName, setTestName] = useState(null);

    const handleTestLink = (testCode) => {
        setTestId(tests.find((t) => t.code === testCode)._id);
        setOpenModal(true);
    }

    useEffect(() => {
        setTestName(tests.find((t) => t.code === testCode)?.name || '');
    }, [testCode]);
    
    return (
    testName && <>
        <TestModal
            open={openModal}
            setOpen={setOpenModal}
            testId={testId}
            setTestId={setTestId}
            report={report}
            tests={tests}
            results={results}
            reports={reports}
        />

        <a href="#" onClick={(e) => {
            e.preventDefault();
            handleTestLink(testCode);
        }}>{content ? content : (lowercase ? LowercaseTest[testCode] : testName)}</a>
      </>
    )
};