import { useState, useEffect } from 'react'
import { Input, Form, Typography, Button, Spin } from "antd"
import { LeftOutlined, RightOutlined, WarningFilled } from '@ant-design/icons'
import "./schedule.scss"
import { Selector } from "antd-mobile";
import { FlowStepFooter } from '../../flowStep/flowStepFooter.component'
import _ from 'lodash'
import moment from 'moment-timezone'
import { getAvailability } from '../../../../services/schedule.service'
import FormHelper from '../../helpers/form.helper';
import Breakpoint from '../../../../enums/breakpoint.enum';
import { useSearchParams } from 'react-router-dom';
import PhlebType from '../../../../enums/phlebType.enum';
import classNames from 'classnames';
import useWidth from '../../../../hooks/useWidth.hook';

const { Item } = Form
const { Text } = Typography

export const Schedule = ({
  onSubmit,
  form,
  flow,
  step,
  isSubmitting,
}) => {
  const scheduleTimeWatch = Form.useWatch("scheduleTime", form)
  const [searchParams] = useSearchParams()
  const width = useWidth()
  const [startDate, setStartDate] = useState()
  const [scheduleDate, setScheduleDate] = useState()
  const [availability, setAvailability] = useState([])
  const [location, setLocation] = useState()
  const [phlebType, setPhlebType] = useState()
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    setInitialDates()
  }, [location, flow])

  useEffect(() => {
    fetchSelectedTime()
    fetchLocation()
  }, [flow])

  const fetchSelectedTime = async () => {
    if (!flow?.appointment)  return
    const { appointment } = flow
    form.setFieldsValue({
      scheduleTime: [moment(appointment.start).tz(appointment.location.timeZoneId).format("h:mm a")]
    })
  }

  const getFirstDayOfWeek = (day=new Date()) => {
    return moment(day).tz(location?.timeZoneId).startOf('week').startOf('day').add(1,'days').toDate()
  }

  const getSelectedDate = (availability) => {
    return flow?.appointment?.start ? moment(flow.appointment.start).tz(location.timeZoneId).startOf('day').toDate() : moment(availability.find(({ times }) => times?.length)?.day).tz(location.timeZoneId).startOf('day').toDate()
  }

  const getStartDate = (availability) => {
    return getFirstDayOfWeek(getSelectedDate(availability))
  }

  const isDateDisabled = (day) => {
    return !availability?.find(params => moment(params.day).tz(location.timeZoneId).format('DD.MM.YYYY') === day.format('DD.MM.YYYY'))?.times?.length
  }

  const fetchLocation = () => {
    setLocation(flow?.appointment?.location || flow?.user?.location)
  }

  const setInitialDates = async () => {
    if (!location || !flow) return

    setLoading(true); // Start loading
    const response = await onGetAvailability(getFirstDayOfWeek(flow?.appointment ? new Date(flow.appointment.start) : new Date()))

    // Find the first available day with time slots
    const firstAvailableDay = response.availability.find(({ times }) => times?.length > 0);
    const initialDate = firstAvailableDay ? moment(firstAvailableDay.day).tz(location.timeZoneId).startOf('day').toDate() : getFirstDayOfWeek();

    // Set the initial start date and the schedule date
    setStartDate(getStartDate(response.availability)); // Adjusts the display to the week containing first available date
    setScheduleDate(initialDate);

    // Set the form fields
    form.setFieldsValue({
      scheduleDate: [initialDate.toString()],
      scheduleTime: flow?.appointment ? [new Date(flow.appointment.start).getTime()] : []
    });

  }

  const onLoadTimes = async (date) => {
    const hasLoaded = getHasLoaded(date)
    if (!hasLoaded) {
      setAvailability(null)
      await onGetAvailability(date)
    }
  }

  const onGetAvailability = async (date) => {
    if (!location) return

    const override = searchParams.get('override')
    const response = await getAvailability({
      startDate: date,
      location,
      override,
      flowId: flow?._id,
    })
    setPhlebType(response.phlebType)

    setAvailability(response.availability.reduce((acc, { day, times }) => {
      if (!getHasLoaded(day)) {
        acc.push({
          day,
          times
        })
      }
      return acc
    }, availability || []))
    setLoading(false);
    return response
  }

  const getHasLoaded = (date) => {
    return availability?.some(({ day }) => moment(day).format('MM/DD/YYYY') === moment(date).format('MM/DD/YYYY'))
  }

  const onPrev = async () => {
    setLoading(true);

    const prevDate = moment(startDate).tz(location.timeZoneId).subtract(7, 'days').isoWeekday(1).toDate()
    await onLoadTimes(prevDate) 

    setStartDate(prevDate)
    setLoading(false)
  }

  const onNext = async () => {
    setLoading(true);

    const nextDate = moment(startDate).tz(location.timeZoneId).add(7, 'days').isoWeekday(1).toDate()
    await onLoadTimes(nextDate) 

    setStartDate(nextDate)
    setLoading(false)
  }

  const onScheduleDateChange = async (value) => {
    form.setFieldsValue({ scheduleTime: null })
    if (value?.length) {
      setScheduleDate(new Date(value[0]))
    } else {
      setScheduleDate(null)
    }
  }


  return location && (
    <div className="schedule">
      <Form
        form={form}
        className="schedule-form"
      >
        <div className="date-selection">
          <Button
            icon={<LeftOutlined />}
            onClick={onPrev}
            className="left-icon"
            disabled={moment(startDate).subtract(1, 'days').endOf('day').toDate().getTime() < moment().startOf('day').toDate().getTime()}
          />

          <Item
            name="scheduleDate"
            className="schedule-item"
            rules={[{ 
              required: true, 
              message: <><WarningFilled />&nbsp; Select an appointment date</>,
            }]}
            validateTrigger='onSubmit'
          >
            <Selector
              onChange={value => {
                onScheduleDateChange(value)
                FormHelper.fetchHasError(form)
              }}
              options={Array.from(Array(5)).map((_, dayIndex) => {
                const day = moment(startDate).tz(location.timeZoneId).startOf('day').add(dayIndex, 'days')

                return {
                  label: (
                    <div className="date-option">
                      <Text className="date-day-of-week">
                        {day.format('ddd')}
                      </Text>
                      <Text className="date-day">
                        {day.format('D')}
                      </Text>
                      <Text className="date-month">
                        {day.format('MMM')}
                      </Text>
                    </div>
                  ),
                  value: day.toDate().toString(),
                  disabled: isDateDisabled(day)
                }
              })}
            />
          </Item>

          <Button
            icon={<RightOutlined />}
            onClick={onNext}
            className="right-icon"
          />
        </div>

        {loading && (
          <div className="loading-times">
            <Spin size="small" /> <Text className="loading-times-text">Loading available times...</Text>
          </div>
        )}
        {!loading && !availability?.length && 
          <div className="loading-times">
            <Text className="loading-times-text">Apologies, there are no times available to schedule online right now. Please contact <a href="mailto:concierge@instalab.com">concierge@instalab.com</a> to get this done.</Text>
          </div>
        }

        {scheduleDate && availability?.length > 0 && (
          <div 
            className={classNames(
              "time-selection",
              `${phlebType}-time-selection`
            )}>
            <Item 
              name="scheduleTime"
              className="schedule-item"
              rules={[{ 
                required: true, 
                message: <><WarningFilled />&nbsp; Select an appointment time</>,
              }]}
              validateTrigger='onSubmit'
            >
                <Selector
                  onChange={() => FormHelper.fetchHasError(form)}
                  options={availability?.find(params => moment(params.day).tz(location.timeZoneId).format('MM/DD/YYYY') === moment(scheduleDate).tz(location.timeZoneId).format('MM/DD/YYYY'))?.times?.map(time => {
                    return {
                      label: phlebType === PhlebType.GETLABS ? (
                        <div className="time-option">
                          {moment(time).tz(location.timeZoneId).format("ha")} - {moment(time).add(2, 'hours').tz(location.timeZoneId).format("ha")}
                        </div>
                      ) : (
                        <div className="time-option">
                          {moment(time).tz(location.timeZoneId).format("h:mm a")}
                        </div>
                      ),
                      value: new Date(time).getTime()
                    }
                  }) || []}
                />
            </Item>
          </div>
        )}

        <Item
          name='timeZoneId'
          hidden
        >
          <Input />
        </Item>
        
        { scheduleDate && scheduleTimeWatch?.length > 0 &&  (
          <FlowStepFooter 
            onSubmit={() => onSubmit()}
            isSubmitting={isSubmitting}
            buttonText={ step?.createPending ? "Reserve Time Slot" : width > Breakpoint.SM ?  "Confirm Appointment" : "Confirm"}
          />
        )}
      </Form>
    </div>
  )
}