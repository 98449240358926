import './exposureIntro.scss';

const ExposureIntro = () => {
  return (
    <div className="exposure-intro">
      Exposure Intro
    </div>
  )
};

export default ExposureIntro;