import { TestItemList } from "../testItemLlist/testItemList.component";
import { TestChart } from "../testChart/testChart.component";


export const TestView = ({tests, report, user, results, reports }) => {


  return (tests && (
    <>
        {(tests.length === 1) ? (
          <TestChart 
            report={report} 
            code={tests[0].code} 
            tests={tests}
            results={results}
            reports={reports}
          />
        ) : (
          <TestItemList
            report={report}
            testIds={tests.map(({ _id }) => _id)}
            tests={tests}
            results={results}
            user={user}
            reports={reports}
          />
        )}    
    </>
  ))
}