const NoteType   = Object.freeze({
    GENERAL: 'general',
    MEDICAL_CONDITION: 'medical-condition', 
    MEDICATION: 'medication',
    SUPPLEMENT: 'supplement',
    PREFERENCES: 'preference',
    APPOINTMENT: 'appointment',
    DIET: 'diet',
    EXERCISE: 'exercise',
})

export default NoteType;
