import './launchTotalExposure.scss';
import moment from 'moment'
import { useContext, useEffect, useState } from 'react';
import { listTests } from '../../services/test.service';
import { addSelfReportedResults, listResults, removeSelfReportedResult } from '../../services/result.service';
import TestCode from '../../enums/testCode.enum';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Color from '../../colors.scss';
import { message, Typography } from 'antd';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  TextField,
  Stack,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { Button } from 'antd';
import { UserContext } from '../../contexts/user.context';
import { v4 as uuidv4 } from 'uuid';
import { RightOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { updateMe } from '../../services/user.service';
import PosthogHelper from '../../helpers/posthog.helper';

const { Paragraph } = Typography
const testId = '621d2a9d5a43a99402ccce12'

const isValidDate = (date) => {
  if (!dayjs(date).isValid() || dayjs(date).format('MM/DD/YYYY') !== dayjs(date).format('MM/DD/YYYY')) {
    return false;
  }
  
  const currentYear = dayjs().year();
  const yearToCheck = dayjs(date).year();
  return yearToCheck >= (currentYear - 120) && yearToCheck <= currentYear;
};

export const LaunchTotalExposure = () => {
  const navigate = useNavigate()
  const { currentUser, setCurrentUser, token } = useContext(UserContext)
  const [dataSource, setDataSource] = useState([{
    key: 1,
    collectedAt: null,
    apobValue: null,
    isSelfReported: true,
  }]);
  const [tests, setTests] = useState([]);
  const [test, setTest] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deletingKeys, setDeletingKeys] = useState(new Set());
  const [fetchedResultIds, setFetchedResultIds] = useState(new Set());
  const [dob, setDob] = useState(null);

  useEffect(() => {
    fetchTests()
  }, [])

  useEffect(() => {
    if (currentUser?.dob) {
      setDob(dayjs(currentUser.dob))
    }
  }, [currentUser])

  useEffect(() => {
    fetchTest()
  }, [tests])

  useEffect(() => {
    fetchTestValues()
  }, [currentUser])

  const fetchTest = () => {
    setTest(tests.find(({ code }) => code === TestCode.APO_B))
  }

  const sortDataSource = (data) => {
    return data.sort((a, b) => {
      return new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime()
    })
  }

  const fetchTestValues = async () => {
    if (!currentUser) return
    const fetchedResults = await listResults({
      select: '_id values collectedAt isSelfReported',
      sort: '-collectedAt',
    })
    const testValues = fetchedResults.filter(({ values }) => {
      return values?.some(({ test, value }) => test === testId && value)
    }).map(({ _id, collectedAt, values, isSelfReported }) => {
      setFetchedResultIds(prev => new Set([...prev, _id]));
      return {
        key: _id,
        isSelfReported,
        collectedAt: moment(collectedAt),
        apobValue: values.find(({ test, value }) => test === testId && value).value
      }
    })
    if (testValues?.length) {
      setDataSource(prevDataSource => {
        return sortDataSource([
          ...prevDataSource,
          ...testValues.filter(({ key }) => !prevDataSource.some(record => record.key === key))
        ])
      })
    }
  }

  const fetchTests = async () => {
    const tests = await listTests()
    setTests(tests)
  }

  const manageEmptyRowsHelper = (currentDataSource) => {
    const emptyRows = currentDataSource.filter(row => 
      !row.collectedAt && (!row.apobValue || row.apobValue === '')
    );

    let newDataSource = [...currentDataSource];

    if (emptyRows.length === 0) {
      newDataSource.push({
        key: uuidv4(),
        isSelfReported: true,
        collectedAt: null,
        apobValue: null,
      });
    }
    else if (emptyRows.length > 1) {
      const sortedEmptyKeys = emptyRows
        .map(row => row.key)
        .sort((a, b) => b - a);
      
      sortedEmptyKeys.slice(1).forEach(key => {
        if (!currentDataSource.find(row => row.key === key).collectedAt && 
            !currentDataSource.find(row => row.key === key).apobValue) {
          newDataSource = newDataSource.filter(row => row.key !== key);
        }
      });
    }

    return newDataSource;
  };

  const handleDateChange = (key, date) => {
    setDataSource(prevDataSource => {
      const newDataSource = prevDataSource.map((item) =>
        item.key === key ? { ...item, collectedAt: dayjs(date) } : item
      );
      return manageEmptyRowsHelper(newDataSource);
    });
  };

  const handleValueChange = (key, value) => {
    setDataSource(prevDataSource => {
      const newDataSource = prevDataSource.map((item) =>
        item.key === key ? { ...item, apobValue: value } : item
      );
      return manageEmptyRowsHelper(newDataSource);
    });
  };

  const isValidDOB = (date) => {
    if (!date || !isValidDate(date)) return false;
    return dayjs(date).isBefore(dayjs());
  };

  const handleSubmit = async () => {
    try {
      if (!token && !isValidDOB(dob)) {
        message.error('Please enter a valid date of birth');
        return;
      }

      setIsSubmitting(true);

      if (!token) {
        await updateMe({
          fields: {
            dob: dob.format('MM/DD/YYYY')
          }
        })
        setCurrentUser(prevCurrentUser => {
          return {
            ...prevCurrentUser,
            dob: dob.format('MM/DD/YYYY')
          }
        })
      }

      const fields = dataSource.filter(data => {
        return isValidEntry(data) && data.isSelfReported && !fetchedResultIds.has(data.key)
      }).map(({ collectedAt, apobValue }) => ({
        collectedAt: collectedAt.toDate(),
        values: [{
          test: test._id,
          value: Number(apobValue),
        }]
      }))
      await addSelfReportedResults({
        fields,
        dob: dob.toDate()
      });
      PosthogHelper.track(currentUser, `launch exposure`, true)
      message.success('Launched exposure tool')
      navigate('/exposure')
    } catch (error) {
      console.error('Error submitting results:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const handleDelete = async (key) => {
    try {
      setDeletingKeys(prev => new Set([...prev, key]));
      
      if (fetchedResultIds.has(key)) {
        await removeSelfReportedResult(key);
      }

      setDataSource(prevDataSource => {
        const newDataSource = prevDataSource.filter(item => item.key !== key);
        return manageEmptyRowsHelper(newDataSource);
      });
      message.info('Removed test result');
    } catch (error) {
      console.error('Error deleting result:', error);
      message.error('Failed to remove test result');
    } finally {
      setDeletingKeys(prev => {
        const newSet = new Set(prev);
        newSet.delete(key);
        return newSet;
      });
    }
  };

  const isValidEntry = (row) => {
    return row.collectedAt && 
           isValidDate(row.collectedAt) && 
           row.apobValue && 
           Number(row.apobValue) >= 0;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="launch-total-exposure">
        <Paragraph className="launch-title">Tracking Lifetime ApoB Exposure</Paragraph>

        <Paragraph className="launch-description">
          Your cardiovascular health isn't just about a single ApoB reading—it's about the total amount of ApoB your arteries have endured over your lifetime. This is the key to understanding what truly drives plaque buildup and cardiovascular disease.
        </Paragraph>

        <Paragraph className="launch-description">
          Getting started with this tool is simple: just upload your ApoB readings below. It will estimate your total exposure to ApoB so far and predict when you might start facing serious cardiovascular risks like heart attacks in the future.
        </Paragraph>

        <Paragraph className="launch-description">
          If you have any questions, <a className="chat-link" onClick={onChat}>send us a message</a> and we're happy to help!
        </Paragraph>

        <Stack spacing={2}>
          {!token && (
            <div>
              <Paragraph style={{ marginBottom: 8 }}>Date of Birth</Paragraph>
              <DatePicker
                value={dob}
                onChange={setDob}
                className="dob-field"
                format="MM/DD/YYYY"
                slotProps={{ 
                  textField: { 
                    fullWidth: true,
                    size: "small",
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '6px',
                        fontSize: '14px',
                        '& fieldset': {
                          borderColor: '#d9d9d9'
                        },
                        '&:hover fieldset': {
                          borderColor: '#bfbfbf'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: Color.success
                        }
                      }
                    }
                  } 
                }}
              />
            </div>
          )}

          <TableContainer component={Paper} sx={{ 
            '& .MuiTableCell-root': {
              borderBottom: '1px solid #f0f0f0'
            },
            '& .MuiTableHead-root': {
              backgroundColor: '#fafafa'
            }
          }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Test Date</TableCell>
                  <TableCell>ApoB Value (mg/dL)</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSource.map((record) => (
                  <TableRow 
                    key={record.key} 
                    sx={{ '&:hover': { backgroundColor: '#fafafa' } }}
                  >
                    <TableCell>
                      <Tooltip 
                        title={!record.isSelfReported ? "This value cannot be modified as it was returned from our clinical lab" : ""}
                      >
                        <div>
                          <DatePicker
                            value={record.collectedAt}
                            onChange={(date) => handleDateChange(record.key, date)}
                            format="MM/DD/YYYY"
                            disabled={!record.isSelfReported}
                            slotProps={{ 
                              textField: { 
                                fullWidth: true,
                                size: "small",
                                sx: {
                                  '& .MuiOutlinedInput-root': {
                                    borderRadius: '6px',
                                    fontSize: '14px',
                                    '& fieldset': {
                                      borderColor: '#d9d9d9'
                                    },
                                    '&:hover fieldset': {
                                      borderColor: '#bfbfbf'
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: Color.success
                                    },
                                    '&.Mui-disabled': {
                                      backgroundColor: 'transparent',
                                      '& input': {
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)'
                                      }
                                    }
                                  }
                                }
                              } 
                            }}
                          />
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip 
                        title={!record.isSelfReported ? "This value cannot be modified as it was returned from our clinical lab" : ""}
                      >
                        <div>
                          <TextField
                            type="number"
                            inputProps={{ min: "0" }}
                            onKeyDown={(e) => {
                              if (e.key === '-') {
                                e.preventDefault();
                              }
                            }}
                            fullWidth
                            size="small"
                            placeholder="80"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '6px',
                                fontSize: '14px',
                                '& fieldset': {
                                  borderColor: '#d9d9d9'
                                },
                                '&:hover fieldset': {
                                  borderColor: '#bfbfbf'
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: Color.success
                                },
                                '&.Mui-disabled': {
                                  backgroundColor: 'transparent',
                                  '& input': {
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)'
                                  }
                                }
                              }
                            }}
                            disabled={!record.isSelfReported}
                            value={record.apobValue || ''}
                            onChange={(e) => handleValueChange(record.key, e.target.value)}
                          />
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {dataSource.length > 1 && record.isSelfReported && (!(!record.collectedAt && !record.apobValue)) && (
                        <Button
                          danger
                          icon={<DeleteIcon style={{ fontSize: 18 }} />}
                          onClick={() => handleDelete(record.key)}
                          loading={deletingKeys.has(record.key)}
                          style={{ height: 38, width: 38 }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack direction="row" spacing={2}>
            <Button
              type={dataSource.some(row => isValidEntry(row)) && isValidDOB(dob) ? "primary" : "default"}
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={!dataSource.some(row => isValidEntry(row)) || !isValidDOB(dob)}
              className="risk-calculator-button"
              size="large"
            >
              {isSubmitting ? 'Launching...' : 'Launch Risk Calculator'}
              {!isSubmitting && <RightOutlined />}
            </Button>
          </Stack>
        </Stack>
      </div>
    </LocalizationProvider>
  );
};