import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Row, Col, message, Select } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import { addNote, getNote, updateNote } from '../../services/note.service';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import NoteType from '../../enums/noteType.enum';
import { listPatients } from '../../services/patient.service';
import './noteForm.scss'

const { Item } = Form

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export const NoteForm = ({ open, setOpen, onSuccess, noteId, patientId, currentUserId }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedNoteType, setSelectedNoteType] = useState(NoteType.GENERAL)
  const [patients, setPatients] = useState([])

  useEffect(() => {
    if (open) {
      if (noteId) {
        setNoteFields()
      } 
    } else {
      form.resetFields()
    }
  }, [open])

  useEffect(() => {
    if (open && !patientId) {
      // Load patients when modal opens and no patientId is provided
      fetchPatients()
    }
  }, [open])

  const fetchPatients = async () => {
    try {
      const response = await listPatients()
      setPatients(response.map(patient => ({
        label: `${patient.firstName} ${patient.lastName}`,
        value: patient._id
      })))
    } catch (error) {
      message.error('Failed to load patients')
    }
  }

  const onCancel = () => {
    setOpen(false)
  }

  const setNoteFields = async () => {
    const {text, startDate, endDate, noteType} = await getNote(noteId)

    // Format dates if they exist
    const formattedStartDate = startDate ? new Date(startDate).toISOString().split('T')[0] : undefined
    const formattedEndDate = endDate ? new Date(endDate).toISOString().split('T')[0] : undefined

    setSelectedNoteType(noteType)
    form.setFieldsValue({
      text,
      noteType,
      ...(formattedStartDate && { startDate: formattedStartDate }),
      ...(formattedEndDate && { endDate: formattedEndDate })
    })
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const {
        text,
        startDate,
        endDate,
        noteType
      } = form.getFieldsValue()
      
      let params = {
        text,
        startDate,
        endDate,
        noteType,
        patient: patientId,
      }
      const response = noteId ? await updateNote(noteId, params) : await addNote(params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(noteId ? 'Note updated' : 'Note added')
      setOpen(false)
      if (!noteId) {
        form.resetFields()
      }
    } catch (err) {
      let msg = noteId ? 'Failed to update note' : 'Failed to add note'
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  const showDateFields = (type) => {
    return [
      NoteType.MEDICATION,
      NoteType.DIET,
      NoteType.EXERCISE,
      NoteType.SUPPLEMENT,
      NoteType.MEDICAL_CONDITION
    ].includes(type)
  }

  return (
      <Modal 
        open={open} 
        title={noteId ? 'Edit Note' : 'Add Note'}
        onCancel={onCancel}
        onOk={onOk}
        okText='Save'
        footer={[
          <ContinueButton
            onClick={onOk}
            text='Save Note'
            isSubmitting={isSubmitting}
          />
        ]}
      >
        <Form
          form={form}
          onFinish={onOk}
          layout='vertical'
          className='note-form'
        >
          {!patientId && (
            <Item
              label="Patient"
              name="patient"
              rules={[{ required: true, message: 'Please select a patient' }]}
            >
              <Select
                options={patients}
                placeholder="Select a patient"
              />
            </Item>
          )}

          <Item 
            label="Note Type"
            name="noteType"
            initialValue={NoteType.GENERAL}
            rules={[{ required: true, message: 'Please select a note type' }]}
          >
            <Select 
              options={Object.values(NoteType)
                .sort()
                .map(type => ({ label: type, value: type }))} 
              onChange={(value) => setSelectedNoteType(value)}
            />
          </Item>
         
          <Item 
            name="text"
            label="Note Text"
            rules={[{ required: true, message: 'Please enter a note' }]}
          >
            <ReactQuill theme="snow" modules={modules} />
          </Item>

          {showDateFields(selectedNoteType) && (
            <div className="date-fields-container" >
              <Row gutter={16}>
                <Col span={12}>
                  <Item 
                    label="Start Date"
                    name="startDate"
                  >
                    <Input type="date" />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item 
                    label="End Date"
                    name="endDate"
                  >
                    <Input type="date" />
                  </Item>
                </Col>
              </Row>

              <p className="date-fields-note">Warning: By adding dates to this note, it will be used for annotation in results display. Keep note short and concise.</p>
            </div>
          )}
          
        </Form>
      </Modal>
 
  )
}