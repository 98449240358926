import React, { useEffect, useRef, useState, useContext } from "react";
import { Button, Tag, Modal } from "antd";
import { GiftOutlined } from "@ant-design/icons";
import ImageGallery from "react-image-gallery";
import "./productHero.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import { useNavigate } from "react-router-dom";
import { ProductQuestions } from "../productQuestions/productQuestions.component";
import { RightOutlined, HeartOutlined } from "@ant-design/icons"
import { loadStripe } from "@stripe/stripe-js";
import { UserContext } from "../../../contexts/user.context";

export const ProductHero = ({product, isMobileOrTablet}) => {

    const navigate = useNavigate()
    const ctaRef = useRef(null);  // Ref for the CTA button
    const [showScrollButton, setShowScrollButton] = useState(false);
    const stripeMessagingRef = useRef(null);
    const { instalabMembership } = useContext(UserContext);

    useEffect(() => {
        const handleScroll = () => {
        const scrollPosition = window.scrollY;
        // Show the button after scrolling 400px
        if (scrollPosition > 400) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (product.hasKlarna) {
            loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY).then(stripe => {
                const options = {
                    amount: (product.memberPrice || product.originalPrice) * 100, // convert to cents
                    currency: 'USD',
                    paymentMethodTypes: ['klarna'],
                    countryCode: 'US',
                };
                
                const elements = stripe.elements();
                const messagingElement = elements.create('paymentMethodMessaging', options);
                messagingElement.mount(stripeMessagingRef.current);
            });
        }
    }, [product]);

    const onGo = () => {
        if (product.cta.url) {
            navigate(product.cta.url)
        }
        if (product.cta.onClick) {
            product.cta.onClick()
        }
    }

  
    return (   
        <div className="product-detail-hero-container">
                      
            <div className="product-photos-desktop">
                {product.photos.length === 1 ? <img className="product-photos-solo" src={product.photos[0].original}/> :
                <ImageGallery 
                    items={product.photos}
                    showThumbnails={!isMobileOrTablet}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={isMobileOrTablet}
                    thumbnailClass="product-photos-thumbnail"
                    showNav={false}
                    slideOnThumbnailOver={true}
                />}
            </div>
            <div className="product-description">
                <h1 className="product-title">{product.title}</h1>
                <p className="product-oneliner">{product.oneliner}</p>

                {product.originalPrice && (
                     <div className="product-pricing">
                        <span className={`product-price ${instalabMembership && product.memberPrice && product.memberPrice < product.originalPrice ? 'crossed-out' : ''}`}>
                            ${product.originalPrice}{" "}
                        </span>
                        {product.memberPrice && instalabMembership && product.memberPrice < product.originalPrice &&
                            <>
                                <span className="product-member-price">${product.memberPrice}</span>
                                <Tag className="member-tag">For Members</Tag>
                            </>
                        }
            
                    </div>)}
                    {product.hasKlarna &&
                        <div className="bnpl-option">
                            <div ref={stripeMessagingRef}></div>
                        </div>}


                { product.note && !instalabMembership && <p className="product-note">{product.note}</p> }

                <div ref={ctaRef} className={`product-cta`}>
                    <Button 
                        className="product-cta-button" 
                        size="large" 
                        type="primary" 
                        onClick={onGo}
                        icon={<HeartOutlined/>}
                    >
                        Get for Yourself
                        {/* {product.cta.text} */}
                    </Button>


                    {product.giftCta && <Button 
                        className="product-gift-button" 
                        size="large"
                        type="default"
                        icon={<GiftOutlined />}
                        onClick={() => navigate(product.giftCta.url)}
                    >
                        {product.giftCta.text}
                    </Button>}

                </div>

                <div className="product-photos-mobile">
                    <ImageGallery 
                        items={product.photos}
                        showThumbnails={!isMobileOrTablet}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showBullets={isMobileOrTablet}
                        thumbnailClass="product-photos-thumbnail"
                        showNav={false}
                    />
                </div>
            

                {product.faqs && 
                <ul className="product-top-faq">
                    <ProductQuestions questions={product.faqs.slice(0,4)}/>
                </ul>}
            </div>    

          

            {/* Scroll Button */}
            {showScrollButton && (
            <Button
                type="primary"
                className="product-cta-button fixed-cta"
                onClick={onGo}
            >
                {product.cta.text} <RightOutlined className="btn-icon" />
            </Button>)}

        </div>
        
    )
}