import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Button, Empty, Typography, Statistic } from 'antd';
import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { BlogData } from '../../data/blog.data';
import { UserContext } from '../../contexts/user.context';
import { SupportSidebar } from '../supportSidebar/supportSidebar.component';
import { TestCatalog } from '../testCatalog/testCatalog.component';
import { listAppointments } from "../../services/appointment.service";
import AppointmentStatus  from '../../enums/appointmentStatus.enum';
import dayjs from 'dayjs';
import './dashboard.scss';
import { listProductTypes } from '../../services/productType.service';
import { getMe } from '../../services/user.service';
import { ResearchCard } from '../researchCard/researchCard.component';
import AddFundsModal from '../addFundsModal/addFundsModal.component';
import { Spin } from 'antd';
import TestHelper from '../../helpers/test.helper';
import RecommendationHelper from '../../helpers/recommendation.helper';
import AppointmentCard from '../appointmentCard/appointmentCard.component';
const { Title, Paragraph, Text } = Typography;

const appointmentSelect = '_id status location start type products facility provider remoteLocation';
const appointmentPopulate = [{
  path: 'products',
  populate: [{
    path: 'productType'
  }]
}, {
  path: 'facility'
}, {
  path: 'provider',
  select: 'firstName lastName npi'
}];



export const Dashboard = () => {
  const { currentUser, memberships, instalabMembership } = useContext(UserContext);
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState([]);
  const [consults, setConsults] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [cashBalance, setCashBalance] = useState(null);
  const [openAddFundsModal, setOpenAddFundsModal] = useState(false);
  const [isBalanceAnimating, setIsBalanceAnimating] = useState(false);
  const [recommendedTests, setRecommendedTests] = useState([]);

  useEffect(() => {
    document.title = `Home | Instalab`;
    fetchCashBalance();
    fetchAppointments();
    fetchProductTypes();
  }, []);


  useEffect(() => {
    const recommendations = RecommendationHelper.getRecommendations(currentUser);
    setRecommendedTests(recommendations);
  }, [currentUser]);

  const fetchCashBalance = async () => {
    const response = await getMe({
      select: 'cashBalance',
    });
    setCashBalance(response.cashBalance || 0);
  }

  const fetchAppointments = async () => {
    try {
      const currentDate = new Date();
      
      // Fetch confirmed future appointments
      const confirmedAppts = await listAppointments({ 
        filter: {
          products: { $exists: true, $not: { $size: 0 } },
          status: AppointmentStatus.CONFIRMED,
          start: { $gte: currentDate }
        },
        select: appointmentSelect,
        populate: appointmentPopulate,
        sort: 'start',
      });

      // Fetch all pending appointments
      const pendingAppts = await listAppointments({ 
        filter: {
          products: { $exists: true, $not: { $size: 0 } },
          status: AppointmentStatus.PENDING
        },
        select: appointmentSelect,
        populate: appointmentPopulate,
        sort: 'start',
      });

      // Combine and sort appointments
      const allAppts = [
        ...confirmedAppts.sort((a, b) => new Date(a.start) - new Date(b.start)),
        ...pendingAppts.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      ];

      setAppointments(allAppts);
    } catch (error) {
      console.error('Failed to fetch appointments:', error);
    }
  };


  const fetchProductTypes = async () => {
    const types = await listProductTypes();
    setProductTypes(types);
  }

  const getLatestResearch = () => {
    return BlogData.filter(blog => blog.isPublished).slice(0, 4);
  };


  const onAddFunds = async (amount) => {
    setIsBalanceAnimating(true);
    setCashBalance(cashBalance + amount);
    setOpenAddFundsModal(false);
    
    // Remove animation class after animation completes
    setTimeout(() => {
      setIsBalanceAnimating(false);
    }, 1000);
  }

  const BalanceCard = () => {
    return (
      <Card 
        className="section-card flat balance-card" 
        style={{ marginBottom: 24 }}
        title="Instalab Cash"
        actions={[
          <Button 
          type="link"
          size="middle"
          className="gray-link"
          icon={<PlusOutlined />}
          onClick={() => setOpenAddFundsModal(true)}
        >
          Add Funds
        </Button>
        ]}
      >

        <div className="balance-content">
          <Row align="top" justify="space-between" gutter={[16, 16]}>
            <Col>
              <Text 
                className={`balance-amount ${isBalanceAnimating ? 'balance-updated' : ''}`} 
              >
                {cashBalance !== null ? `$${cashBalance.toFixed(2)}` : <Spin />}
              </Text>
              <Text type="secondary" className="balance-description">
                Pre-fund your account for tests or services. HSA/FSA eligible.
              </Text>
            </Col>

          </Row>
        </div>
      </Card>
    )
  }

  const groupCredits = (credits) => {
    return credits.reduce((acc, credit) => {
      const key = credit;
      if (!acc[key]) {
        acc[key] = {
          code: credit,
          count: 1
        };
      } else {
        acc[key].count += 1;
      }
      return acc;
    }, {});
  };

  const getProductType = (credit) => {
    return productTypes.find(type => type.code === credit);
  }

  const filteredCredits = (credits) => {
    return credits?.filter(credit => {
      return getProductType(credit)?.flowType;
    });
  }

  return currentUser && (
    <div className="dashboard">
      <AddFundsModal
        open={openAddFundsModal}
        setOpen={setOpenAddFundsModal}
        onSuccess={onAddFunds}
      />

      <Row gutter={[24, 24]}>
        {/* Welcome Section */}
        <Col span={24}>
          <Card className="section-card flat" style={{ marginBottom: 0 }}>
            <Title level={2}>
              {currentUser.firstName ? 
                dayjs().diff(dayjs(currentUser.createdAt), 'day') > 0 ?
                  `Welcome back, ${currentUser.firstName}.` :
                  `Welcome, ${currentUser.firstName}.` :
                'Welcome!'}
            </Title>
          <Paragraph>Let us know what you'd like to do next.</Paragraph>
          </Card>
        </Col>


        {/* Biomarker Status Section */}
        {currentUser?.stats && currentUser?.stats?.totalTests > 0 && <Col span={24}>
          <Card 
            className="section-card flat biomarker-status-card" 
            style={{ marginBottom: 0 }}
            title="Your Health Status"
            actions={[
              <Button type="gray-link" onClick={() => navigate('/patient/results')}>
                View All Results <ArrowRightOutlined />
              </Button>
            ]}
          >


            {/* Longevity Score Card */}
            {currentUser.stats.longevityScore && (
              <Card className={`longevity-score-card ${TestHelper.getLongevityRisk(currentUser.stats.longevityScore)}`} bordered={false}  onClick={() => navigate(`/patient/results`)}>
                <Row align="middle">
                  <Col xs={24} md={14}>
                    <Title level={4} style={{ marginTop: 0, marginBottom: 8 }}>Longevity Score</Title>
                    <Text type="secondary" style={{ fontSize: '14px', lineHeight: '1.5' }}>
                    A higher score reflects a healthier trajectory based on your biomarker levels. Positive changes in your habits can boost your score over time.
                    </Text>
                  </Col>
                  <Col xs={24} md={10}>
                    <div className={`score-container`}>
                      <Statistic 
                        value={currentUser.stats.longevityScore}
                        suffix="/100"
                        style={{ fontWeight: 600 }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            )}

            {/* Biomarker Stats Cards */}
            <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
              <Col xs={24} sm={8}>
                <Card 
                  className="stat-card optimal" 
                  bordered={false}
                  onClick={() => navigate('/patient/results?filter=low-risk')}
                  style={{ cursor: 'pointer' }}
                >
                  <Statistic 
                    title="Optimal" 
                    value={currentUser.stats.optimal}
                    suffix="Biomarkers"
                  />
                  <Text type="secondary">Within healthy range</Text>
                </Card>
              </Col>

              <Col xs={24} sm={8}>
                <Card 
                  className="stat-card borderline" 
                  bordered={false}
                  onClick={() => navigate('/patient/results?filter=high-moderate-risk')}
                  style={{ cursor: 'pointer' }}
                >
                  <Statistic 
                    title="Borderline" 
                    value={currentUser.stats.borderline}
                    suffix="Biomarkers"
                  />
                  <Text type="secondary">Near optimal range</Text>
                </Card>
              </Col>

              <Col xs={24} sm={8}>
                <Card 
                  className="stat-card at-risk" 
                  bordered={false}
                  onClick={() => navigate('/patient/results?filter=high-risk')}
                  style={{ cursor: 'pointer' }}
                >
                  <Statistic 
                    title="Needs Attention" 
                    value={currentUser.stats.atRisk}
                    suffix="Biomarkers"
                  />
                  <Text type="secondary">Outside optimal range</Text>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>}

  
        {/* Main Content Area */}
        <Col xs={24} lg={15}>

          {/* Appointments Section */}
          {(appointments?.length > 0 ) && 
          <Card className="section-card flat" title="Upcoming Appointments" style={{ marginBottom: 24 }}>

              <Row gutter={[24, 24]}>
                {appointments.map((appointment) => (
                  <Col xs={24} key={appointment._id}>
                    <AppointmentCard appointment={appointment} defaultView={false} />
                  </Col>
                ))}
                
  
              </Row>            
          </Card>}

          {/* Credits Section */}
          {filteredCredits(currentUser?.credits)?.length > 0 && 
            <Card className="section-card flat" title="Available Credits" style={{ marginBottom: 24 }}>
              <Row gutter={[24, 24]}>
                {Object.values(groupCredits(currentUser?.credits)).map((credit) => (
                  getProductType(credit.code)?.flowType && 
                  <Col xs={24} key={credit.code}>
                    <Card 
                      className="appointment-card"
                      hoverable
                      bordered={true}
                    >
                      <div className="appointment-card-content">
                        <Row justify="space-between" align="top">
                          <Col flex="1">
                            <div className="tests">
                              {getProductType(credit.code)?.title} {credit.count > 1 ? `× ${credit.count}` : ''}
                            </div>
                            <Text type="secondary" className="description">
                              {getProductType(credit.code)?.tagline}
                            </Text>
                          </Col>
                          <Col>
                            <Button 
                              type="default"
                              onClick={() => navigate(`/flow/${getProductType(credit.code)?.flowType}`)}
                            >
                              Get Now
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card>
          }

          {/* Test Catalog Section */}
          
          {recommendedTests?.length >= 2 ? ( 
            <Card 
              className="section-card flat" 
              title="Recommended Tests"
              actions={[
                <Button type="gray-link" onClick={() => navigate('/catalog?category=recommended')}>
                  View All <ArrowRightOutlined />
                </Button>
              ]}
            >
              <TestCatalog 
                showAll={false}
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={12}
                viewMore={false}
                hideCategories={true}
                hidePrices={true}
                count={4}
                noFrills={true}
                defaultCategory="recommended"
              /> 
            </Card>
          ) : (
            <Card className="section-card flat" title="Recommended Tests">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                className="compact-empty"
                description={
                  <div className="empty-appointments">
                    <Text>No Recommended Tests Yet</Text>
                  <Button type="primary" size="middle" onClick={() => navigate('/start')}>
                    Get Started <ArrowRightOutlined />
                  </Button>
                    </div>
                }
              />

            </Card>

          )}
        

          {/* Latest Research Section */}
          <Card className="section-card flat" 
            style={{ marginTop: 24 }} 
            title="Latest Research"
            actions={[
              <Button type="gray-link" onClick={() => navigate('/research')}>
                View All <ArrowRightOutlined />
              </Button>
            ]}
          >
            <Row gutter={[16, 16]}>
              {getLatestResearch().map(({ id, title, description, publishedAt, readTime }) => (
                <Col xs={24} xxl={12} key={id}>
                  <ResearchCard
                    title={title}
                    description={description}
                    readTime={readTime}
                    publishedAt={publishedAt}
                    onClick={() => navigate(`/research/${id}`)}
                  />
                </Col>
              ))}
            </Row>
          </Card>
        </Col>

        {/* Right Sidebar */}
        <Col xs={24} lg={9}>
   
          <Row>
            <Col xs={24} lg={24}>
              <BalanceCard />
            </Col>
          </Row>

          { <SupportSidebar />} 
          

        </Col>
      </Row>
    </div>
  );
};