import React, { useEffect, useState, useContext} from 'react';
import { Row, Col, Spin, Table, Tabs, Typography, Affix, Anchor, Button, Drawer } from 'antd'
import './gameplan-v2.scss';
import { UserContext } from '../../contexts/user.context';
import classNames from "classnames";
import RecommendationData from "../../data/recommendation.data"
import ReportHelper from '../../helpers/report.helper';
import { Product } from "../../data/product.data";
import { ProductBox } from "../universalReport/productBox/productBox.component"
import { Panel, RecommendationKind, ReportStatus, Role } from '../../enums/index.enum';
import { TestModal } from "../testModal/testModal.component";
import { useParams } from 'react-router-dom';
import { listAnnotatedResults } from '../../services/result.service';
import { listAnnotatedTests } from '../../services/test.service';
import { ReferralBox } from '../universalReport/referralBox/referralBox.component';
import { RightOutlined, MenuOutlined } from '@ant-design/icons';
import { listRecommendations, getPatient } from '../../services/patient.service';
import { getMe } from '../../services/user.service';
const { Text } = Typography;

const sections = [
  { id: "follow-up", title: "Physician Consult", kind: RecommendationKind.PROVIDER },
  { id: "nutrition", title: "Nutrition", kind: RecommendationKind.NUTRITION },
  { id: "physical-activity", title: "Physical Activity", kind: RecommendationKind.EXERCISE },
  { id: "lifestyle", title: "Lifestyle", kind: RecommendationKind.LIFESTYLE },
  { id: "supplements", title: "Supplements", kind: RecommendationKind.SUPPLEMENT },
  { id: "medications", title: "Medications", kind: RecommendationKind.MEDICATION },
  { id: "testing", title: "Testing", kind: RecommendationKind.TESTING },
];

const patientPopulate = [{
  path: "memberships",
  populate: [{
    path: "membershipType"
  }]
}]

export const GameplanV2 = () => {
  const { currentUser } = useContext(UserContext)
  const { patientId } = useParams()
  const [recs, setRecs] = useState();
  const [params, setParams] = useState();
  const [testId, setTestId] = useState();
  const [openModal, setOpenModal] = useState();
  const [activeKey, setActiveKey] = useState([]);
  const [isLoading, setIsLoading] = useState()

  const [tests, setTests] = useState()
  const [results, setResults] = useState()
  // const [reports, setReports] = useState()
  const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
  const [recommendations, setRecommendations] = useState()
  const [patient, setPatient] = useState()

  useEffect(() => {
    document.title =`Instalab | Game Plan`
  }, [])

  useEffect(() => {

    const fetchPatientData = async () => {
      if (!currentUser) return
      setIsLoading(true)
      const isPatient = currentUser.role === Role.PATIENT
      const userId = isPatient ? currentUser._id : patientId
      setTests(await listAnnotatedTests({ patient: userId }))
      setResults(await listAnnotatedResults({ patient: userId }))
      setPatient(isPatient ? await getMe({populate: patientPopulate}) : await getPatient(userId, {populate: patientPopulate}))
      // setReports(await listReports({
      //   filter: {
      //     patient: userId,
      //   },
      //   select: 'status healthScore healthPercentile sections',
      //   populate: [{
      //     path: "patient",
      //     populate: {
      //       path: "memberships",
      //       populate: [{
      //         path: "membershipType"
      //       }]
      //     }
      //   }, {
      //     path: "result",
      //     populate: [{
      //       path: "answers",
      //       populate: [{
      //           path: "question",
      //           populate: {
      //             path: "questionTemplate",
      //           },
      //         }, {
      //           path: "result",
      //         },
      //       ],
      //     }, {
      //       path: "order",
      //     }],
      //   }, {
      //     path: "prevReport",
      //     populate: {
      //       path: "result",
      //     },
      //   }],
      //   sort: '-createdAt'
      // }))
      setIsLoading(false)
    }

    const fetchRecommendations = async () => {
      if (!currentUser) return
  
      try {
        const userId = currentUser.role === Role.PATIENT ? currentUser._id : patientId
        const recData = await listRecommendations(userId)
        let recs = []
        for (const rec of recData) {
          const recommendation = RecommendationData.find(recommendation => recommendation.code === rec.code)
          recs.push({
            ...rec,
            ...recommendation
          })
        }
  
  
        setRecommendations(recs)
      } catch (error) {
        console.error("Error fetching test recommendations", error)
      }
    }

    fetchPatientData()
    fetchRecommendations()

  }, [patientId, currentUser])

  // useEffect(() => {
  //   if (!reports || reports?.length<=0) return;


  //   let eligibelReports = reports.filter(
  //     ({ status, result }) =>
  //       [
  //         Panel.BASIC,
  //         Panel.ADVANCED_BASELINE,
  //         Panel.ADVANCED_FOLLOWUP,
  //         Panel.HEART_HEALTH,
  //         Panel.ATHLETE,
  //         Panel.CAC,
  //         Panel.DEXA
  //       ].includes(result?.order?.panel) && 
  //       status === ReportStatus.APPROVED 
  //   ).sort((a, b) => {
  //       const dateA = new Date(a?.result?.collectedAt).getTime();
  //       const dateB = new Date(b?.result?.collectedAt).getTime();
  //       return dateB - dateA;
  //   });


  //   if (currentUser?.role === Role.ADMIN || currentUser?.role === Role.PROVIDER) {
  //       eligibelReports = reports.filter(
  //         ({ result }) =>
  //           [
  //             Panel.BASIC,
  //             Panel.ADVANCED_BASELINE,
  //             Panel.ADVANCED_FOLLOWUP,
  //             Panel.HEART_HEALTH,
  //             Panel.ATHLETE,
  //             Panel.CAC,
  //             Panel.DEXA
  //           ].includes(result?.order?.panel)
  //       ).sort((a, b) => {
  //         const dateA = new Date(a?.result?.collectedAt).getTime();
  //         const dateB = new Date(b?.result?.collectedAt).getTime();
  //         return dateB - dateA;
  //       });
  //   }

  //   setReport(eligibelReports[0]);
  // }, [reports, patientId, currentUser]);

  useEffect(() => {
    if (!patient || !results || !tests) return;
    setParams(ReportHelper.getRecParamsV2(patient, results, tests, setTestId, setOpenModal))
  }, [patient,results, tests ]);

  useEffect(() => {
    if (!params || !recommendations) return;
    setRecs(getRecs(recommendations));
  }, [params, recommendations]);

  const handleCollapseChange = (key) => {
    setActiveKey(key);
  };


  console.log("recs", recs)
  console.log("params", params)

  const getRecs = (recs) => {

    const recommendationCodes = recs.map(rec => rec.code)
    const recItems = recommendationCodes.map(code => {
      const rec = RecommendationData.find(recommendation => recommendation.code === code);
      let product;
      
      if (rec.products) {
        if (typeof rec.products === 'function') {
          product = Product[rec.products(params)[0]];
        } else {
          product = Array.isArray(rec.products) && rec.products.length > 0 ? Product[rec.products[0]] : null;
        }
      }
      
      const data  = {
        // ...extraRecs.find(extraRec => extraRec.code === rec.code),
        code: rec.code,
        title: rec.title(params),
        description: rec.description(params),
        referralType: rec.referralType ? rec.referralType(params) : null,
        amazon: rec.amazon,
        product: product,
        buyUrl: product?.buyUrl,
        buyText: product?.buyText,
        hideProduct: rec.hideProduct,
        kind: rec.kind,
        foodList: rec.foodList ? rec.foodList(params) : null,
      };

      return data
    });


    // aggregate recommendations with the same code
    const newRecommendations = recItems.reduce((acc, recommendation) => {
      const existingRecommendation = acc.find(rec => rec.code === recommendation.code)
      if (existingRecommendation) {
        existingRecommendation.weight += recommendation.weight
        // Handle cases where one or both start dates might not exist
        console.log(existingRecommendation.start, recommendation.start)
        if (!existingRecommendation.start) {
          existingRecommendation.start = recommendation.start
        } else if (recommendation.start) {
          existingRecommendation.start = new Date(Math.min(
            new Date(existingRecommendation.start).getTime(),
            new Date(recommendation.start).getTime()
          )).toISOString();
        }
      } else {
        acc.push(recommendation)
      }
      return acc
    }, [])
     
    
    const categorizedRecItems = newRecommendations.reduce((acc, item) => {
      if (!acc[item.kind]) {
        acc[item.kind] = []; // If not, create an array for this kind
      }
      acc[item.kind].push(item); // Add the item to the array for its kind
      return acc; // Return the updated accumulator for the next iteration
    }, {});

    // Sort recommendations within each category
    Object.keys(categorizedRecItems).forEach(kind => {
      categorizedRecItems[kind].sort((a, b) => {
        // Items without start dates should appear first
        if (!a.start && !b.start) return 0;
        if (!a.start) return -1;
        if (!b.start) return 1;
        // Sort by start date
        return new Date(a.start) - new Date(b.start);
      });
    });

    const maxKindValue = Math.max(...Object.values(RecommendationKind));
    return Array.from({ length: maxKindValue + 1 }, (_, index) =>  categorizedRecItems[index] || []);
  }

  const RecItem = ({item}) => {
    return (
        <div className={classNames("advice-item-description")}>{item.description}
        {item.referralType && <ReferralBox referralType={item.referralType} patient={patient}/>}

        {item.hideProduct ? <></>  : <ProductBox style={{ marginTop: 20}} product={item.product}></ProductBox>}
        {item.foodList ? <FoodList items={item.foodList.items} restriction={item.foodList.restriction} /> : <></>}

        </div>
    )
  }


  const FoodTable = ({foods, restriction=[]}) => { 
    const filteredFoods = foods.filter(food => !food.exclude?.some(exclude => restriction.includes(exclude)))
  
    return (<Table 
      dataSource={filteredFoods} 
      columns={[{food: 'food', dataIndex:'food'}]}
      pagination={false}
      showHeader={false}
      className="foodTable"
      />) 
  }

  const FoodList = ({items, restriction}) => {
    return (
      <div style={{marginTop:10}}>
  
      <Tabs
        size={"small"}
        tabBarGutter={15}
        items={items.map((item, index) => {
          return {
            label: item.title,
            key: index,
            children: item.supplement ? <ProductBox style={{marginTop:10}} product={Product[item.supplement]}/> : <FoodTable restriction={restriction} foods={item.foods}/>,
          };
        })}
      />
  
      </div>
    )
  
  }

  const SectionRecs = ({recs, sections}) => {
    const items = sections.map((section) => recs[section]).flat()

    return (
      <div className="recommendation-list">
        {items.map((item) => (
          <div key={`rec-${item.code}`} className="recommendation-item">
            <div 
              className="recommendation-header"
              onClick={() => handleCollapseChange(activeKey.includes(item.code) ? 
                activeKey.filter(k => k !== item.code) : 
                [...activeKey, item.code]
              )}
            >
              <div className="recommendation-dot">
                <RightOutlined 
                  className={classNames("expand-icon", {
                    'expanded': activeKey.includes(item.code)
                  })} 
                />
              </div>
              <div className="recommendation-header-content">
                <h3 className="recommendation-title">{item.title}</h3>
                {/* {item.start && <div className="recommendation-start-date">
                  Recommended By: {new Date(item.start).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                </div>} */}
              </div>
            </div>
            
            {activeKey.includes(item.code) && (
              <div className="recommendation-content">
                <RecItem item={item} />
              </div>
            )}
          </div>
        ))}
      </div>
    )
  }

  const TableOfContents = ({ recs }) => {
    const visibleSections = sections.filter(section => recs[section.kind].length > 0);

    return (
      <Affix className="table-of-contents">
        <div>
          <Text className="toc-title">
            Game Plan
          </Text>
          <Anchor
            items={visibleSections.map((section) => {
              // const recCount = recs[section.kind].length;
              return {
                key: section.id,
                href: `#${section.id}`,
                title: `${section.title}`,
              };
            })}
          />
        </div>
      </Affix>
    );
  };

  const MobileTOCButton = () => (
    <Button
      type="primary"
      shape="circle"
      icon={<MenuOutlined />}
      size="large"
      className="mobile-toc-button"
      onClick={() => setMobileDrawerVisible(!mobileDrawerVisible)}
    />
  );

  return  <>

    {isLoading ? <>
      <div className="loading">
        <div className="loading-text">
          <Spin /> &nbsp;&nbsp;Loading
        </div>
      </div>
    </> : <>
      {recs &&   
        <div className="gameplan">
          <TestModal
            open={openModal}
            setOpen={setOpenModal}
            testId={testId}
            setTestId={setTestId}
            // report={report}
            tests={tests}
            results={results}
            // reports={reports}
          />

          <Row>
            <Col 
              xs={{ span: 0 }}
              sm={{ span: 0 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <TableOfContents recs={recs} />
            </Col>
            
            <Col 
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 20 }}
              lg={{ span: 20 }}
              xl={{ span: 20 }}
              xxl={{ span: 20 }}
              className="gameplan-main"
            >
              {Object.entries({
                'Physician Consult': [RecommendationKind.PROVIDER],
                'Nutrition': [RecommendationKind.NUTRITION],
                'Physical Activity': [RecommendationKind.EXERCISE],
                'Lifestyle': [RecommendationKind.LIFESTYLE],
                'Supplements': [RecommendationKind.SUPPLEMENT],
                'Medications': [RecommendationKind.MEDICATION],
                'Testing': [RecommendationKind.TESTING],

              }).map(([title, kinds]) => (
                recs[kinds[0]].length > 0 && (
                  <div key={title} id={title.toLowerCase().replace(/\s+/g, '-')} className="gameplan-section">
                    <h2 className="section-title">{title}</h2>
                    <SectionRecs recs={recs} sections={kinds} />
                  </div>
                )
              ))}
            </Col>
          </Row>

          <Drawer
            title="Game Plan"
            placement="left"
            onClose={() => setMobileDrawerVisible(false)}
            open={mobileDrawerVisible}
            className="mobile-toc-drawer"
          >
            <Anchor
              onClick={() => setMobileDrawerVisible(false)}
              items={sections
                .filter(section => recs[section.kind].length > 0)
                .map((section) => ({
                  key: section.id,
                  href: `#${section.id}`,
                  title: section.title
                }))}
            />
          </Drawer>

          <div className="mobile-only">
            <MobileTOCButton />
          </div>
        </div>
      }
    </>}
    </>
}