import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  message,
  Radio,
  Space,
  Form,
  Typography,
  Select,
  Skeleton,
  Button
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons"
import { addResult, getResult } from "../../services/result.service";
import MaskedInput from "antd-mask-input";
import "./testResultsModal.scss";
import moment from "moment";
import { ContinueButton } from "../continueButton/continueButton.component";
import { updateResult } from "../../services/result.service";
import { listTests } from "../../services/test.service";
import { listPatients } from "../../services/patient.service";
import { listLabs } from "../../services/lab.service";
import { listAbsoluteCategories } from "../../services/absoluteCategory.service";
import { Panel, Org, ResultStatus } from "../../enums/index.enum";

const { Text } = Typography;
const { Item } = Form;

export const TestResultsModal = ({
  open,
  setOpen,
  resultId,
  setResultId,
  onSuccess,
  select,
  populate,
  patientId
}) => {
  const [form] = Form.useForm();
  const [result, setResult] = useState();
  const [availableTests, setAvailableTests] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [absoluteCategories, setAbsoluteCategories] = useState();
  const [patients, setPatients] = useState();
  const [labs, setLabs] = useState();

  useEffect(() => {
    if (resultId) {
      fetchResult();
    }
  }, [resultId]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setResultId(null);
      setResult(null);
    }
  }, [open]);

  useEffect(() => {
    fetchAbsoluteCategories();
    fetchAvailableTests();
    fetchPatients();
    fetchLabs();
  }, []);

  useEffect(() => {
    if (result) {
      fetchFormValues();
    }
  }, [result]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (open && event.key === 'Enter') {
        event.preventDefault();
        onOk()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };  
  }, [open]);

  const fetchAbsoluteCategories = async () => {
    setAbsoluteCategories(await listAbsoluteCategories());
  };
  

  const fetchFormValues = () => {
    if (!resultId) return;

    let values = {
      patient: result.patient,
      collectedAt: moment(result.collectedAt).format("MM/DD/YYYY HH:mm Z"),
      hasRedraw: result.hasRedraw,
      panel: !result?.order || !result?.order.panel ? null : result?.order.panel,
      reqId: !result?.order || !result?.order.reqId ? null : result?.order.reqId,
      reqLink: !result?.order || !result?.order.reqLink ? null : result?.order.reqLink,
      lab: !result?.order || !result?.order.lab ? null : result?.order.lab,
      fasting: result.fasting,
      tests: result.values.map((val) => ({
        testName: val.test, // Use the test ID or name as required
        testValue: val.value,
      })),
      isApproved: result.status === ResultStatus.APPROVED,
    };

    form.setFieldsValue(values);
  };

  const fetchAvailableTests = async () => {
    setAvailableTests(await listTests());
  };

  const fetchPatients = async () => {
    try {
      const patients = await listPatients({
        filter: {
          org: Org.INSTALAB,
          firstName: {
            $ne: null
          },
          email: {
            $ne: null
          }
        },
        select: '_id firstName lastName',
        populate: []
      });
      setPatients(sortPatients(patients));
    } catch (error) {
      console.error('Failed to fetch patients:', error);
    }
  }

  const sortPatients = (unsortedPatients) => {
    return unsortedPatients.sort((a, b) => {
      const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
      const nameb = `${b.firstName} ${b.lastName}`.toLowerCase();
  
      if (nameA < nameb) {
          return -1;
      }
      if (nameA > nameb) {
          return 1;
      }
      return 0;
    })
  }

  const fetchLabs = async () => {
    setLabs(await listLabs());
  };

  const onCancel = () => {
    setOpen(false);
  };

  const fetchResult = async () => {
    setIsLoading(true);
    setResult(await getResult(resultId, {
      select: 'patient collectedAt hasRedraw fasting values',
      populate: [{
        path: 'order',
        select: 'panel reqId reeqLink lab',
      }]
    }));
    setIsLoading(false);
  };


  const onOk = async () => {
    setIsSubmitting(true);
    try {
      const formValues = form.getFieldsValue();
      const {
        patient,
        collectedAt,
        hasRedraw,
        panel,
        reqId,
        reqLink,
        lab,
        fasting,
        tests,
        isApproved,
      } = formValues;
      
      const selectedPatient = patientId || patient;
      
      if (!selectedPatient) {
        message.error('Please select a patient');
        setIsSubmitting(false);
        return;
      }

      // Initialize or update the values array
      let values = resultId ? [...result.values] : []; // Use the existing saved values

      // Only push changes to values
      let updatedValues = [];
      let updated = false;

      // Track which tests were provided in the current form submission
      const currentTestIds = tests ? tests.map(({ testName }) => testName) : [];

      if (Array.isArray(tests)) {
        for (const { testName, testValue } of tests) {
          const existingValue = values.find((value) => value.test === testName);

          // If there's a change or it's a new entry
          if (
            (existingValue && existingValue.value !== testValue) || // value changed
            (!existingValue && testValue !== null && testValue !== undefined) // new entry
          ) {
            updatedValues.push({
              test: testName,
              value: testValue !== undefined && testValue !== null ? testValue : null, // Set value to null if cleared
            });
            updated=true
          }
        }
      }

      // Check for removed values that were in the original result but not in the new form
      for (const existingValue of values) {
        if (!currentTestIds.includes(existingValue.test)) {
          // If a previously saved test is not in the current form, mark it as removed (value set to null)
          updatedValues.push({
            test: existingValue.test,
            value: null, // Mark as removed
          });
          updated = true;
        }
      }

      // Check if other fields have changed
      const fieldChanges = {
        patient: result?.patient !== selectedPatient,
        collectedAt: moment(result?.collectedAt).format("MM/DD/YYYY HH:mm Z") !== collectedAt,
        hasRedraw: result?.hasRedraw !== hasRedraw,
        panel: result?.order?.panel !== panel,
        reqId: result?.order?.reqId !== reqId,
        reqLink: result?.order?.reqLink !== reqLink,
        lab: result?.order?.lab !== lab,
        fasting: result?.fasting !== fasting,
        isApproved: result?.status !== ResultStatus.APPROVED,
      };

      // If any field has changed, mark the form as updated
      if (Object.values(fieldChanges).some((isChanged) => isChanged)) {
        updated = true;
      }

      // If there are no updates, don't send a request
      if (!updated) {
        message.info("No changes detected.");
        setIsSubmitting(false);
        return;
      }

      // Prepare the params object with the fields to be saved
      const params = {
        fields: {
          values: updatedValues, 
          patient: selectedPatient,
          collectedAt: moment(collectedAt, "MM/DD/YYYY HH:mm A Z").toDate(), 
          hasRedraw, 
          panel,
          lab,
          reqId,
          reqLink,
          fasting,
          status: isApproved ? ResultStatus.APPROVED : ResultStatus.PENDING,
          ...(isApproved && { approvedAt: new Date() }), // Only set approvedAt if isApproved is true
        },
        select, // The fields to select
        populate, // The fields to populate
      };


      // Determine if it's an update or a new entry and send the request
      const response = resultId ? await updateResult(resultId, params) : await addResult(params);
      onSuccess(response)
      setOpen(false);
      form.resetFields();
      message.info("Result saved");
    } catch (err) {
      message.error("Failed to save result");
    } finally {
      setIsSubmitting(false);
    }
  };

  return availableTests && (
    <Modal 
      open={open} 
      title='Test Results'
      onCancel={onCancel}
      okText='Save'
      width={700}
      className="test-results-modal"
      footer={[
        <ContinueButton
          onClick={onOk}
          text='Save'
          isSubmitting={isSubmitting}
        />
      ]}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (      
        <Form
          form={form}
          onFinish={onOk}
          layout='vertical'
        >

            {/* Patient Select - Only show if patientId is not provided */}
            {!patientId && (
              <div className="test-row">
                <Text className="test-name">Patient</Text>
                <div className="field-container">
                  <Item 
                    name={'patient'}
                    rules={[{ required: true, message: 'Please select a patient' }]}
                  >
                    <Select 
                      placeholder={'Patient'}
                      filterOption={(input, option) => 
                        option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
                      }
                      options={patients?.map(patient => {
                        return {
                          label: `${patient.firstName} ${patient.lastName}`,
                          value: patient._id
                        }
                      })}
                      className="patient-select"
                      showSearch
                    />
                  </Item>
                </div>
                <Text className="test-unit"></Text>
              </div>
            )}

            {/* Panel */}
            <div className="test-row">
                <Text className="test-name">Panel</Text>
                <div className="field-container">
                  <Item name={"panel"}>
                    <Select
                      placeholder={"Panel"}
                      filterOption={(input, option) => 
                        option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
                      }
                      options={Object.entries(Panel).map(([key, value]) => ({
                        label: key,
                        value: value,
                      }))}
                      className="panel-select"
                      showSearch
                    />
                  </Item>
                </div>
                <Text className="test-unit"></Text>
            </div>

            {/* Lab */}
            <div className="test-row">
              <Text className="test-name">Lab</Text>
              <div className="field-container">
                <Item name={'lab'}>
                  <Select 
                    placeholder={'Lab'}
                    options={labs?.map(lab => {
                      return {
                        label: lab.name,
                        value: lab._id
                      }
                    })}
                    className="lab-select"
                    filterOption={(input, option) => 
                      option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
                    }
                    showSearch
                  />
                </Item>
                </div>
                <Text className="test-unit"></Text>
            </div>

            {/* Req Id */}
            <div className="test-row">
              <Text className="test-name">Req Id</Text>
              <div className="field-container">
                <Item name={"reqId"}>
                  <Input
                    placeholder={"Requisition Id"}
                    className="test-value-input"
                  />
                </Item>
              </div>
              <Text className="test-unit"></Text>
            </div>      

            {/* Req Link */}
            <div className="test-row">
              <Text className="test-name">Req Link</Text>
              <div className="field-container">
                <Item name={"reqLink"}>
                  <Input
                    placeholder={"Requisition Link"}
                    className="test-value-input"
                  />
                </Item>
              </div>
              <Text className="test-unit"></Text>
            </div>   

            {/* Collected At */}
            <div className="test-row">
              <Text className="test-name">Collected</Text>
              <div className="field-container">
                <Item name={"collectedAt"}>
                  <MaskedInput
                    placeholder={"MM/DD/YYYY HH:MM Z"}
                    mask="00/00/0000 00:00 -00:00"
                    inputMode="decimal"
                    className="test-value-input"
                  />
                </Item>
              </div>
              <Text className="test-unit"></Text>
            </div>

            {/* Redraw */}
            <div className="test-row">
              <Text className="test-name">Redraw Required</Text>
              <div className="field-container">
                <Item name="hasRedraw">
                  <Radio.Group>
                    <Space direction="horizontal">
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Space>
                  </Radio.Group>
                </Item>
              </div>
              <Text className="test-unit"></Text>
            </div>

            {/* Fasting */}
            <div className="test-row">
              <Text className="test-name">Fasting</Text>
              <div className="field-container">
                <Item name="fasting">
                  <Radio.Group>
                    <Space direction="horizontal">
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Space>
                  </Radio.Group>
                </Item>
              </div>
              <Text className="test-unit"></Text>
            </div>

            {/* Approved */}
            <div className="test-row">
              <Text className="test-name">Approved</Text>
              <div className="field-container">
                <Item name="isApproved">
                  <Radio.Group>
                    <Space direction="horizontal">
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Space>
                  </Radio.Group>
                </Item>
              </div>
              <Text className="test-unit"></Text>
            </div>

            {/* Test Results */}
            <Item label="Test Results">
              <Form.List name="tests">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 5 }} align="baseline">
                        <Item
                          {...restField}
                          name={[name, 'testName']}
                          fieldKey={[fieldKey, 'testName']}
                          rules={[{ required: true, message: 'Missing test name' }]}
                        >
                        <Select
                            placeholder="Select Test"
                            style={{ width: 300 }}
                            options={availableTests.map(test => ({
                              value: test._id,
                              label: test.name,
                            }))}
                            showSearch
                            filterOption={(input, option) => {
                              const searchText = input.toLowerCase().replace(/\s/g, ''); // User input, stripped of spaces
                              const optionLabel = option.label.toLowerCase().replace(/\s/g, ''); // Option's label, stripped of spaces
                          
                              return optionLabel.includes(searchText); // Check if the label includes the search term
                            }}
                          />
                        </Item>
                        <Item
                          {...restField}
                          name={[name, 'testValue']}
                          fieldKey={[fieldKey, 'testValue']}
                          rules={[{ required: true, message: 'Missing test value' }]}
                        >
                          <Input placeholder="Value" min={0} style={{ width: 120 }} />
                        </Item>

                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Test Result
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Item>
        </Form>
      )}
    </Modal>
  )
};
