import React, { useEffect, useContext, useState } from 'react';
import { Row, Col, Switch } from 'antd';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { UserContext } from '../../contexts/user.context';
import MembershipTypeCode from '../../enums/membershipTypeCode.enum';
import { MembershipPlan } from '../membershipPlan/membershipPlan.component';
import "./memberships.scss";
import MembershipHelper from '../../helpers/membership.helper';
import { listMemberships } from '../../services/membership.service';

export const Memberships = () => {
  const { memberships, setMemberships, instalabMembership, setInstalabMembership } = useContext(UserContext);
  const [isYearly, setIsYearly] = useState(true);

  useEffect(() => {
    document.title = 'Membership | Instalab';
  }, []);


  useEffect(() => {
    if (memberships?.length) {
      const hasActiveAnnual = memberships.some(membership => 
        MembershipHelper.isAnnual(membership) && 
        MembershipHelper.isActive(membership)
      );
      setIsYearly(hasActiveAnnual);
    }
  }, [memberships]);


  const onMembershipPaymentUpdate = async () => {
    try {
      const updatedMemberships = await fetchMemberships();
      setMemberships(updatedMemberships);
      setInstalabMembership(MembershipHelper.getActiveInstalabMembership(updatedMemberships))
    } catch (error) {
      console.error('Failed to fetch updated memberships:', error);
    }
  };


  const fetchMemberships = async () => {
    try {
      const response = await listMemberships({populate:[{path:"membershipType"}, {path:"card"}]});
      return response;
    } catch (err) {
      console.error("Error fetching memberships", err);
      throw err;
    }
  }

  return memberships && (
    <div className="memberships">

      <h1 className="membership-title">
        Choose Your Plan
      </h1>
      
      <div className="toggle-container">
        <span className="toggle-label">Billed Monthly</span>
        <Switch 
          checked={isYearly}
          onChange={(checked) => setIsYearly(checked)}
          className="billing-toggle"
        />
        <span className="toggle-label">Billed Yearly</span>
      </div>


      <Row gutter={12} className="membership-plans-container">

        <Col xs={24} sm={24} lg={12} xl={12} className="membership-plan">
          <MembershipPlan
            key={`core-${memberships?.length}`}
            membershipTypeCode={isYearly ? MembershipTypeCode.LONGEVITY_12MO : MembershipTypeCode.LONGEVITY}
            onMembershipPaymentUpdate={onMembershipPaymentUpdate}  
            currentMembership={instalabMembership}
          />
        </Col>

        {/* <Col xs={24} sm={24} lg={12} xl={12} className="membership-plan">
          <MembershipPlan
            key={`premium-${memberships?.length}`}
            membershipTypeCode={isYearly ? MembershipTypeCode.PREMIUM_12MO : MembershipTypeCode.PREMIUM}
            onMembershipPaymentUpdate={onMembershipPaymentUpdate}  
            currentMembership={instalabMembership}
          />  
        </Col> */}
      </Row>

      <PageHeader title="Membership Add-Ons" />
      <Row gutter={12} className="membership-plans-container">
        <Col xs={24} sm={24} md={24} xl={12} className="membership-plan">
          <MembershipPlan
            key={`heart-health-${memberships?.length}`}
            membershipTypeCode={MembershipTypeCode.HEART_HEALTH}
            onMembershipPaymentUpdate={onMembershipPaymentUpdate}  
            currentMembership={memberships?.find(membership => MembershipHelper.isHeartHealth(membership) && MembershipHelper.isActive(membership))}
            path={`/heart`}
          />
        </Col>
      </Row>
    </div>
  );
};