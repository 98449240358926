import React, { useState } from 'react';
import { Drawer } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import './mobileResultsDrawer.scss';

const MobileResultsDrawer = ({ 
    isOpen, 
    onClose, 
    hasUpdates, 
    headerText, 
    hasResults,
    readyText="Results Ready",
    notReadyText="Not Ready",
    children
}) => {

    const [isMobileResultsOpen, setIsMobileResultsOpen] = useState(false);

    return (
        <>
        <div 
            className={`mobile-drawer-trigger ${hasUpdates? 'has-updates' : ''} ${hasResults ? 'has-results' : ''}`}
            onClick={() => setIsMobileResultsOpen(true)}
        >

            <div className="drawer-trigger-content">
                    <div className="trigger-header">
                        <div className="header-left">
                            <h2>{headerText}</h2>
                        </div>
                        <div className="pull-up-indicator">
                            {hasResults ? (
                                <>
                                <div className="results-preview">
                                    <CheckCircleOutlined className="results-icon" />
                                        <span>{readyText}</span>
                                </div>

                                <div className="pull-icons">
                                    <UpOutlined className="pull-icon" />
                                    <UpOutlined className="pull-icon delayed" />
                                </div>
                                </>
                            ) : (
                                <div className="results-preview">
                                    <CloseCircleOutlined className="results-icon" />
                                    <span>{notReadyText}</span>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
        </div>

        <Drawer
            placement="bottom"
            height="75vh"
            open={isMobileResultsOpen}
            onClose={() => setIsMobileResultsOpen(false)}
            title={
                <div 
                    className="drawer-header"
                    onClick={() => setIsMobileResultsOpen(false)}
                >
                    <h2>{headerText}<DownOutlined className="close-icon" />
                    </h2>
                </div>
            }
            className="mobile-results-drawer"
            styles={{
                body: { padding: '24px 16px 16px' },
            }}
            >
            {children}
        </Drawer>

    </>
    );
};

export default MobileResultsDrawer;