

const TestimonialData =  [
  {
    name: "Steph Simon",
    title: "CEO @ StealthCo",
    image: "/img/people/steph.jpeg",
    webp: "/img/people/steph.webp",
    text: "I thought that Instalab was mostly for testing at first. But it’s way more than that. I have a dedicated person (Allona) finding me doctors and scheduling and rescheduling my visits. I have a roadmap for testing, including a doctor to help me understand the results. And instead of pdfs in my email, my results are all in one place. At $49/month, I feel like I discovered a loophole or something - it’s an insanely good deal."
  },

  {
    name: "Jason Goldman",
    title: "Filmmaker @ Wild Growth Media",
    image: "/img/jason.jpg",
    webp: "/img/jason.webp",
    text: "Instalab uncovered something life-changing that I never would have found on my own—early osteoporosis caused by silent celiac disease. They took care of every detail, from arranging the right tests to connecting me with exceptional specialists for treatment. Beyond that, their ongoing support has given me the confidence to take charge of my health in a way I never thought possible."

  }, 
  {
    name: "Gustaf Alstromer",
    title: "Partner @ Y Combinator",
    image: "/img/gustaf.jpg",
    webp: "/img/gustaf.webp",
    text: "Staying on top of your health is a must for the best founders. Instalab made it simple for me by arranging all the testing and breaking down my results into actionable steps. Their personalized guidance helped me focus on the right areas to improve my health and longevity."
  },
  // {
  //     name: "Ed Suh",
  //     title: "MD @ Alpine Ventures ",
  //     image: "/img/ed.jpg", 
  //     webp: "/img/ed.webp",
  //     text: "Instalab’s comprehensive approach blew me away. Their detailed reports focused on the issues most relevant to me, with intuitive summaries and clear explanations of my risk factors. They made testing and follow-up care remarkably simple."
  // },
  {
    name: "Geoff Abraham",
    title: "Founder @ Spoken",
    image: "/img/geoff.jpg",
    text: "I didn’t know my body until I started working with Instalab. A lovely phlebotomist came to me on my schedule - shout out to Layla! It was very frustrating trying to get the same tests from my primary care provider. In 3 months, I have dramatically improved my biomarkers."
  },
  {
    name: "Kat Manalac",
    title: "Partner @ Y Combinator",
    image: "/img/kat.jpg",
    webp: "/img/kat.webp",
    text: "As a working parent, I don’t have much time to focus on my health. Instalab made it effortless by arranging everything from testing to results interpretation and providing a game plan that worked with my schedule. It’s never been easier to stay proactive about my health."
  },

  {
  image: `/img/heart/santi.jpg`,
  name: `Santi Mesa`,
  title: `BD @ SAP`,
  text: `Instalab has completely changed how I approach my health. I used to feel overwhelmed with scheduling tests and finding the right doctors, but now my concierge, Jasmine, takes care of all of it. I get reminders, updates, and even suggestions for what to prioritize next.`,
}, 
// {
//   image: `/img/heart/kenza.jpg`,
//   name: `Kenza El Madih`,
//   title: `Product Manager`,
//   quote: `"The Heart Health Program has been a game-changer for me. My doctor really tailored it to fit my specific health concerns, especially around my cholesterol and inflammation. The regular check-ins and coaching have kept me motivated and, for the first time, I feel confident about managing my heart health."`,
// }, {
//   image: `/img/heart/aaron.jpg`,
//   name: `Aaron Cheung`,
//   title: `Founder @ Homeaglow`,
//   text: `"I had struggled with high blood pressure and cholesterol for years. Instalab’s program gave me the structure I needed to get it under control. My doctor kept tweaking my treatment plan based on my test results until we found what worked. Now, my biomarkers are optimal and I’m grateful for the care and guidance that got me here."`,
// }
]


export default TestimonialData;