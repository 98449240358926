import { useState, useEffect } from "react";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component"
import { LowercaseTest, ReportSection, Condition, TestCode } from "../../../enums/index.enum";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

import "./inflammation.scss";

// TO DO:
// - identify possible factros in the description text - check for IR, obesity, fatty liver
// - merge in chronic inflammation 

const { Paragraph } = Typography;

export const InflammationCheck = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [inflammationTests, setInflammationTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [hasChronicInflammation, setHasChronicInflammation] = useState();

  // baseline or followup?
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();

  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.INFLAMMATION_CHECK));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.INFLAMMATION_CHECK));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.INFLAMMATION_CHECK));
  }, [report]);

  useEffect(() => {
    setInflammationTests(getInflammationTests());
  }, [report, tests]);

  useEffect(() => {
    setHasChronicInflammation(getHasChronicInflammation())
  }, [report, inflammationTests])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report, tests, results, inflammationTests, hasChronicInflammation, isFixed, isBetter, isBetter, isWorse]);


  const getInflammationTests = () => {
    if (!report || !tests ) return null;
    return ReportHelper.getSectionTests(report, ReportSection.INFLAMMATION_CHECK, tests);
  }

  const getHasChronicInflammation = () => {
    if (!report || !inflammationTests) return null;

    const conditions = report?.result?.conditions

    if (conditions?.includes(Condition.CHRONIC_INFLAMMATION)) return true
    const justHsCrp = inflammationTests.length === 1 && inflammationTests[0].code === TestCode.HS_CRP

    const count = (conditions?.includes(Condition.OBESITY)  ? 1 : 0)
                + (conditions?.includes(Condition.INSULIN_RESISTANCE) || conditions?.includes(Condition.INSULIN_RESISTANCE_MAYBE) ? 1 : 0)
                + (conditions?.includes(Condition.NAFLD_METABOLIC_SYNDROME) ||  conditions?.includes(Condition.NAFLD_METABOLIC_SYNDROME_LIKE) ? 1 : 0)

    return justHsCrp && ((count >=2) || conditions?.includes(Condition.OBESITY))
  }

  const getTestNames = (inflammationTests) => {

    const testNames = inflammationTests.map((test, index) => (
      <>
      <span key={test._id}>{LowercaseTest[test.code]}</span>
      {inflammationTests.length > 2 && index < inflammationTests.length - 1 && ', '}
      {inflammationTests.length > 1 && index < inflammationTests.length - 1 && ' and '}
      </>
    ));

    return testNames
  }

  const getConditionsString = (conditions) => {
    let conditionsString = [];
    if (conditions?.includes(Condition.OBESITY) || conditions?.includes(Condition.OVERWEIGHT))
      conditionsString.push("increased body weight");
    if (conditions?.includes(Condition.INSULIN_RESISTANCE) || conditions?.includes(Condition.INSULIN_RESISTANCE_MAYBE)) 
      conditionsString.push("insulin resistance");
    if (conditions?.includes(Condition.NAFLD_METABOLIC_SYNDROME) ||  conditions?.includes(Condition.NAFLD_METABOLIC_SYNDROME_LIKE))
      conditionsString.push("fatty liver");
  
    if (conditionsString.length > 1) {
      const lastCondition = conditionsString.pop();
      return conditionsString.join(", ") + " and " + lastCondition;
    } else {
      return conditionsString.join(", ");
    }
  }

  const getGoalsString = (conditions) => {
    let conditionsString = [];
    if (conditions?.includes(Condition.OBESITY) || conditions?.includes(Condition.OVERWEIGHT))
      conditionsString.push("decreasing body fat");
    if (conditions?.includes(Condition.INSULIN_RESISTANCE) || conditions?.includes(Condition.INSULIN_RESISTANCE_MAYBE)) 
      conditionsString.push("improving your insulin sensitivity");
  
    if (conditionsString.length > 1) {
      const lastCondition = conditionsString.pop();
      return conditionsString.join(", ") + " and " + lastCondition;
    } else {
      return conditionsString.join(", ");
    }
  }


  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !tests || !results || !inflammationTests) return null;
    let content = <></>

    if (ReportHelper.hasWorseningTrend(TestCode.HS_CRP, report, results, tests)) {
      content = <>Your <span>hs-CRP</span> is <span>increasing</span>, indicating possible chronic inflammation.</>
    }

    else if (hasChronicInflammation)
      content = <>Your <span>hs-CRP</span> continues to be <span>{ReportHelper.getRiskEval(TestCode.HS_CRP, report, results, tests)}</span>, a protein made by your liver in response to <span>inflammation</span>.</>
    else 
      content = <>Your {getTestNames(inflammationTests)} {inflammationTests.length > 1 ? 'are ' : 'is '}<span>{ReportHelper.getRiskEval(inflammationTests[0].code, report, results, tests)}</span>, indicating possible chronic inflammation or a response to a recent acute event.</>


    return content;

  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report || !tests || !inflammationTests) return null;

    let content = <></>
    const conditions = report?.result?.conditions

    if (hasChronicInflammation) 
      content = <>
        {getConditionsString(conditions) &&  <>Your hs-CRP is high, which signals a state of inflammation in your body. This may be caused by a variety of factors, including {getConditionsString(report?.result?.conditions)}.<br/><br/></>} 
        
        Persistently high levels of hs-CRP are concerning as they indicate chronic inflammation, which is a well-established risk factor for heart disease, type 2 diabetes, and some cancers. 
        
        {getConditionsString(conditions) 
          ? <><br/><br/>Based on your other results, it is advisable double down on {getGoalsString(conditions)}.</>
          : <><br/><br/>If there hasn't been any recent acute incident, like an injury or viral infection, since your last blood test, it's advisable to consult your physician to determine the cause of these changes before they worsen.</>
        }
      </>
    else
      content = <>
      Your  {getTestNames(inflammationTests)} levels may temporarily spike due to acute events such as infections, injuries, or intense exercise. These short-term increases are part of a natural and healthy immune response. However, if this remains elevated over time, it could indicate chronic inflammation, which may be linked to underlying health concerns.
      
      {/* Temporary {getTestNames(inflammationTests)} spikes often result from acute events, e.g. infections, injury or intense exercise.  This is a natural, healthy immune response. However, prolonged elevation, i.e. chronic inflammation, may indicate a serious issue. */}
                <br/><br/>To better understand your results, it’s important to determine whether this is temporary or persistent. We recommend a follow-up test to monitor your levels before making any conclusions or adjustments.</> 

    return content
  }

  return (
    inflammationTests && (
      <div className="inflammation-check">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">{description}</Paragraph>
        <TestView 
          report={report} 
          tests={inflammationTests}
          results={results}
          reports={reports}
          user={user}
        />
        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};