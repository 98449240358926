import React, { useEffect, useState, useRef, useContext } from 'react'
import { message, Tooltip, Avatar, Modal, Button, Checkbox } from 'antd'
import { completeTask, listTasks, openTask, removeTask } from "../../services/task.service"
import { KeyOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { PageHeader } from '../pageHeader/pageHeader.component'
import TaskStatus from '../../enums/taskStatus.enum.js'
import { TaskForm } from '../taskForm/taskForm.component';
import parse from 'html-react-parser';
import { useSearchParams } from 'react-router-dom'
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import './adminTasks.scss'
import PatientHelper from '../../helpers/patient.helper.js'
import { TaskModal } from '../taskModal/taskModal.component.js'
import UrlHelper from '../../helpers/url.helper.js'
import Role from '../../enums/role.enum.js'
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js'
import moment from 'moment'
import { UserContext } from '../../contexts/user.context.js'

export const AdminTasks = () => {
  const [tasks, setTasks] = useState([])
  const tasksRef = useRef()
  tasksRef.current = tasks

  const [isLoading, setIsLoading] = useState(true)
  const [searchParams] = useSearchParams()
  const [filteredCount, setFilteredCount] = useState();
  const [isTaskModalOpen, setIsTaskModalOpen] = useState()
  const [taskId, setTaskId] = useState()
  const { currentUser, setCounts } = useContext(UserContext)
  const [openTaskModal, setOpenTaskModal] = useState()
  const [assignees, setAssignees] = useState([])

  useEffect(() => {
    document.title = 'Instalab | Tasks'
    fetchTasks()
  }, [])

  useEffect(() => {
    autoOpenTaskModal()
  }, [searchParams])

  const autoOpenTaskModal = () => {
    const id = searchParams.get('id')
    if (id) {
      setTaskId(id)
      setOpenTaskModal(true)
    }
  }

  const fetchTasks = async () => {
    setIsLoading(true)

    let params = {
      select: '_id patient isUrgent status title deadline assignee',
      filter: {},
      populate: [{
        path: 'patient',
        select: '_id firstName lastName'
      }, {
        path: 'assignee',
        select: 'photo firstName lastName'
      }],
    }

    const fetchedTasks = await listTasks(params)
    setAssignees([...new Set(fetchedTasks.filter(({ assignee }) => assignee).map(({ assignee }) => `${assignee.firstName} ${assignee.lastName}`))].sort())
    setTasks(sortTasks(fetchedTasks))
    setFilteredCount(fetchedTasks.length)
    setIsLoading(false)
  }

  const sortTasks = (unsortedTasks) => {
    return unsortedTasks.sort((a, b) => {
      const now = new Date()
      const aDeadlinePassed = new Date(a.deadline) < now
      const bDeadlinePassed = new Date(b.deadline) < now

      // Active tasks with passed deadline first
      if (a.status === TaskStatus.ACTIVE && aDeadlinePassed && 
          !(b.status === TaskStatus.ACTIVE && bDeadlinePassed)) {
        return -1
      } else if (b.status === TaskStatus.ACTIVE && bDeadlinePassed && 
                 !(a.status === TaskStatus.ACTIVE && aDeadlinePassed)) {
        return 1
      }
      
      // On Hold tasks second
      else if (a.status === TaskStatus.ON_HOLD && b.status !== TaskStatus.ON_HOLD) {
        return -1
      } else if (b.status === TaskStatus.ON_HOLD && a.status !== TaskStatus.ON_HOLD) {
        return 1
      }
      
      // Active tasks with future deadline third
      else if (a.status === TaskStatus.ACTIVE && !aDeadlinePassed && 
               b.status !== TaskStatus.ACTIVE) {
        return -1
      } else if (b.status === TaskStatus.ACTIVE && !bDeadlinePassed && 
                 a.status !== TaskStatus.ACTIVE) {
        return 1
      }
      
      // Complete tasks last, sorted by deadline descending
      else if (a.status === TaskStatus.COMPLETE && b.status === TaskStatus.COMPLETE) {
        return new Date(b.deadline) - new Date(a.deadline)
      }
      
      // Within same status groups, sort by deadline
      return new Date(a.deadline) - new Date(b.deadline)
    })
  }
  
  const handleTaskStatusChange = async ({ _id, title }, checked) => {
    let updatedTask
    if (checked) {
      updatedTask = await completeTask(_id)
      message.success(`Task completed: ${title}`)
    } else {
      updatedTask = await openTask(_id)
      message.info(`Task reopened: ${title}`)
    }
    setTasks(sortTasks([...tasksRef.current].map(t => t._id === _id ? updatedTask : t)))
  }

  const onEdit = (task) => {
    setTaskId(task._id)
    setIsTaskModalOpen(true)
  }

  const onRemove = async (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this task?',
      content: 'Deleting this task cannot be undone.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removeTask(_id);
          setTasks(tasks.filter(task => task._id !== _id));
          message.info('Task removed');
          setCounts(cachedCounts => {
            return {
              ...cachedCounts,
              tasks: cachedCounts.tasks - 1
            }
          })
          setFilteredCount(cachedFilterCount => cachedFilterCount-1)
        } catch (err) {
          message.error('Failed to remove task');
        }
      }
    });
  }

  const getActionItems = (task) => {
    let menuItems = [  
    {
      key: 'edit',
      label: (
        <Button onClick={() => onEdit(task)}>
          <EditOutlined /> Edit
        </Button>
      ),
    }]
    if (currentUser?.role === Role.ADMIN) {
      menuItems.push(
      {
        key: 'impersonate',
        label: (
          <Button onClick={() => PatientHelper.onImpersonate(task.patient)}>
            <KeyOutlined /> Impersonate
          </Button>
        ),
      },
      {
        type: 'divider'
      },
      {
        key: 'remove',
        label: (
          <Button
            onClick={() => onRemove(task._id)}
            className="remove-item"
          >
            <DeleteOutlined /> Remove
          </Button>
        )
      })
    }
    return menuItems
  }

  const onTaskSuccess = (task) => {
    if (tasks.some(t => t._id === task.id)) {
      setTasks(tasks.map(t => t._id === task._id ? task : t))
    } else {
      setTasks([
        task,
        ...tasks,
      ])
      setCounts(cachedCounts => {
        return {
          ...cachedCounts,
          tasks: cachedCounts.tasks + 1
        }
      })
      setFilteredCount(cachedFilterCount => cachedFilterCount+1)
    }
  }

  const getCustomFilter = (task, value) => {
    return {
      patient: () => task.patient?.firstName && `${task.patient.firstName.toLowerCase()} ${task.patient.lastName.toLowerCase()}`.includes(value.toLowerCase()),
      assignee: () => task.assignee && `${task.assignee.firstName} ${task.assignee.lastName}` === value,
    }
  }

  const rowClassName = (record) => {
    let className = '';
    if (record.isUrgent) {
      className += 'deadline-passed';
    }
    if (record.status === TaskStatus.COMPLETE) {
      className += 'strikethrough-row';
    }
    if (record.status === TaskStatus.ON_HOLD) {
      className += 'on-hold-row';
    }
    return className;
  }

  const handleTaskFormSuccess = (response, taskId) => {
    if (taskId) {
      setTasks(tasks => tasks.map(task => 
        task._id === taskId ? response : task
      ));
    } else {
      setTasks(tasks => [...tasks, response]);
    }
  };

  return (
    <div className="admin-tasks">
      <PageHeader
        title='Concierge Tasks'
        count={filteredCount}
        actions={
          <Button
            type="primary"
            onClick={() => {
              setTaskId(null);
              setIsTaskModalOpen(true);
            }}
          >
            + Add Task
          </Button>
        }
      />

      <TaskModal
        open={openTaskModal}
        setOpen={setOpenTaskModal}
        taskId={taskId}
        setTaskId={setTaskId}
        onSuccess={onTaskSuccess}
      />

      <TaskForm
        open={isTaskModalOpen}
        setOpen={setIsTaskModalOpen}     
        taskId={taskId}
        onSuccess={handleTaskFormSuccess}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={tasks}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        tableProps={{ rowClassName }}
        columns={[{
          title: '',
          dataIndex: 'status',
          render: (status, record) => (
            <Checkbox
              checked={status === TaskStatus.COMPLETE}
              onChange={e => handleTaskStatusChange(record, e.target.checked)}
            />
          )
        }, 
        {
          title: 'Patient',
          dataIndex: 'patient',
          render: patient => patient ? <a className="patient-link" href={UrlHelper.getPatientProfile(patient._id)} target="_blank">{patient.firstName} {patient.lastName}</a> : null,
          filterDropdownType: FilterDropdownType.INPUT
        }, 

        {
          title: 'Task',
          dataIndex: 'title',
          // render: (title, { _id }) => title ? (
          //   <a onClick={() => {
          //     setTaskId(_id)
          //     setOpenTaskModal(true)
          //   }} className="task-title">
          //     {parse(title)}
          //   </a>
          // ) : null,
          filterDropdownType: FilterDropdownType.INPUT,
        }, 
        {
          title: 'Deadline',
          dataIndex: 'deadline',
          render: deadline => deadline && moment(deadline).format('MM/DD/YY')
        }, {
          title: 'Assignee',
          dataIndex: 'assignee',
          className: "photo-col",
          render: assignee => <>{assignee?.firstName}</>,
          // assignee?.photo ? (
          //   <Tooltip title={`${assignee.firstName} ${assignee.lastName}`}>
          //     <Avatar className="user-photo" src={`${assignee.photo}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} />
          //   </Tooltip>
          // ) : null,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: assignees,
        }, {
          title: 'Status',
          dataIndex: 'status',
          render: status => {
            const statusMap = {
              [TaskStatus.ACTIVE]: 'Active',
              [TaskStatus.COMPLETE]: 'Complete',
              [TaskStatus.ON_HOLD]: 'On Hold',
              [TaskStatus.CANCELLED]: 'Cancelled'
            }
            return statusMap[status] || status
          },
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(TaskStatus),
        }]}
      />
    </div>
)

}