import { Form, Button, Row, Col, Radio, Image, Spin } from "antd"
import "./packageSelect.scss"
import { WarningFilled, } from '@ant-design/icons'
import { useState, useEffect, useRef, useContext } from "react"
import FormHelper from "../../helpers/form.helper"
import RuleHelper from "../../../../helpers/rule.helper"
import { listProductTypes } from "../../../../services/productType.service"
import ProductTypeCode from "../../../../enums/productTypeCode.enum"
import "../singleSelect/singleSelect.scss" // Import the single select styles
import { UserContext } from "../../../../contexts/user.context"
import { CheckCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { addEvent } from "../../../../services/event.service"
import EventType from "../../../../enums/eventType.enum"

const { Item } = Form


const giftCategories = {
  bundles: [
    ProductTypeCode.BUNDLE_ESSENTIALS,
    ProductTypeCode.BUNDLE_ESSENTIALS_JR,
  ]
}


export const PackageSelect = ({
  step,
  onSubmit,
  skeleton,
  form,
  isSubmitting,
  hasAttempt,
  flow,
}) => {
  const [rules, setRules] = useState({})
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt
  const [productTypes, setProductTypes] = useState()
  const { currentUser } = useContext(UserContext)
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState('bundles');
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    fetchRules()
  }, [step])

  useEffect(() => {
    fetchProductTypes()

    const keyDownHandler = event => {
      if (event.target.classList.contains('__PrivateStripeElement-input')) {
        return;
      }
      
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };
    
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
      form.resetFields();
    };
  }, []);

  useEffect(() => {
    if (flow?.giftProductTypeCodes) {
      form.setFieldValue('giftProductTypeCodes', flow?.giftProductTypeCodes[0]);
    }
  }, [flow])

  useEffect(() => {
    if (!productTypes) return;
    
    setImagesLoaded(false);
    
    const imageUrls = productTypes
      .filter(pt => pt?.photos?.length > 0)
      .map(pt => pt.photos[0].webp);

    if (imageUrls.length === 0) {
      setImagesLoaded(true);
      return;
    }

    const imagePromises = imageUrls.map(url => {
      return new Promise((resolve) => {
        const img = new Image();
        
        // Set a timeout to resolve after 5 seconds if image hasn't loaded
        const timeoutId = setTimeout(() => {
          resolve();
        }, 5000);

        img.onload = () => {
          clearTimeout(timeoutId);
          resolve();
        };

        img.onerror = () => {
          clearTimeout(timeoutId);
          resolve();
        };

        img.src = url;
      });
    });

    Promise.all(imagePromises)
      .then(() => {
        setImagesLoaded(true);
      })
      .catch(() => {
        setImagesLoaded(true);
      });

    // Cleanup timeout if component unmounts
    return () => setImagesLoaded(false);
  }, [productTypes]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const selectedGift = getProductType(values.giftProductTypeCodes);
      if (selectedGift) {
        onSubmit(values);
      }
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.BUNDLE_ESSENTIALS,  
            ProductTypeCode.BUNDLE_ESSENTIALS_JR,
            ProductTypeCode.LONGEVITY_PANEL,
            ProductTypeCode.CTCALCIUM,
            ProductTypeCode.CONSULT_LONGEVITY,
            ProductTypeCode.MOBILE_BLOOD_DRAW,
            ProductTypeCode.DEXA_BODYCOMP,
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType?.code === code)
  }

  const fetchRules = () => {
    setRules({
      giftProductTypeCodes: [
        { 
          ...RuleHelper.isRequired, 
          message: <><WarningFilled />&nbsp; Please select an option</> 
        }
      ]
    });
  }

  const renderField = (field) => {
    return (
      <Radio.Group
        value={form.getFieldValue('giftProductTypeCodes')}
        onChange={(e) => {
          form.setFieldValue('giftProductTypeCodes', e.target.value);
          if (hasAttemptRef.current) {
            FormHelper.fetchHasError(form)
          }
        }}
      >
        <Row gutter={[18, 18]}>
          {field.options.map(({ value, title, tagline, price, isBundle, productTypeCodes }) => (
            <Col xs={24} sm={12} key={value} className="product-grid-item">
              <div className={`product-box ${Array.isArray(form.getFieldValue('giftProductTypeCodes')) 
                ? form.getFieldValue('giftProductTypeCodes')?.includes(value) ? 'selected' : ''
                : form.getFieldValue('giftProductTypeCodes') === value ? 'selected' : ''
              }`}>
                <div className="product-select-label">
                  <div className={`option-title-container ${activeTab}`}>
                    <div className="option-title">{title}</div>
                    {isBundle ? 
                      <div className="gift-bundle-description">
                        <div className="option-description">{getProductType(value)?.tagline}</div>
                        {productTypeCodes.map(pt => {
                          const product = getProductType(pt);
                          return (
                            <div key={pt} className="bundle-item">
                              <CheckCircleOutlined/>
                              <span 
                                className="bundle-item-title"
                                onClick={() => {
                                  addEvent({
                                    eventType: EventType.CLICK_BUNDLE_ITEM,
                                    meta: {
                                      url: product?.path || `/flow/${product?.flowType}`,
                                    },
                                  });
                                  window.open(product?.path || `/flow/${product?.flowType}`, '_blank', 'noopener,noreferrer');
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                {product?.title}
                              </span>
                            </div>
                          );
                        })}
                      </div> 
                      :
                      <div className="option-description">{tagline}</div>
                    }
                  </div>
                  <div className="price-button-container">
                    <div className="option-price">${price}</div>
                    <Button 
                      value={value}
                      type="default"
                      className="select-package-button"
                      onClick={() => {
                        form.setFieldValue('giftProductTypeCodes', value);
                        handleSubmit();
                      }}
                    >   
                      Select Package
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    )
  }

  return (
    <div className="package-select">
      {!imagesLoaded ? (
        <div className="gift-loading">
          <Spin size="large" />
          <div style={{ marginTop: 16 }}>Loading packages ...</div>
        </div>
      ) : (
        <Form
          form={form}
          className="multiple-input-form"
          layout="vertical"
        >
          <div className="field-container selector-container">
            <Item
              name="giftProductTypeCodes"
              className="single-select-item"
              rules={rules['giftProductTypeCodes']}
              validateTrigger='onSubmit'
            >
              {renderField({ options: giftCategories.bundles.map(bundle => ({
                title: getProductType(bundle)?.title,
                value: getProductType(bundle)?.code,
                price: getProductType(bundle)?.cost,
                tagline: getProductType(bundle)?.tagline,
                isBundle: getProductType(bundle)?.isBundle,
                productTypeCodes: getProductType(bundle)?.productTypeCodes,
              })) })}
            </Item>
          </div>
        </Form>
      )}
    </div>
  )
}