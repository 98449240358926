import { RiskLevel } from "../enums/index.enum.js";
const TestHelper = {
  formatRange: (range) => {
    if (!range) return null
    let {
      lessThan,
      lessThanOrEqual,
      greaterThan,
      greaterThanOrEqual,
      equal
    } = range

    const isGreaterThan = greaterThan !== undefined && greaterThan !== null
    const isLessThan = lessThan !== undefined && lessThan !== null
    const isGreaterThanOrEqual = greaterThanOrEqual !== undefined && greaterThanOrEqual !== null
    const isLessThanOrEqual = lessThanOrEqual !== undefined && lessThanOrEqual !== null

    if (equal !== undefined && equal !== null) return equal
    if (isLessThanOrEqual && isGreaterThanOrEqual) return `${greaterThanOrEqual} - ${lessThanOrEqual}`
    if (isLessThan && !isGreaterThan && !isGreaterThanOrEqual) return `<${lessThan}`
    if (isLessThanOrEqual && !isGreaterThan && !isGreaterThanOrEqual) return `≤${lessThanOrEqual}`
    if (isGreaterThan && !isLessThan && !isLessThanOrEqual) return `>${greaterThan}`
    if (isGreaterThanOrEqual && !lessThan && !lessThanOrEqual) return `≥${greaterThanOrEqual}`
    if (isLessThan && isGreaterThanOrEqual) return `≥${greaterThanOrEqual} and <${lessThan}`
    if (isLessThan && isGreaterThan) return `>${greaterThan} and <${lessThan}`
    if (isGreaterThan && isLessThanOrEqual) return `>${greaterThan} and ≤${lessThanOrEqual}`
    return null
  },
  getLongevityRisk: (healthScore) => {
    if (!healthScore || typeof healthScore !== 'number') return null
    
    if (healthScore>=90) {
      return RiskLevel.OPTIMAL;
    } else if (healthScore>=75) {
      return RiskLevel.MODERATE;
    } else {
      return RiskLevel.HIGH;
    }

  }
}

export default TestHelper
