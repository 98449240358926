import { Typography, Row, Col }  from 'antd'
import Breakpoint from "../../enums/breakpoint.enum";
import useWidth from '../../hooks/useWidth.hook';
import { Alert } from 'antd';
import { ServiceFeeNotice } from '../../components/serviceFeeNotice/serviceFeeNotice.component';
import PatientHelper from '../../helpers/patient.helper';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import { UserContext } from '../../contexts/user.context';
import { useContext } from 'react';
const { Text, Paragraph } = Typography

export const DexaIntro = ({ footer, instructions, productType, patient }) => {
  const { instalabMembership } = useContext(UserContext)
  const width = useWidth()
  return (
    <div 
      className="flow-intro"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >


      <div 
        className="flow-intro-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <Paragraph 
          className="flow-title"
          style={{
            fontSize: width > Breakpoint.SM ? 32 : 28,
          }}
        >
          DEXA Body Composition Scan
        </Paragraph>
        <Paragraph 
          className="flow-description"
          style={{
            fontSize: width > Breakpoint.SM ? 16 : 14,
          }}
        >
            A non-invasive imaging procedure that precisely measures body composition, including body fat, visceral fat and lean muscle mass.
          
        </Paragraph>

        {!instalabMembership && !PatientHelper.hasCredit(patient, ProductTypeCode.DEXA_BODYCOMP) && <Alert message={<div><ServiceFeeNotice complex={false} productType={productType}/></div>} type="info"/>}  

        <div 
          className="instruction-list"
          style={{
            marginTop: width > Breakpoint.SM ? 50 : 30,
            marginBottom: width > Breakpoint.SM ? -15 : -25,
            paddingTop: width > Breakpoint.SM ? 25 : 15,
          }}
        >
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row gutter={10}>
                <Col
                  xs={{ span: 5 }}
                  sm={{ span: 4 }}
                  md={{ span: 3 }}
                  lg={{ span: 3 }}
                  xl={{ span: 3 }}
                  xxl={{ span: 3 }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  xs={{ span: 19 }}
                  sm={{ span: 20 }}
                  md={{ span: 21 }}
                  lg={{ span: 21 }}
                  xl={{ span: 21 }}
                  xxl={{ span: 21 }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
        <div className="flow-intro-btn-container">
          <div className="flow-intro-btn">
            {footer({ 
              buttonText: "Let's go",
              buttonCheck: false,
              buttonStyle: {
                height: 50,
                fontSize: 18,
                width: '100%',
                display: 'block',
              },
              enterStyle: {
                display: 'none',
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

