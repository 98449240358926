import React, { useContext, useEffect } from 'react';
import './patientCatalog.scss';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { TestCatalog } from '../testCatalog/testCatalog.component'
import { UserContext } from '../../contexts/user.context';
import { PgFooter } from '../pgFooter/pgFooter.component';
export const PatientCatalog = () => {
  const { currentUser, token } = useContext(UserContext)

  useEffect(() => {
    document.title = `Test Catalog | Instalab`
  }, [])


  return  currentUser && <>
    <div className="patient-catalog">
        <PageHeader
          title={<>Which test would you like to take?</>}
          description={!token && <>Need help choosing the right test? <a href="/start" className="cta-link">Take our quick assessment →</a></>}
        />
        <div className="test-catalog-container">
          <TestCatalog xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}  showAll={true} />
        </div>
    </div>
    {!token && <PgFooter />}

    </>
}