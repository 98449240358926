import { Typography } from "antd";
import { StickyBlogHeader } from "../shared/stickyBlogHeader/stickyBlogHeader.component";
import { BlogData } from "../../../data/blog.data";
import { useParams } from "react-router-dom";
import classNames from "classnames";

const { Paragraph } = Typography;

export const SeedOilPost = () => {
  const { blogId } = useParams();

  return (
    <div className={classNames("blog-post", "seed-oil-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          {BlogData.find(b => b.id === blogId).title}
        </Paragraph>

        <Paragraph className="blog-subheading">
          {BlogData.find(b => b.id === blogId).description}
        </Paragraph>
      </div>

      <StickyBlogHeader 
        authorPhoto={BlogData.find(b => b.id === blogId).authorPhoto}
        authorName={BlogData.find(b => b.id === blogId).authorName}
        publishedAt={BlogData.find(b => b.id === blogId).publishedAt}
        readTime={BlogData.find(b => b.id === blogId).readTime}
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          The Rise of Seed Oils and Their Controversial Reputation
        </Paragraph>

        <Paragraph className="blog-description">
          Seed oils gained popularity in the mid-20th century due to their affordability, versatility, and long shelf life. However, their industrial origins and association with trans fats (a byproduct of early hydrogenation processes) raised early suspicions about their healthfulness.
        </Paragraph>

        <Paragraph className="blog-description">
          Today, many see seed oils as processed and "unnatural," which fuels much of the skepticism. But to make informed decisions, it's crucial to move beyond headlines and examine the evidence.
        </Paragraph>

        <Paragraph className="blog-title">
          The Omega-6 Debate: Are Seed Oils Inflammatory?
        </Paragraph>

        <Paragraph className="blog-description">
          Critics of seed oils often focus on their high content of omega-6 fatty acids, such as linoleic acid. Omega-6 fatty acids are precursors to compounds that can promote inflammation. This finding has led many to assume that consuming seed oils automatically raises inflammation levels in the body.
        </Paragraph>

        <Paragraph className="blog-description">
          However, the story isn't that simple. While omega-6 fatty acids are involved in pro-inflammatory pathways, their overall effect on inflammation depends on their balance with omega-3 fatty acids. Omega-3s, found in fatty fish like salmon, walnuts, and chia seeds, have anti-inflammatory properties that complement omega-6s.
        </Paragraph>

        <Paragraph className="blog-description">
          The problem arises when omega-6 intake far outweighs omega-3, which is common in many Western diets. So yes, consuming excessive seed oils or processed foods containing them can tip the scale toward inflammation. But moderation and balance—as always—are key.
        </Paragraph>

        <Paragraph className="blog-title">
          The Surprising Benefits of Omega-6 Fatty Acids
        </Paragraph>

        <Paragraph className="blog-description">
          Here's where things get interesting. Despite their reputation, omega-6 fatty acids have benefits when consumed in appropriate amounts. For instance, higher intake of linoleic acid has been associated with a reduced risk of coronary heart disease (CHD). Studies show that omega-6 polyunsaturated fatty acids (PUFAs) can lower LDL cholesterol and triglyceride levels, which are key factors in CHD prevention.
        </Paragraph>

        <Paragraph className="blog-description">
          It's a reminder that omega-6s aren't inherently "bad"—they're essential fatty acids that our bodies need. The problem lies in overconsumption without balancing them with omega-3s.
        </Paragraph>

        <Paragraph className="blog-title">
          The Role of Balance: Omega-6 and Omega-3
        </Paragraph>

        <Paragraph className="blog-description">
          If you enjoy using seed oils, there's no need to banish them from your kitchen. Instead, focus on maintaining a healthy omega-6 to omega-3 ratio. Ideally, this ratio should be around 4:1 or lower, but many Western diets skew closer to 10:1 or even 20:1. Here's how to achieve better balance:
        </Paragraph>

        <ul className="blog-list">
          <li>
            <strong>Limit Processed Foods:</strong> Processed snacks, fried foods, and fast foods are often high in omega-6-rich seed oils. Reducing these can significantly lower your omega-6 intake.
          </li>
          <li>
            <strong>Increase Omega-3 Intake:</strong> Add fatty fish like salmon, mackerel, or sardines to your meals 2-3 times a week. Include plant-based sources like flaxseeds, chia seeds, and walnuts in your diet.
          </li>
          <li>
            <strong>Choose Oils Wisely:</strong> If you're a fan of seed oils, opt for those with lower omega-6 content, such as canola oil. Alternatively, using olive oil or avocado oil for everyday cooking can lower omega-6 content.
          </li>
          <li>
            <strong>Consider Supplements:</strong> Fish oil or algal oil supplements can help bridge the gap if your omega-3 intake is low.
          </li>
        </ul>

        <Paragraph className="blog-title">
          The Bottom Line
        </Paragraph>

        <Paragraph className="blog-description">
          The narrative around seed oils isn't as black and white as some might suggest. While excessive omega-6 intake, especially from processed foods, can contribute to inflammation, omega-6 fatty acids also have significant health benefits, particularly for heart health. The key is maintaining a healthy balance with omega-3s. By focusing on moderation, variety, and informed choices, you can enjoy the benefits of both types of fatty acids without tipping the scales toward harm.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Harris, W., Mozaffarian, D., Rimm, E., Kris-Etherton, P., Rudel, L., Appel, L., Engler, M., Engler, M., & Sacks, F. (2009). Omega-6 Fatty Acids and Risk for Cardiovascular Disease: A Science Advisory From the American Heart Association Nutrition Subcommittee. Circulation, 119, 902-907. <a onClick={() => window.open('https://doi.org/10.1161/CIRCULATIONAHA.108.191627', '_blank')}>https://doi.org/10.1161/CIRCULATIONAHA.108.191627</a>
            </li>
            <li>
              Harris, W. (2010). Omega-6 and omega-3 fatty acids: partners in prevention. Current Opinion in Clinical Nutrition and Metabolic Care, 13, 125–129. <a onClick={() => window.open('https://doi.org/10.1097/MCO.0b013e3283357242', '_blank')}>https://doi.org/10.1097/MCO.0b013e3283357242</a>
            </li>
            <li>
              Weaver, K., Ivester, P., Seeds, M., Case, L., Arm, J., & Chilton, F. (2009). Effect of Dietary Fatty Acids on Inflammatory Gene Expression in Healthy Humans. The Journal of Biological Chemistry, 284, 15400-15407. <a onClick={() => window.open('https://doi.org/10.1074/jbc.M109.004861', '_blank')}>https://doi.org/10.1074/jbc.M109.004861</a>
            </li>
            <li>
              Al-Khudairy, L., Hartley, L., Clar, C., Flowers, N., Hooper, L., & Rees, K. (2015). Omega 6 fatty acids for the primary prevention of cardiovascular disease. The Cochrane database of systematic reviews, 11, CD011094. <a onClick={() => window.open('https://doi.org/10.1002/14651858.CD011094.pub2', '_blank')}>https://doi.org/10.1002/14651858.CD011094.pub2</a>
            </li>
            <li>
              Berquin, I., Edwards, I., & Chen, Y. (2008). Multi-targeted therapy of cancer by omega-3 fatty acids. Cancer letters, 269(2), 363-377. <a onClick={() => window.open('https://doi.org/10.1016/j.canlet.2008.03.044', '_blank')}>https://doi.org/10.1016/j.canlet.2008.03.044</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
