import { CalendarOutlined, FileSearchOutlined, DollarCircleOutlined, MedicineBoxOutlined, TeamOutlined } from '@ant-design/icons';
import { ReactComponent as TubeOutlined } from '../assets/svg/blood-vial.svg'
import { ReactComponent as DoctorOutlined } from "../assets/svg/doctor-2.svg"

const ExampleData = [
    {
        description: "Scheduled comprehensive blood work and arranged for a mobile phlebotomist to collect samples at patient's home.",
        icon: <CalendarOutlined />,
        category: "Testing & Results"
    },
    {
        description: "Connected patient with very high CAC results with a top cardiologist and scheduled requested follow-up imaging.",
        icon: <DoctorOutlined />,
        category: "Specialist Referrals"
    },
    {
        description: "Developed a cancer screening plan for a 40-year-old with a family history of cancer, integrating newest early detection tests.",
        icon: <FileSearchOutlined />,
        category: "Health Planning"
    },
    {
        description: "Worked with patient’s insurance to secure coverage for a PCSK9 inhibitor, saving him over $5,000 annually.",
        icon: <DollarCircleOutlined />,
        category: "Patient Advocacy"
    },
    {
        description: "Got a low-dose statin prescription for a 35-year-old with elevated LDL levels and a family history of heart disease.",
        icon: <MedicineBoxOutlined />,
        category: "Medication Support"
    },

    {
        description: "Reviewed a patient’s supplement regimen for effectiveness, and recommended changes based on latest test results.",
        icon: <TubeOutlined />,
        category: "Testing & Results"
    },
    {
        description: "Scheduled regular blood testing for a 31-year-old MLB pitcher to ensure optimal recovery and peak performance.",
        icon: <CalendarOutlined />,
        category: "Testing & Results"
    },
    {
        description: "Connected patient with clinical lipidologist to discuss new drug that may help with his cholesterol levels.",
        icon: <DoctorOutlined />,
        category: "Specialist Referrals"
    },
    {
        description: "Navigated months of back and forth to get insurance approval for a new biologic drug, saving the patient over $10,000 annually.",
        icon: <DollarCircleOutlined />,
        category: "Patient Advocacy"
    },
    {
        description: "Coordinated care with reproductive endocrinologist and a genetic counselor for a couple seeking fertility support.",
        icon: <DoctorOutlined />,
        category: "Specialist Referrals"
    },
    {
        description: "Booked dermatologist for patient with recurring rashes and wanting to avoid any that only prescribed cortisone for treatment.",
        icon: <DoctorOutlined />,
        category: "Specialist Referrals"
    },
    {
        description: "Arranged a consult with a sleep specialist for a 27-year-old NBA player to address jet lag and optimize recovery during travel weeks.",
        icon: <DoctorOutlined />,
        category: "Specialist Referrals"
    },
 
]

export default ExampleData;