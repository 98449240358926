import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Typography } from "antd";
import "./omega3Advice.scss";
import ReportHelper from "../../../helpers/report.helper";
import { TestView } from "../testView/testView.component"
import { LowercaseTest, RiskLevel, ReportSection, TestCode } from "../../../enums/index.enum"
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import { TestLink } from "../testLink/testLink.component";
import ResultHelper from "../../../helpers/result.helper";
import parse from 'html-react-parser';

const { Paragraph } = Typography;

const primaryCodes = [TestCode.OMEGA_3_INDEX, TestCode.OMEGA_6_3, TestCode.ARACHIDONIC_EPA]

export const Omega3Advice = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [riskLevel, setRiskLevel] = useState();
  const [title, setTitle] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();
  const [omega3Tests, setOmega3Tests] = useState();

  // progress
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();
  const [isStatic, setIsStatic] = useState();

  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.OMEGA_3_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.OMEGA_3_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.OMEGA_3_ADVICE));
    setIsStatic(ReportHelper.isStatic(report, ReportSection.OMEGA_3_ADVICE));
  }, [report]);

  useEffect(() => {
    setOmega3Tests(getOmega3Tests())
  }, [report, tests])

  useEffect(() => {
    setTitle(getTitle());
    setPrimaryDescription(getPrimaryDescription())
  }, [riskLevel, report, omega3Tests])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
          recs
        }
      }
    })
  }, [title, primaryDescription, recs])

  useEffect(() => {
    populateTestLinks()
  }, [primaryDescription])

  useEffect(() => {
    setRiskLevel(getRiskLevel())
  }, [tests, results, report])


  const getOmega3Tests = () => {
    if (!report || !tests) return null

    // if (isBetter || isFixed) {
    //   return ReportHelper.getSectionImprovedTests(report, ReportSection.OMEGA_3_ADVICE, tests)
    // }

    const t = ReportHelper.getSectionTests(report, ReportSection.OMEGA_3_ADVICE, tests)

    return t
  }

  const getRiskLevel = () => {
    if (!tests || !results || !report) return null;
    return ReportHelper.getTestRisk(TestCode.TSH, report, results, tests);
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
      
    if (!report || !riskLevel || !tests || !results) return null;

    

    if (isBetter || isFixed) {
      return <>Your <span>omega-3 profile</span> is <span>improving</span>, which decreases your risk for cardiovascular disease</>
    }

    if (isWorse) {
      return <>Your <span>omega-3 index</span> is <span>{ResultHelper.getDirection(TestCode.OMEGA_3_INDEX, results, tests)}</span>, which increases your risk for heart disease.</>

    }

    if (isStatic) {
      return <>Your <span>omega-3 index</span> remains <span>unchanged</span>, indicating continued elevated risk for cardiovascular disease.</>
    }

    return <>
        Your <span>{LowercaseTest[omega3Tests[0].code]}</span> is <span>low</span>, which increases your risk for cardiovascular disease.
    </>
  };

  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription)

    if (!report || !riskLevel || !tests || !results || !omega3Tests) return null;

    return (
      <>
        Omega-3 index measures the levels of EPA and DHA, which are two critical omega-3 fatty acids that are essential for maintaining heart health, reducing inflammation, and supporting overall cellular function. Low levels have been linked to a higher likelihood of heart disease, cognitive decline, and other chronic conditions.
        <br/><br/>
        While symptoms like fatigue, joint pain, or cognitive changes may not be immediately noticeable, a low omega-3 index remains a silent risk factor. Increasing omega-3 intake through diet or supplements has been shown to improve cardiovascular health and lower long-term risk.

        <TestView  
        report={report} 
        tests={omega3Tests.filter(({code})=> primaryCodes.includes(code))} 
        results={results}
        reports={reports}
        user={user}
      />
      </>
    )
  }


  const populateTestLinks = () => {
    const testLinks = document.getElementsByClassName('view-test')
    for (const testLink of testLinks) {
      const testCode = testLink.id.split('-')[2]
      const test = tests.find(({ code }) => code === Number(testCode))
      ReactDOM.render(
        <TestLink
          test={test}
          content={testLink.getAttribute('text')}
          report={report}
          tests={tests}
          results={results}
          reports={reports} 
        />,
        testLink
      )
    }
  }

  return (
    omega3Tests && <div className="omega3-advice">
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">{primaryDescription}</Paragraph>

      <AdviceSection 
        report={report} 
        results={results}
        tests={tests}
        section={section}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />
    </div>
  );
};
