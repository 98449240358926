import './confirmAppointment.scss'
import AppointmentHelper from '../../helpers/appointment.helper'
import InviteStatus from '../../enums/inviteStatus.enum'
import { useEffect, useState } from 'react'
import LatestPosts from '../latestPosts/latestPosts.component.js'
import Panels from "../panels/panels.component.js"
import ProductTypeCode from "../../enums/productTypeCode.enum.js"
const ConfirmAppointment = ({ flow }) => {
  const [inviteCount, setInviteCount] = useState()

  useEffect(() => {
    fetchInviteCount()
  }, [flow])


  const productTypeCodes = [
    ProductTypeCode.CTCALCIUM,
    ProductTypeCode.LONGEVITY_PANEL,
    ProductTypeCode.GALLERI,
    ProductTypeCode.WATCHPAT_ONE
  ]

  const fetchInviteCount = () => {
    if (!flow?.appointment) return
    setInviteCount(flow.appointment.patients.length - 1 + flow.appointment.invites.filter(({ status }) => status === InviteStatus.PENDING).length)
  }

  return flow?.appointment && (
    <div className="confirm-appointment">
      <div className="detail-list">
        <div className="detail-row">
          <div className="detail-label">
            Location
          </div>
          <div className="detail-value">
            {AppointmentHelper.getLocation(flow.appointment)}
          </div>
        </div>
        
        <div className="detail-row">
          <div className="detail-label">
            Time
          </div>
          <div className="detail-value">
            {AppointmentHelper.getStartTime(flow.appointment).format("MMMM D")} at {
              AppointmentHelper.isGetLabs(flow.appointment)
                ? `${AppointmentHelper.getStartTime(flow.appointment).format("h:mm a")} - ${AppointmentHelper.getStartTime(flow.appointment).add(2, 'hours').format("h:mm a")}`
                : AppointmentHelper.getStartTime(flow.appointment).format("h:mm a")
            }
          </div>
        </div>

        {flow?.appointment?.phlebotomist && (
          <div className="detail-row">
            <div className="detail-label">
              Phlebotomist
            </div>
            <div className="detail-value">
              {AppointmentHelper.getPhlebotomistName(flow.appointment)}
            </div>
          </div>
        )}

        {inviteCount > 0 &&
        <div className="detail-row">
          <div className="detail-label">
            Invites
          </div>
          <div className="detail-value">
            {inviteCount} {inviteCount === 1 ? 'invite' : 'invites'}
          </div>
        </div>}

      </div>

      <Panels productTypeCodes={productTypeCodes} />
      <LatestPosts />
    </div>
  )
}

export default ConfirmAppointment