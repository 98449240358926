import { Card, Row, Col, Button, Dropdown } from 'antd';
import { CalendarOutlined, EnvironmentOutlined, VideoCameraOutlined, MoreOutlined } from '@ant-design/icons';
import { ReactComponent as DoctorIcon } from '../../assets/svg/doctor-2.svg';
import { Tag } from 'antd';
import UserHelper from '../../helpers/user.helper.js';
import dayjs from 'dayjs';
import { ProductTypeCode, AppointmentStatus } from '../../enums/index.enum.js';
import { useNavigate } from 'react-router-dom';
import AppointmentHelper from '../../helpers/appointment.helper.js';
import "./appointmentCard.scss";
import { EyeOutlined } from '@ant-design/icons';
const collectionMethods = [
  ProductTypeCode.MOBILE_BLOOD_DRAW,
  ProductTypeCode.PRO_MOBILE_BLOOD_DRAW,
  ProductTypeCode.LAB_VISIT,
]

const AppointmentCard = ({ 
    appointment, 
    defaultView = true,
    actionItems = []
  }) => {
  const navigate = useNavigate();

  const { start, location, facility, status, products } = appointment;
  const address = facility?.location || location;

  const { streetAddress, streetAddress2, city, state, postalCode } = address || {};
  const fullAddress = address ? `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}` : 'TBD';
  const locationDisplay = facility ? `${facility.name} - ${fullAddress}` : fullAddress;

  
  return (
    <Card 
      className={`appointment-card flat ${appointment.status}`}
      key={appointment._id}
    >
      <div className="appointment-card-content">

        {actionItems.length > 0 && <Dropdown
            menu={{ items: actionItems }}
            placement="bottomRight"
            trigger={['click']}
        >
            <Button
                type="text"
                icon={<MoreOutlined />}
                className="more-actions-button"
            />
        </Dropdown>}

        <Row justify="space-between" align="top">
          <Col flex="1">

            {defaultView && <div className="appointment-status">
              <Tag color={AppointmentHelper.getTagColor(appointment.status)}>{appointment.status}</Tag>
            </div>}

            {/* Appointment Type */}
            <div className="tests">
              {appointment.products?.filter(product => !collectionMethods.includes(product.productType?.code)).map(product => product.productType?.title).join(', ')}
            </div>

            <div className="time">
              <CalendarOutlined /> 
              {appointment.start ? 
                dayjs(appointment.start)
                  .tz(appointment.facility?.location?.timeZoneId || appointment.location?.timeZoneId)
                  .format('MMM D, YYYY @ h:mm a z') 
                : 'TBD'
              }
            </div>


            {appointment.provider && 
              <div className="provider">
                <DoctorIcon className="anticon" />
                <span>
                  {UserHelper.getName(appointment.provider)}
                </span>
              </div>
            }

            {appointment.remoteLocation ? (
              <div className="remote-location">
                <VideoCameraOutlined className="anticon" />
                <span>
                  {(() => {
                    try {
                      new URL(appointment.remoteLocation);
                      return <a href={appointment.remoteLocation} className="remote-location-link" target="_blank" rel="noopener noreferrer">{appointment.remoteLocation}</a>;
                    } catch {
                      return appointment.remoteLocation;
                    }
                  })()}
                </span>
              </div>
            ) : (
              <div className="location">
                <EnvironmentOutlined className="anticon" />
                <span>{locationDisplay}</span>
              </div>
            )}

            {appointment.status === AppointmentStatus.PENDING && <div className="pending-message">
              <span>Our concierge team is working on your appointment and will be in touch soon.{' '}
              Please email concierge@instalab.com with any questions.</span>
            </div>}


          </Col>

          <Col>
            {!defaultView &&
                  <Button 
                type="default"
                icon={<EyeOutlined />}
                className="view-details-button"
                onClick={() => navigate('/patient/appointments')}
              >
                {/* View Details */}
              </Button>
            }
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default AppointmentCard;