import { useRef, useState, useEffect } from "react"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { WaitlistConfirm } from "./waitlistConfirm.component"
import { completeFlow } from "../../services/flow.service"

export const Step = {
  EMAIL: 'email',
  PASSWORD: 'password',
  NAME: 'name',
  DOB: 'dob',
  LOCATION: 'location',
  REASON: 'reason',
  CONFIRM: 'confirm',
}

export const WaitlistFlow = () => {
  const [flow, setFlow] = useState()
  const [initialUser, setInitialUser] = useState()
  const hasCompletedFlowRef = useRef(false)

  const [hasPass, setHasPass] = useState()
  const [skeleton, setSkeleton] = useState()
  
  useEffect(() => {
    fetchSkeleton()
  }, [hasPass, initialUser, flow])

  const fetchSkeleton = () => {
    if (!initialUser || hasPass===undefined) return
    setSkeleton({
      [Step.EMAIL]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `What's your email address?`,
        description: `We'll email you with your waitlist status and when you can join.`,
        field: {
          name: 'email',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        },
        hiddenFields: [{
          name: 'waitlist',
          value: ['concierge'],
        }],
        skipIf: () => {
          return initialUser?.email
        }
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.LOCATION,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        buildFlow: true,
        nextStep: Step.PASSWORD,
        title: `What's your current address?`,
        description: `We are available anywhere in the United States.`,
        field: {
          name: 'location'
        },
      },
      // [Step.REASON]: {
      //   type: StepType.SINGLE_INPUT,
      //   nextStep: Step.PASSWORD,
      //   buildUser: true,
      //   title: `Why are you interested in Instalab Concierge?`,
      //   description: 'This will help us prioritize you on our waitlist.',
      //   field: {
      //     name: 'waitlistReason',
      //     placeholder: 'Type your reason here...',
      //   },
      // },
      [Step.PASSWORD]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: `One last thing: set account password`,
        description: `With an Instalab acount, once you're off the waitlist, you can get going right away.`,
        field: {
          name: 'password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        },
        skipIf: () => {
          return hasPass
        }
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `You're on the waitlist!`,
        description: `Thank you letting you know about your interest in Instalab Concierge. We'll email you when we're ready for you. In the meantime, please reach out if you have any questions.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <WaitlistConfirm />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }


  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow}
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />
  )
}