import { Typography, Row, Col }  from 'antd'
import { CloseOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react";
import Breakpoint from "../../enums/breakpoint.enum";
import { useNavigate } from 'react-router-dom';
import useWidth from '../../hooks/useWidth.hook';

const { Paragraph } = Typography


const UltimateMemberConfirmed = ({ footer }) => {
  const width = useWidth()
  const navigate = useNavigate()
 
  return (
    <div 
      className="member-confirmed"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >
      <div 
        className="member-confirmed-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <Paragraph className="member-title">
          Congrats, you're in! 
        </Paragraph>
        <Paragraph className="member-description">
          We're thrilled to have you as an Ultimate Member. Your dedicated health concierge will reach out shortly via email. In the meantime, you can email <a href="mailto:concierge@instalab.com">concierge@instalab.com</a> with any immediate questions.
          <br/>
          <CloseOutlined className="close-icon" />
          <br/>
          {/* If you're new here, please fill out this quick 2-minute medical questionnaire so we can recommend what you should do first. */}
          If you're new here, take a quick quiz to find out all the preventive tests you should do this year to stay on top of your health:
        </Paragraph>


        {footer({ 
          buttonText: "Take Quiz",
          buttonCheck: false,
          buttonStyle: {
            height: 50,
            fontSize: 18,
            width: 300,
            margin: '40px auto 0 auto',
            display: 'block',
          },
          enterStyle: {
            display: 'none',
          },
          onCustomSubmit: () => navigate('/flow/quiz')
        })}

      </div>
    </div>
  )
}

export default UltimateMemberConfirmed 