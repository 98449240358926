import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { TwitterOutlined, LinkedinOutlined, InstagramOutlined } from '@ant-design/icons';
import logo from '../../assets/svg/logo.svg';
import './footerv2.scss';

export const FooterV2 = () => {

    const onChat = () => {
        window.FrontChat("show");
      };
    
    return (

        <footer className="footer-section">
          <div className="max-width">
            <div className="footer-content">
              <div className="footer-column company-info">

              <div className="logo-container">
                <img src={logo} alt="logo" className="logo" /> 
                <Typography.Text className="logo-name">Instalab</Typography.Text>
              </div> 
                
                <p>Making proactive health easy and accessible.</p>
                <div className="social-links">
                  <a href="https://twitter.com/getinstalab" target="_blank" rel="noopener noreferrer">
                    <TwitterOutlined />
                  </a>
                  <a href="https://linkedin.com/company/instalabinc" target="_blank" rel="noopener noreferrer">
                    <LinkedinOutlined />
                  </a>
                  <a href="https://instagram.com/getinstalab" target="_blank" rel="noopener noreferrer">
                    <InstagramOutlined />
                  </a>
                </div>
          
              </div>


              <div className="footer-column">
                <h4>Products</h4>
                <ul>
                  <li><Link to="/concierge">Concierge</Link></li>
                  <li><Link to="/catalog">Diagnostic Tests</Link></li>
                  <li><Link to="/heart">Heart Health</Link></li>
                </ul>
              </div>

              <div className="footer-column">
                <h4>Company</h4>
                <ul>
                  <li><Link to="/about">About Us</Link></li>                 
                  <li><Link to="/research">Research</Link></li>
                  <li><Link to="/terms">Terms</Link> & <Link to="/privacy">Privacy</Link></li>
                </ul>
              </div>


              <div className="footer-column">
                <h4>Contact</h4>
                <ul>
                  <li>Email: <a href="mailto:hello@instalab.com">hello@instalab.com</a></li>
                  <li>Web Chat: 
                    <Button 
                      type="link" 
                      onClick={onChat} 
                      className="web-chat-link"
                    >
                      Start Chat
                    </Button>
                  </li>
                  <li>Text/SMS: <a href="sms:+19175409829">(917) 540-9829</a></li>
                </ul>
              </div>
            </div>

          </div>
        </footer>

    );
};

