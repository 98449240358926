import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { getMembershipTypeByCode } from '../../services/membershipType.service'
import Color from '../../colors.scss'
import FlowType from "../../enums/flowType.enum"
import ConfirmInterest from "./static/confirmInterest/confirmInterest.component"
import { Step as IntakeStep } from '../intakeFlow/intakeFlow.component'
import { addSlack } from "../../services/slack.service"
import ConfirmAppointment from "./static/confirmAppointment/confirmAppointment.component"
import { getAvailability } from "../../services/schedule.service"
import PhlebType from "../../enums/phlebType.enum"
import { addProduct } from "../../services/product.service"
import { listProductTypes } from '../../services/productType.service'
import { completeFlow } from "../../services/flow.service"
import ProductHelper from "../../helpers/product.helper"
import { useNavigate } from "react-router-dom"
import { updateProduct } from "../../services/product.service"
import { listResults } from "../../services/result.service"
import { listTests } from "../../services/test.service"
import TestCode from "../../enums/testCode.enum"
import { confirmAppointment, setShippingLocation, updateAppointmentAddons, releasePendingAppointment } from "../../services/appointment.service"
import { updateFlow } from "../../services/flow.service"
import TimerDisplay from "../timerDisplay/timerDisplay.component"
import PatientHelper from "../../helpers/patient.helper"
export const Step = {
  ACCOUNT: 'account',
  NAME: 'name',
  GENDER: 'gender',
  DOB: 'dob',
  PHONE: 'phone',
  LOCATION: 'location',
  INTEREST: 'interest',
  INTEREST_CONFIRMATION: 'interest-confirmation',
  DRAW: 'draw',
  PSC: 'psc',
  PAY: 'pay',
  ADDON: 'add-ons',
  SHIPPING: 'shipping',
  SCHEDULE: 'schedule',
  RESCHEDULE: 'reschedule',
  INVITE: 'invite',
  APPOINTMENT_CONFIRMATION: 'appointment-confirmation'
}

export const LongevityTestFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership, currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const [membershipType, setMembershipType] = useState()
  const [phlebType, setPhlebType] = useState()
  const [hasPass, setHasPass] = useState()
  const [hasApoe, setHasApoe] = useState()
  const hasCompletedFlowRef = useRef(false)
  const [initialUser, setInitialUser] = useState()
  const lockedCostRef = useRef()

  useEffect(() => {
    // don't show add on if has already run apoe
    const fetchHasApoe = async () => {
      const [test] = await listTests({
        select: '_id',
        filter: { code: TestCode.APO_E }
      });
      const results = await listResults({
        select: '_id values',
        filter: { patient: currentUser._id }
      });
  
      const hasApoE = results.some(({ values }) =>
        values.some(value => value.value && value.test.toString() === test._id.toString())
      );
  
      setHasApoe(hasApoE);
    };
  
    fetchHasApoe();
  }, [currentUser]);

  useEffect(() => {
    fetchSkeleton()
  }, [productTypes, instalabMembership, flow, currentUser, phlebType, hasPass, initialUser, hasApoe])

  useEffect(() => {
    fetchPhlebType()
  }, [currentUser, flow])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipType()
  }, [])

  const fetchPhlebType = async () => {
    if (!currentUser?.location || !flow) return
    const response = await getAvailability({
      startDate: new Date(),
      location: currentUser.location,
      flowId: flow._id
    })
    setPhlebType(response.phlebType)
  }

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.LONGEVITY_PANEL,
            ProductTypeCode.OMEGA_3_ADDON,
            ProductTypeCode.APOE_ADDON,
            ProductTypeCode.CORTISOL,
            ProductTypeCode.BIOMETRICS,
            ProductTypeCode.MOBILE_BLOOD_DRAW,
            ProductTypeCode.ATHLETE_ADDON
          ]
        }
      }
    }))
  }

  const fetchMembershipType = async () => {
    setMembershipType(await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }


  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser || !productTypes) return

    const selectedProductTypes = flow?.prefersMobileDraw ? [getProductType(ProductTypeCode.LONGEVITY_PANEL), getProductType(ProductTypeCode.MOBILE_BLOOD_DRAW)] : [getProductType(ProductTypeCode.LONGEVITY_PANEL)]  
    const totalCost = ProductHelper.getTotalCost(selectedProductTypes, instalabMembership, currentUser)

    // Update lockedCostRef when selectedProductTypes changes
    const selectedProductTypesCodes = selectedProductTypes.map(pt => pt.code).sort().join(',')
    if (lockedCostRef.current === undefined || lockedCostRef.selectedTypes !== selectedProductTypesCodes) {
      lockedCostRef.current = totalCost
      lockedCostRef.selectedTypes = selectedProductTypesCodes
    }

    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.LONGEVITY_TEST}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        },
        buttonText: 'Create Account'
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this for your lab order for the test.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your sex assigned at birth?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.LOCATION,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        buildFlow: true,
        onNextStep: async (patient) => {
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType;
          return phlebType ? Step.DRAW : Step.INTEREST
        },
        title: `Where are you located?`,
        description: `You'll either visit a nearby patient service center, or your phlebotomist will come to this location your blood draw.`,
        field: {
          name: 'location'
        },
      },
      [Step.INTEREST]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.INTEREST_CONFIRMATION,
        buildUser: true,
        title: `Apologies, we're not in your area yet...`,
        description: <>But if you leave your email, we'll notify you as soon as we are. You can also reach out to us at <a href="mailto:concierge@instalab.com">concierge@instalab.com</a>.</>,
        field: {
          name: 'email',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        },
        onSuccess: async (patient) => {
          await addSlack({
            message: `${patient.email} interested: longevity test`,
            channel: process.env.REACT_APP_PATIENT_SLACK
          })
        },
      },
      [Step.INTEREST_CONFIRMATION]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `You joined the waitlist.`,
        description: `When we launch in your area, we'll send you an email.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmInterest />
      },
      [Step.DRAW]: {
        type: StepType.PRODUCT_SELECT,
        title: `Select your blood draw option.`,
        description: `Choose how you'd like to complete your Comprehensive Blood Panel.`,
        buildFlow: true,
        multiple: false,
        productType: [ProductTypeCode.LONGEVITY_PANEL, ProductTypeCode.MOBILE_BLOOD_DRAW],
        onNextStep: (patient, flow) => {
          if (flow?.prefersMobileDraw) {
            return Step.SCHEDULE;
          }
          else return Step.PSC;
        },
        field: (() => {

          const patient = flow?.user
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType;
          const noLabVisit = ['AZ','HI','NJ','RI'].includes(patient?.location?.state)

          const mobileCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.LONGEVITY_PANEL), getProductType(ProductTypeCode.MOBILE_BLOOD_DRAW)], instalabMembership, currentUser)

          const labCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.LONGEVITY_PANEL)], instalabMembership, currentUser)

          return {
            name: 'prefersMobileDraw',
            required: true,
            options: [
              {
                title: "Lab Visit",
                value: false,
                description: "Visit a nearby patient service center (select location next).",
                price: labCost,
                disabled: noLabVisit,
                tag: noLabVisit && "Unavailable in your state"

              },             
              {
                title: "At-Home Draw",
                value: true,
                description: "A licensed phlebotomist will come to your home or office.",
                price: mobileCost,
                disabled: phlebType === PhlebType.FACILITY ,
                tag: phlebType === PhlebType.FACILITY && "Unavailable in your area"
              },             
          ] 
        }})(),
        isLocked: () => {
          return flow?.products.filter(product => [ProductTypeCode.MOBILE_BLOOD_DRAW].includes(product.productType.code)).length > 0
        }

      },
      [Step.PSC]: {
        type: StepType.FACILITY,
        nextStep: Step.SCHEDULE,
        buildFlow: true,
        multiple: false,
        productTypeCode: ProductTypeCode.LONGEVITY_PANEL,
        title: `Select your preferred patient service center.`,
        description: `This is where you'll get your blood drawn.`,
        field: {
          name: 'facility',
          required: true,
        },
      },
      [Step.SCHEDULE]: {
        type: StepType.SCHEDULE,
        createPending: true,
        addPendingAppointment: true,
        nextStep: Step.PAY,
        title: `Schedule your blood draw appointment.`,
        description: (() => { 
          const isMobile = flow?.prefersMobileDraw 
          return isMobile && (flow?.appointment?.phlebType  || flow?.user?.phlebType) === PhlebType.GETLABS ? 
              `A phlebotomist from our partner (Getlabs) will arrive during the 2-hour time window you select. The appointment takes about 10 minutes. You must fast 8 hours before your appointment. Water and black coffee are fine. ` :
              `The appointment takes about 10 minutes. You must fast 8 hours beforehand (water and black coffee are fine).`
            })(),
      },   
      [Step.RESCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        addAppointment: true,
        title: () =>  `Reschedule your blood draw appointment.`,
        description: `The appointment takes about 10 minutes. You must fast 8 hours beforehand (water and black coffee are fine).` , 
      },
      
      [Step.PAY]: {
        type: StepType.PAY,
        onNextStep: async (patient) => {

          const hasActiveCard = await PatientHelper.hasActiveCard(patient)
          if (hasActiveCard || lockedCostRef.current > 0) {
            return Step.ADDON
          }
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType
          if (flow?.prefersMobileDraw && phlebType === PhlebType.GETLABS) {
            return Step.SHIPPING
          }
          else if (flow?.prefersMobileDraw && phlebType === PhlebType.INSTALAB) {
            return Step.INVITE
          }
          else {
            return Step.APPOINTMENT_CONFIRMATION
          }       
        },
        addProduct: true,
        productType: flow?.prefersMobileDraw ? [ProductTypeCode.LONGEVITY_PANEL, ProductTypeCode.MOBILE_BLOOD_DRAW] : [ProductTypeCode.LONGEVITY_PANEL],  
      
        title: (() => {
          const panelTitle = getProductType(ProductTypeCode.LONGEVITY_PANEL)?.title;
          const productTypeCodes = flow?.prefersMobileDraw ? [ProductTypeCode.LONGEVITY_PANEL, ProductTypeCode.MOBILE_BLOOD_DRAW] : [ProductTypeCode.LONGEVITY_PANEL]
          const hasPaid = flow?.products.length > 0 && productTypeCodes.every(productTypeCode => flow?.products.some(p => p.productType.code === productTypeCode))
          return hasPaid ? (
            <>Payment complete for <span className="product-name">{panelTitle}</span></>
          ) : (
            <>Pay ${lockedCostRef.current} for the {panelTitle}.</>
          );
        })(),
        header: () => {
          const productTypeCodes = flow?.prefersMobileDraw ? [ProductTypeCode.LONGEVITY_PANEL, ProductTypeCode.MOBILE_BLOOD_DRAW] : [ProductTypeCode.LONGEVITY_PANEL]
          const hasPaid = flow?.products.length > 0 && productTypeCodes.every(productTypeCode => flow?.products.some(p => p.productType.code === productTypeCode))
    
          return !hasPaid && (
            <div>
              <div className="timer-warning">
                <div className="timer-warning-content">
                Complete payment within{" "} 
                  <TimerDisplay 
                    start={flow?.appointment?.updatedAt} 
                    timeAllowed={8*60} 
                    onExpire={async () => {
                      await releasePendingAppointment(flow?.appointment?._id)
                      await updateFlow(flow?._id, {appointment: null})
                      setFlow(prevFlow => ({...prevFlow, appointment: null}))
                      navigate(`/flow/${FlowType.LONGEVITY_TEST}/${Step.SCHEDULE}/${flow?._id}`)
                    }} 
                  />{" "}
                
                  to secure your appointment. 
              </div>  
              </div>
            </div>
          )
        },
        // description: () => {
        //   const productTypeCodes = flow?.prefersMobileDraw ? [ProductTypeCode.LONGEVITY_PANEL, ProductTypeCode.MOBILE_BLOOD_DRAW] : [ProductTypeCode.LONGEVITY_PANEL]
        //   const hasPaid = flow?.products.length > 0 && productTypeCodes.every(productTypeCode => flow?.products.some(p => p.productType.code === productTypeCode))
    
        //   return !hasPaid && (
        //     <div>
        //       <div className="timer-warning">
        //         <strong>Time Slot Reserved:</strong> Please complete payment within{" "} 
        //       <TimerDisplay 
        //         start={flow?.appointment?.updatedAt} 
        //         timeAllowed={5*60} 
        //         onExpire={async () => {
        //           await releasePendingAppointment(flow?.appointment?._id)
        //           await updateFlow(flow?._id, {appointment: null})
        //           setFlow(prevFlow => ({...prevFlow, appointment: null}))
        //           navigate(`/flow/${FlowType.LONGEVITY_TEST}/${Step.SCHEDULE}/${flow?._id}`)
        //         }} 
        //       />{" "}
            
        //       to secure your appointment. 
        //       </div>
        //     </div>
        //   )
        // },
        onSuccess: async () => {
          await confirmAppointment(flow?.appointment?._id, {flowId: flow?._id})
        },

      },
      [Step.ADDON]: {
        type: StepType.PRODUCT_SELECT,
        title: `Would you like add on any of these tests?`,
        description: `If you'd like add a test not listed here, email concierge@instalab.com after you finish booking.`,
        buildFlow: true,
        multiple: true,
        hasSkip: true,
        buttonText: 'Add Tests',
        onNextStep: (patient) => {
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType
          if (flow?.prefersMobileDraw && phlebType === PhlebType.GETLABS) {
            return Step.SHIPPING
          }
          else if (flow?.prefersMobileDraw && phlebType === PhlebType.INSTALAB) {
            return Step.INVITE
          }
          else {
            return Step.APPOINTMENT_CONFIRMATION
          }
        },
        field: {
          name: 'addOns',
          required: true,
          options: [
            {
              title: `${getProductType(ProductTypeCode.CORTISOL)?.title}`,
              value: ProductTypeCode.CORTISOL,
              description: getProductType(ProductTypeCode.CORTISOL)?.tagline,
              tag: null,
              price: ProductHelper.getTotalCost([getProductType(ProductTypeCode.CORTISOL)], instalabMembership, currentUser)
            },             
            {
              title: `${getProductType(ProductTypeCode.OMEGA_3_ADDON)?.title}`,
              value: ProductTypeCode.OMEGA_3_ADDON,
              description: getProductType(ProductTypeCode.OMEGA_3_ADDON)?.tagline,
              tag: null,
              price: ProductHelper.getTotalCost([getProductType(ProductTypeCode.OMEGA_3_ADDON)], instalabMembership, currentUser)
            }, 
            hasApoe ? null : {
              title: `${getProductType(ProductTypeCode.APOE_ADDON)?.title}`,
              value: ProductTypeCode.APOE_ADDON,
              description: getProductType(ProductTypeCode.APOE_ADDON)?.tagline,
              tag: null,
              price: ProductHelper.getTotalCost([getProductType(ProductTypeCode.APOE_ADDON)], instalabMembership, currentUser)
            },

          ].filter(Boolean) 
        },
        onSuccess: async (_, flow) => {
          try {

            // add products + update flow 
            const newProducts = []
            for (const addOn of flow?.addOns) {
              const response = await addProduct({
                  fields: {
                    type: addOn,
                    flowId: flow?._id
                  }
              })
              newProducts.push(response.product._id)
            }

            // update appointment
            await updateAppointmentAddons(flow?.appointment?._id, {  addOns: flow?.addOns, products: newProducts })

          } 
          catch (error) {
            console.log(error)
          }

          return true
        },
        isLocked: () => {
          return flow?.products.filter(product => [ProductTypeCode.OMEGA_3_ADDON, ProductTypeCode.APOE_ADD_ON, ProductTypeCode.CORTISOL].includes(product.productType.code)).length > 0
        },

      },
      [Step.SHIPPING]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        title: `Where should we ship your test supplies kit?`,
        description: `You will receive a kit in the mail containing all the supplies needed for your phlebotomist to complete your blood draw.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient, flow) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }

            await setShippingLocation(flow?.appointment?._id, {shippingLocation: patient.shippingLocation})
            
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },
      [Step.INVITE]: {
        type: StepType.INVITE,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        addInvite: true,
        title: `Add friends and family to your appointment.`,
        description: <>
          Invite others to join your appointment and get the same tests at{" "} 
          <span style={{ color: Color.success }}>{ProductHelper.formatPrice(flow?.appointment?.invitePrice)} per person</span>{flow?.appointment?.invitePrice < getProductType(ProductTypeCode.LONGEVITY_PANEL)?.cost && <span> (normally ${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.cost})</span>}.&nbsp;
          No additional charges will be made until after the appointment.
        </>,
      },
      [Step.APPOINTMENT_CONFIRMATION]: {
        type: StepType.STATIC,
        path: `/flow/${FlowType.INTAKE}/${IntakeStep.MEDICAL_CONDITIONS_CATEGORIES}`,
        title: `Your blood draw appointment is confirmed!`,
        description: (() => {
          const isMobile = flow?.prefersMobileDraw;
          return isMobile && flow?.appointment?.phlebType === PhlebType.GETLABS ? 
            <><u>You will receive the test supplies kit in the mail</u> containing all the supplies needed to complete your blood draw. A phlebotomist from our partner (Getlabs) will call or text you upon arrival on the day of your appointment.<br/><br/>Next, we need a litle bit more information to provide the most accurate analysis and personalized recommendations.</> :
            <>Next, we need a litle bit more information to provide the most accurate analysis and personalized recommendations.</>;
        })(),
        buttonText: 'Continue',
        content: <ConfirmAppointment flow={flow} />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }

  return <>

    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
      productTypeCode={flow?.prefersMobileDraw || flow?.prefersMobileDraw === undefined ? [ProductTypeCode.MOBILE_BLOOD_DRAW, ProductTypeCode.LONGEVITY_PANEL] : ProductTypeCode.LONGEVITY_PANEL}
      startIndex={1}
    />
  </>
}