const AbsoluteCategoryCode = Object.freeze({
  BODY_COMPOSITION: 0,
  LIPIDS: 1,
  INFLAMMATION: 2,
  METABOLISM: 3,
  LIVER: 5,
  KIDNEY: 6,
  THYROID: 7,
  ELECTROLYTES: 8,
  GENETICS: 9,
  HORMONES: 11,
  NUTRIENTS: 12,
  BLOOD_CELLS: 13,
  LIPOPROTEINS: 14,
  // URINALYSIS: 15,
  IMMUNOLOGIC: 16,
  INFECTIOUS_DISEASE: 17,
  SKIN_BIOPSY: 18,
  // PROSTATE: 19,
  // PITUITARY: 20,
  // ADRENAL: 21,
  ALLERGY: 22,
  CANCER: 23,
  // AGING: 24,
  TOXIC: 25,
  // AUTOIMMUNITY: 26,
  CARDIAC: 27,
  MUSCULOSKELETAL: 28,
  STRESS_RECOVERY: 29,
  FITNESS: 30,
  CLOTTING: 31,
  GASTROINTESTINAL: 32,
  NEUROLOGIC: 33,
})

export default AbsoluteCategoryCode;
