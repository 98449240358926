import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/user.context";
import { SugarPost } from "./posts/sugarPost.component";
import { OverexercisePost } from "./posts/overexercisePost.component";
import { CacPost } from "./posts/cacPost.component";
import { VapingPost } from "./posts/vapingPost.component";
import { FounderPost } from "./posts/founderPost.component";
import { SaunaPost } from "./posts/saunaPost.component";
import { InsurancePost } from "./posts/insurancePost.component";
import { CumulativeExposurePost } from "./posts/cumulativeExposurePost.component";
import { SoftPlaquePost } from "./posts/softPlaquePost.component";
import { PCSK9InhibitorsPost } from "./posts/pcsk9InhibitorsPost.component";
import { StatinPost } from "./posts/statinPost.component";
import { GalleriPost } from "./posts/galleriPost.component";
import { ApoBPost } from "./posts/apoBPost.component";
import { SupplementPost } from "./posts/supplementPost.component";
import { PeakPost } from "./posts/peakPost.component";
import { BloodPressureDietPost } from "./posts/bloodPressureDietPost.component";
import { ForecastPost } from "./posts/forecastPost.component";
import './blog.scss';
import { RecommendedBlogs } from "./shared/recommendedBlogs/recommendedBlogs.component";
import { useEffect } from "react";
import PosthogHelper from "../../helpers/posthog.helper";
import BlogId from "../../enums/blogId.enum";
import { SeedOilPost } from "./posts/seedOilPost.component";
import { LpaPost } from "./posts/lpaPost.component";
import { BlogData } from "../../data/blog.data";
import { CalculatorPost } from "./posts/calculatorPost.component";
import { PlaqueReversalPost } from "./posts/plaqueReversalPost.component";
import { TestosteroneAgePost } from "./posts/testosteroneAgePost.component";
import { DexaPost } from "./posts/dexaPost.component";
import { BmiPost } from "./posts/bmiPost.component";

export const Blog = () => {
  const { blogId } = useParams()
  const { currentUser } = useContext(UserContext)
  const [blogComponent, setBlogComponent] = useState(null)

  useEffect(() => {
    if (!blogId) return
    document.title = `Instalab | ${BlogData.find(b => b.id === blogId)?.title}`
  }, [blogId])

  useEffect(() => {
    if (currentUser) {
      PosthogHelper.track(currentUser, `[research] view post: ${blogId}`, true)
    }
  }, [currentUser])

  useEffect(() => {
    switch (blogId) {
      case BlogId.VAPING:
        setBlogComponent(<VapingPost />)
        break
      case BlogId.SUGAR:
        setBlogComponent(<SugarPost />)
        break
      case BlogId.SEED_OIL:
        setBlogComponent(<SeedOilPost />)
        break
      case BlogId.CALCULATOR:
        setBlogComponent(<CalculatorPost />)
        break
      case BlogId.TESTOSTERONE_AGE:
        setBlogComponent(<TestosteroneAgePost />)
        break
      case BlogId.STATIN:
        setBlogComponent(<StatinPost />)
        break
      case BlogId.PLAQUE_REVERSAL:
        setBlogComponent(<PlaqueReversalPost />)
        break
      case BlogId.OVEREXERCISE:
        setBlogComponent(<OverexercisePost />)
        break
      case BlogId.CAC:
        setBlogComponent(<CacPost />)
        break
      case BlogId.FOUNDER:
        setBlogComponent(<FounderPost />)
        break
      case BlogId.SAUNA:
        setBlogComponent(<SaunaPost />)
        break
      case BlogId.INSURANCE:
        setBlogComponent(<InsurancePost />)
        break
      case BlogId.SOFT_PLAQUE:
        setBlogComponent(<SoftPlaquePost />)
        break
      case BlogId.GALLERI:
        setBlogComponent(<GalleriPost />)
        break
      case BlogId.PEAK:
        setBlogComponent(<PeakPost />)
        break
      case BlogId.CUMULATIVE_EXPOSURE:
        setBlogComponent(<CumulativeExposurePost />)
        break
      case BlogId.FORECAST:
        setBlogComponent(<ForecastPost />)
        break
      case BlogId.PCSK9I:
        setBlogComponent(<PCSK9InhibitorsPost />)
        break
      case BlogId.APOB:
        setBlogComponent(<ApoBPost />)
        break
      case BlogId.SUPPLEMENT:
        setBlogComponent(<SupplementPost />)
        break
      case BlogId.BLOOD_PRESSURE_DIET:
        setBlogComponent(<BloodPressureDietPost />)
        break
      case BlogId.LPA:
        setBlogComponent(<LpaPost />)
        break
      case BlogId.DEXA:
        setBlogComponent(<DexaPost />)
        break
      case BlogId.BMI:
        setBlogComponent(<BmiPost />)
        break
    }
  }, [blogId])

  return (
    <div className="blog">
      {blogComponent}
      {blogComponent && <RecommendedBlogs />}
    </div>
  )
}