import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import './timerDisplay.scss'

const TimerDisplay = ({ start, timeAllowed, onExpire }) => {

    const [timeRemaining, setTimeRemaining] = useState(timeAllowed);
 
    const calculateTimeRemaining = () => {
        if (!start) return timeAllowed;
        const remaining = timeAllowed - dayjs().diff(dayjs(start), 'seconds');
        return Math.max(remaining, 0);
    };
    

    useEffect(() => {
        // Reset timer when start changes
        setTimeRemaining(calculateTimeRemaining());
        
        const timer = setInterval(() => {
        const remaining = calculateTimeRemaining();
        setTimeRemaining(remaining);
        
        if (remaining <= 0) {
            clearInterval(timer);
        }
        }, 1000);

        return () => clearInterval(timer);
    }, [start, timeAllowed]); // Add timeAllowed to dependencies

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    if (timeRemaining <= 0 && onExpire) {
        onExpire();
    }
    
    return (
        <span className="timer-display">
        {minutes}:{seconds.toString().padStart(2, '0')}
        </span>
    );
};

export default TimerDisplay;