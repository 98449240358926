import React, { useContext, useEffect, useRef, useState } from 'react';
import { Table, Button, Modal, Dropdown, Typography, message, Tag, Col, Row } from 'antd'
import { listAppointments } from "../../services/appointment.service"
import { SettingOutlined, UserAddOutlined, StopOutlined, EyeOutlined, CalendarOutlined } from '@ant-design/icons'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { cancelAppointment } from '../../services/appointment.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import "./appointments.scss";
import AppointmentStatus from '../../enums/appointmentStatus.enum';
import { Step as LongevityStep } from '../longevityTestFlow/longevityTestFlow.component.js'
import { Step as LongevityPscStep } from '../longevityTestPscFlow/longevityTestPscFlow.component.js'
import { Step as AthleteStep } from '../athleteTestFlow/athleteTestFlow.component.js'
import { Step as CustomStep } from '../customTestFlow/customTestFlow.component.js'
import { Step as TestosteroneStep } from '../testosteronePanelFlow/testosteronePanelFlow.component.js'
import { Step as PerformanceStep } from '../performancePanelFlow/performancePanelFlow.component.js'
import { Step as HeartHealthConsultStep } from '../heartMemberConsultFlow/heartMemberConsultFlow.component.js'
import { Step as AthleteConsultStep } from '../athleteConsultFlow/athleteConsultFlow.component.js'
import { Step as FertilityStep } from '../fertilityTestFlow/fertilityTestFlow.component.js'
import { UserContext } from '../../contexts/user.context.js';
import { useNavigate } from 'react-router-dom';
import { Panel } from "../../enums/index.enum.js"
import FlowType from '../../enums/flowType.enum.js';
import { getMe } from '../../services/user.service.js';
import ProductTypeCode from "../../enums/productTypeCode.enum.js"
import AppointmentHelper from '../../helpers/appointment.helper.js';
import { listProductTypes} from '../../services/productType.service.js';
import ScheduleMenu from '../scheduleMenu/scheduleMenu.component.js';
import AppointmentCard from '../appointmentCard/appointmentCard.component.js';
import PatientHelper from '../../helpers/patient.helper.js';
dayjs.extend(utc)
dayjs.extend(timezone)

const scheduleStep = {
  [FlowType.LONGEVITY_TEST]: LongevityStep.RESCHEDULE,
  [FlowType.PRO_DRAW]: LongevityStep.SCHEDULE,
  [FlowType.PRO_ATHLETE_PANEL]: AthleteStep.SCHEDULE,
  [FlowType.ATHLETE_TEST]: AthleteStep.SCHEDULE,
  [FlowType.CUSTOM_TEST]: CustomStep.SCHEDULE,
  [FlowType.TESTOSTERONE_PANEL]: TestosteroneStep.SCHEDULE,
  [FlowType.PERFORMANCE_PANEL]: PerformanceStep.SCHEDULE,
  [FlowType.LONGEVITY_CONSULT]: LongevityStep.SCHEDULE,
  [FlowType.HEART_MEMBER_CONSULT]: HeartHealthConsultStep.CONSULT,
  [FlowType.ATHLETE_CONSULT]: AthleteConsultStep.CONSULT,
  [FlowType.FERTILITY]: FertilityStep.SCHEDULE,
}

const inviteStep = {
  [FlowType.LONGEVITY_TEST]: LongevityStep.INVITE,
  [FlowType.PRO_DRAW]: LongevityStep.INVITE,
}

const appointmentSelect = '_id status location start type products facility phlebType remoteLocation provider'
const appointmentPopulate = [{
  path: 'patients',
  select: '_id'
}, {
  path: 'flow',
  select: '_id type'
}, {
  path: 'orders',
  select: 'panel addOns'
},{
  path: 'products',
  populate: [
    {
      path: 'productType'
    }
  ]
},
{
  path: 'facility'
}, {
  path: 'provider',
  select: 'firstName lastName npi'
}]


export const Appointments = () => {
  const navigate = useNavigate()
  const [appointments, setAppointments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [canceling, setCanceling] = useState([])
  const cancelingRef = useRef(null)
  cancelingRef.current = canceling
  const { token } = useContext(UserContext)
  const [currentUser, setCurrentUser] = useState()
  const [productTypes, setProductTypes] = useState([]);


  useEffect(() => {
    document.title = 'Testing Appoinments | Instalab'
    fetchAppointments()
  }, [])

  useEffect(() => {
    fetchCurrentUser()
  }, [token])

  useEffect(() => {
    fetchProductTypes(); 
  }, [currentUser])

  
  const fetchCurrentUser = async () => {
    if (!token) return
    setCurrentUser(await getMe({
      select: '_id credits isAthlete memberships',
      populate: [{
        path: 'memberships',
        select: 'membershipType status endAt',
        populate: [{
          path: 'membershipType'
        }]
      }]
    }))
  }

  // Fetch product types for scheudle menu
  const fetchProductTypes = async () => {
    if (!currentUser) return;
  
    // Define a filter to only fetch specific product codes
    let filter = {
      code: { $in: [
        ProductTypeCode.LONGEVITY_PANEL, 
        ProductTypeCode.CTCALCIUM,
        ProductTypeCode.CLEERLY,
        ProductTypeCode.DEXA_BODYCOMP,
        ProductTypeCode.DEXA_BONE,
        ProductTypeCode.TESTOSTERONE_PANEL,
        ProductTypeCode.FERTILITY,
        ProductTypeCode.PRENUVO,
        ProductTypeCode.VO2MAX,
        ProductTypeCode.CONSULT_LONGEVITY,
        ProductTypeCode.HEART_HEALTH_TEST,
      ] }
    };
  
    // If the user is an athlete, include "athlete-panel" and "athlete-consult"
    if (currentUser?.isAthlete) {
      filter.code.$in.unshift(ProductTypeCode.CONSULT_ATHLETE);
      filter.code.$in.unshift(ProductTypeCode.ATHLETE_PANEL); 
    }

    // if user is a heart health member, include "heart-health-consult"
    if (await PatientHelper.isHeartHealthMember(currentUser)) {
      filter.code.$in.unshift(ProductTypeCode.CONSULT_HEART_HEALTH);
    }
  
    try {
      let types = await listProductTypes({ filter }); // Pass the filter when calling API

      types = types.sort((a, b) => {
        if (a.code === ProductTypeCode.ATHLETE_PANEL) return -1; // ATHLETE_PANEL should be first
        if (b.code === ProductTypeCode.ATHLETE_PANEL) return 1;
        if (a.code === ProductTypeCode.LONGEVITY_PANEL) return -1; // LONGEVITY_PANEL second
        if (b.code === ProductTypeCode.LONGEVITY_PANEL) return 1;
        
        // Sort the rest alphabetically by title
        return a.title.localeCompare(b.title);
      });
      
      setProductTypes(types);
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  };

  const fetchAppointments = async () => {
    // blood draws
    const appts = await listAppointments({ 
      filter: {
        status: {
          $in: [
            AppointmentStatus.PENDING,
            AppointmentStatus.COLLECTED,
            AppointmentStatus.COMPLETE,
            AppointmentStatus.CONFIRMED,
          ]
        },
        products: { $exists: true, $not: { $size: 0 } }
      },
      select: appointmentSelect,
      populate: appointmentPopulate,
      sort: '-start'
    })

    // Sort by status first (PENDING at the top), then by start date
    appts.sort((a, b) => {
      if (a.status === AppointmentStatus.PENDING && b.status !== AppointmentStatus.PENDING) return -1;
      if (a.status !== AppointmentStatus.PENDING && b.status === AppointmentStatus.PENDING) return 1;
      return new Date(b.start) - new Date(a.start);
    });

    setAppointments(appts);

    setIsLoading(false);
  };

  const onCancel = async (_id) => {
    setCanceling([
      ...cancelingRef.current,
      _id
    ])
    try {
      const appointment = await cancelAppointment(_id, {
        select: appointmentSelect,
        populate: appointmentPopulate,
      })
      setAppointments(appointments.map(a => a._id === _id ? appointment : a))
      message.info('Appointment canceled.')
      fetchCurrentUser()
    } catch (err) {
      message.error('Failed to cancel appointment')
    }
    setCanceling(cancelingRef.current.filter(cancelingId => cancelingId !== _id))
  }

  const getUrlPrefix = (flow) => {
    if (flow?.type === FlowType.PRO_DRAW) {
      return `/flow/${FlowType.LONGEVITY_TEST}/`
    } else if (flow?.type === FlowType.PRO_CONSULT) {
      return `/flow/${FlowType.LONGEVITY_CONSULT}/`
    } else {
      return `/flow/${flow?.type}/`
    }
  }

  const onReschedule = async (_id, flow) => {
    if (!scheduleStep[flow.type]) {
      Modal.info({
        title: 'Contact Concierge',
        content: 'Please email concierge@instalab.com to reschedule this appointment.',
        okText: 'OK'
      });
      return;
    }
    
    navigate(`${getUrlPrefix(flow)}${scheduleStep[flow.type]}/${flow._id}`)
  }

  const onInvite = async (_id, flow) => {
    navigate(`${getUrlPrefix(flow)}${inviteStep[flow.type]}/${flow._id}`)
  }

  // Determine if appointment is same day
  const isToday = (start) => {
    const today = new Date();
    const startDate = new Date(start);

    return startDate.getDate() === today.getDate() &&
            startDate.getMonth() === today.getMonth() &&
            startDate.getFullYear() === today.getFullYear();
  }


  const items = (appointment) => {

    const { _id, flow, status, start, type } = appointment
    if (type === "other") return false;

    // Function to show the confirmation modal
    const showCancelConfirm = (_id) => {
      let content = "Your account will be credited back after you do this so you can schedule another time."

      if (isToday(start)) {
        content = <>Unfortunately canceling the same day will result in a <b>$50 fee</b> due to the impact on our scheduling and business operations. We appreciate your understanding in this. <br/><br/>Your account will be credited with a blood draw after you cancel so you can reschedule another time.</>
      }

      Modal.confirm({
        title: 'Are you sure you want to cancel your appointment?',
        content: content,
        okText: "OK, cancel",
        onOk() {
          onCancel(_id);
        },
        onCancel() {
          console.log('Cancel operation was aborted');
        },
      });

    };

    // Function to show the reschedule modal
    const showRescheduleConfirm = (_id, flow) => {
      if (isToday(start)) {
        Modal.confirm({
          title: 'Are you sure you want to reschedule?',
          content: <>Unfortunately rescheduling the same day will result in a <b>$50 fee</b> due to the impact on our scheduling and business operations. We appreciate your understanding in this.</>,
          okText: "OK, reschedule",
          onOk() {
            onReschedule(_id, flow);
          },
          onCancel() {
            console.log('Reschedule operation was aborted');
          },
        });
      }

      else {
        onReschedule(_id, flow);
      }
    };

    const isMaster = appointment?.patients[0]?._id === currentUser?._id

    const rescheduleItem = (flow && isMaster) ? {
      key: '2',
      label: (
        <a 
          onClick={() => showRescheduleConfirm(_id, flow)}
          className="error"
        >
          <CalendarOutlined style={{marginRight: 5}} /> Reschedule
        </a>
      )
    } : null

    const inviteItem = ([FlowType.LONGEVITY_TEST, FlowType.PRO_DRAW].includes(flow?.type) && isMaster) ? {
      key: 'invite',
      label: (
        <a 
          onClick={() => onInvite(_id, flow)}
          className="error"
        >
          <UserAddOutlined style={{marginRight: 5}} /> Invite
        </a>
      )
    } : null

    const cancelItem = isMaster ? {
      key: '3',
      label: (
        <a 
          onClick={() => showCancelConfirm(_id)}
          className="remove-item"
        >
          <StopOutlined style={{marginRight: 5}} /> Cancel
        </a>
      )
    } : null


    let menuItems = []

    // visual hack - fix this
    const customPanel = (AppointmentHelper.getPanel(appointment) === Panel.CUSTOM);

    if (status === AppointmentStatus.CONFIRMED) {

      if (rescheduleItem && !customPanel) {
        menuItems.push(rescheduleItem)
      }
      if (inviteItem && AppointmentHelper.canInvite(appointment)) {
        menuItems.push(inviteItem)
      }
      if (cancelItem && !appointment.appointmentParent) {
        menuItems.push({
          type: 'divider'
        })
        menuItems.push(cancelItem)
      }
    } 
    
    // else if (status === AppointmentStatus.PENDING) {

    //   menuItems.push(cancelItem)
    // }

    return menuItems
  }

  const renderMobileCard = (appointment) => {
    const dropdownItems = items(appointment);

    return (
      <Col sm={24} lg={12}><AppointmentCard appointment={appointment}  actionItems={dropdownItems} /></Col>
    );
  };

  return (
    <div className="appointments">
      <PageHeader
        title='Appointments'
        count={appointments.length}
        actions={(
          <ScheduleMenu  
            productTypes={productTypes} 
            currentUser={currentUser} 
          />
        )}
      />

      <div className="appointments-container">

          {isLoading ? (
            <div className="loading-container">Loading...</div>
          ) : (
            <Row gutter={[16, 16]}>
              {appointments.map(renderMobileCard)}
            </Row>
          )}
       
      </div>
    </div>
  )
}