import React, { useState, useEffect, useRef, useContext } from 'react';
import './testRecommender.scss';
import { MedicalCondition, ProductTypeCode, SleepQuality, QuestionTemplateCode, FlowType, Medication } from '../../enums/index.enum';
import { listProductTypes } from '../../services/productType.service';
import { Button, Affix, Card, Modal, Badge, Spin, Tooltip    } from 'antd';
import { CalendarOutlined, CheckCircleOutlined, ArrowRightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { addSlack } from '../../services/slack.service';
import { UserContext } from '../../contexts/user.context';
import { listQuestionTemplates } from '../../services/question.service';
import { buildUser } from '../../services/user.service';
import { addFlow } from '../../services/flow.service';
import RecommendationHelper from '../../helpers/recommendation.helper';
import PhotoHelper from '../../helpers/photo.helper';
import MobileResultsDrawer from '../mobileResultsDrawer/mobileResultsDrawer.component';
import { listResults } from '../../services/result.service';
import { listTests } from '../../services/test.service';

const familyHistoryConditions = [
    MedicalCondition.CANCER,
    MedicalCondition.DEMENTIA,
    MedicalCondition.DIABETES,
    MedicalCondition.HEART_ATTACK,
    MedicalCondition.HEART_DISEASE,
    MedicalCondition.OSTEOPOROSIS,
    MedicalCondition.STROKE,
]

const ownConditions = [
    MedicalCondition.CELIAC,
    MedicalCondition.DIABETES,
    MedicalCondition.HIGH_BLOOD_PRESSURE,
    MedicalCondition.HIGH_CHOLESTEROL,
    MedicalCondition.OBESITY,
    MedicalCondition.OSTEOPOROSIS,
    MedicalCondition.PREDIABETES,
    MedicalCondition.SLEEP_APNEA,
]

const cancerConditions = [
    MedicalCondition.CANCER_BREAST,
    // MedicalCondition.CANCER_CERVICAL,
    MedicalCondition.CANCER_COLORECTAL,
    // MedicalCondition.CANCER_UTERINE,
    // MedicalCondition.CANCER_LUNG,
    // MedicalCondition.CANCER_OVARIAN,
    // MedicalCondition.CANCER_PANCREATIC,
    MedicalCondition.CANCER_PROSTATE,
    MedicalCondition.CANCER_SKIN,
    // MedicalCondition.CANCER_STOMACH,
    // MedicalCondition.CANCER_THYROID,
    MedicalCondition.CANCER_OTHER,
  ]

  const medications = [
    Medication.STATIN,
    Medication.GLP1,
    Medication.EZETIMIBE,
  ]

export const TestRecommender = () => {
    const [recommendedTests, setRecommendedTests] = useState([]);
    const [productTypes, setProductTypes] = useState()
    const ageInputRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const [hasOverflow, setHasOverflow] = useState(false);
    const [isMobileResultsOpen, setIsMobileResultsOpen] = useState(false);
    const [prevCount, setPrevCount] = useState(0);
    const [prevTests, setPrevTests] = useState([]);
    const [hasNewRecommendations, setHasNewRecommendations] = useState(false);
    const { currentUser, instalabMembership } = useContext(UserContext);
    const [questions, setQuestions] = useState([])
    const [answers, setAnswers] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [flow, setFlow] = useState(null)
    const [tests, setTests] = useState([])
    const [results, setResults] = useState([])

    useEffect(() => {
        const fetchResults = async () => {
            const results = await listResults({select: '_id collectedAt values'});
            setResults(results)
        }

        const fetchFlow = async () => {
            setFlow(await addFlow({type: FlowType.QUIZ, forceNew: true}))
        }
    

        const fetchProductTypes = async () => {
            setProductTypes(await listProductTypes({
              filter: {
                code: {
                  $in: [
                    ProductTypeCode.BP_MONITOR,
                    ProductTypeCode.CTCALCIUM,
                    ProductTypeCode.GALLERI,
                    ProductTypeCode.HEART_HEALTH_TEST_KIT,
                    ProductTypeCode.LONGEVITY_PANEL,
                    ProductTypeCode.FIT_STOOL_TEST_KIT,
                    ProductTypeCode.DEXA_BONE,
                    ProductTypeCode.DEXA_BODYCOMP,
                    ProductTypeCode.CLEERLY,
                    ProductTypeCode.WATCHPAT_ONE,
                    ProductTypeCode.TOXIC_METALS_URINE_TEST,
                    ProductTypeCode.MYCOTOX_PROFILE,
                    ProductTypeCode.FERTILITY,
                    ProductTypeCode.TESTOSTERONE_PANEL,
                    ProductTypeCode.LDCT,
                    ProductTypeCode.COLONOSCOPY,
                    ProductTypeCode.MAMMOGRAM,
                    ProductTypeCode.PAP_SMEAR,
                    ProductTypeCode.HPV_TEST,
                    ProductTypeCode.DRE,
                    ProductTypeCode.SKIN_CANCER_EXAM,
                    ProductTypeCode.SPERM_TEST_KIT,
                  ]
                }
              }
            }))
        }
    
        const fetchTests = async () => {
            setTests(await listTests())
        }
    
        const fetchQuestions = async () => {    
            setQuestions(await listQuestionTemplates({
                filter: {
                    code: {
                        $in: [
                            QuestionTemplateCode.DOB,
                            QuestionTemplateCode.GENDER,
                            QuestionTemplateCode.CURRENT_MEDICAL_CONDITIONS,
                            QuestionTemplateCode.FAMILY_HISTORY,
                            QuestionTemplateCode.PREVIOUS_SMOKING,
                            QuestionTemplateCode.SLEEP_QUALITY,
                            QuestionTemplateCode.PLANNING_CHILDREN,
                            QuestionTemplateCode.HAZARDOUS_EXPOSURE,
                            QuestionTemplateCode.POSSIBLE_TOXIC_EXPOSURE,
                            QuestionTemplateCode.CURRENT_MEDICATIONS,
                        ]
                    }
                }
            }))
        }
    

        fetchProductTypes()
        fetchQuestions()
        fetchFlow()
        fetchTests()
        fetchResults()
        ageInputRef.current.focus();
    }, [])


    useEffect(() => {

        const fetchCurrentUser = async () => {
            try {
                const newAnswers = await RecommendationHelper.getAnswers(currentUser)
                setAnswers(newAnswers);
                updateRecommendations(newAnswers);
            } catch (error) {
                console.error('Error fetching current user:', error);
            } finally {
                setIsLoading(false);
            }
        }

        setAnswers({
            [getQuestionTemplate(QuestionTemplateCode.DOB)?.field]: '',
            [getQuestionTemplate(QuestionTemplateCode.GENDER)?.field]: '',
            [getQuestionTemplate(QuestionTemplateCode.CURRENT_MEDICAL_CONDITIONS)?.field]: [],
            [getQuestionTemplate(QuestionTemplateCode.FAMILY_HISTORY)?.field]: [],
            [getQuestionTemplate(QuestionTemplateCode.PREVIOUS_SMOKING)?.field]: '',
            [getQuestionTemplate(QuestionTemplateCode.SLEEP_QUALITY)?.field]: '',
            [getQuestionTemplate(QuestionTemplateCode.PLANNING_CHILDREN)?.field]: '',
            [getQuestionTemplate(QuestionTemplateCode.HAZARDOUS_EXPOSURE)?.field]: '',
            [getQuestionTemplate(QuestionTemplateCode.POSSIBLE_TOXIC_EXPOSURE)?.field]: '',
            [getQuestionTemplate(QuestionTemplateCode.CURRENT_MEDICATIONS)?.field]: [],
        })
        fetchCurrentUser()
    }, [questions, currentUser])


    const getQuestionTemplate = (code) => {
        return questions.find(question => question.code === code)
    }

    useEffect(() => {
        const checkOverflow = () => {
            if (scrollContainerRef.current) {
                const hasVerticalOverflow = scrollContainerRef.current.scrollHeight > scrollContainerRef.current.clientHeight;
                setHasOverflow(hasVerticalOverflow);
            }
        };
        
        checkOverflow();
        // Recheck when recommendations change
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [recommendedTests]);

    const handleChange = async (e) => {
        const { name, value, type, checked } = e.target;
        
        // Handle the new value based on input type
        const newValue = type === 'checkbox'
            ? checked
                ? [...(answers[name] || []), value]
                : answers[name].filter(item => item !== value)
            : value;

        // Update state
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [name]: newValue
        }));

        // Update user in system
        try {
            await buildUser({
                fields: {[name]: newValue},
                flow: flow._id,
            });
        } catch (error) {
            console.error('Error building user:', error);
        }

        updateRecommendations({ ...answers, [name]: newValue });
    };

    const updateRecommendations = (currentAnswers) => {

        // Get recommendations from separate module
        const newTests = RecommendationHelper.getRecommendations(currentAnswers, results, tests);
        
        // Check for count changes or reordering
        const hasCountChanged = newTests.length !== prevCount;
        const hasReordered = prevTests.length > 0 && !newTests.every((test, index) => test.test === prevTests[index]?.test);

        if (hasCountChanged || hasReordered) {
            setHasNewRecommendations(true);
            setRecommendedTests(newTests);
            setPrevCount(newTests.length);
            setPrevTests(newTests);
            setTimeout(() => setHasNewRecommendations(false), 3000);
        }
    };

    const getProductType = (code) => {
        return productTypes?.find(productType => productType.code === code)
    }

    const handleScheduleTest = async (code) => {
        const productType = getProductType(code);
        const url = productType?.path || `flow/${productType?.flowType}`;
        
        if (!url || url === 'flow/undefined') {
            Modal.info({
                title: 'Schedule with Concierge',
                content: (
                    <div>
                        <p>To schedule this test, please email our concierge team at:</p>
                        <p><strong>concierge@instalab.com</strong></p>
                        <p>Our team will help coordinate your testing appointment.</p>
                    </div>
                ),
                okText: 'Close',
            });
            return;
        }

        try {
            await addSlack({
              message: currentUser.firstName ? `[${currentUser.firstName} ${currentUser.lastName}] Test Recommendation Click: ${code}` : `Test Recomendation Click: ${code}`,
              channel: process.env.REACT_APP_FLOW_SLACK
            });
          } catch (error) {
            console.error('Failed to log message to Slack:', error);
          }
        
        window.open(`${url}`, '_blank');
    };

    const onJoinNowClick = async () => {
        try {
            await addSlack({
              message: currentUser.firstName ? `[${currentUser.firstName} ${currentUser.lastName}] Test Recommendation - Membership Click:` : `Test Recommendation - Membership Click:`,
              channel: process.env.REACT_APP_FLOW_SLACK
            });
          } catch (error) {
            console.error('Failed to log message to Slack:', error);
          }
        window.open(`/flow/${FlowType.MEMBER}`, '_blank');
    }

    const ResultsPanel = ({ hideTitle = false }) => (
        <div className="results-panel">
            {!hideTitle && (
                <>
                    <h2>
                        Your Testing Roadmap {recommendedTests.length > 0 && 
                            <Badge 
                                count={recommendedTests.length}
                                className={`results-count ${hasNewRecommendations ? 'animateBadge' : ''}`}
                                
                            />}

                    </h2>
                    {recommendedTests.length > 0 && (
                        <>
                            <p className="results-subtitle" style={{ marginTop: -20 }}>
                                Based on your profile, these are the prioritized set of tests we recommend for you to stay on top of your health:
                            </p>
                            
                          
                        </>
                    )}
                </>
            )}
            <div 
                ref={scrollContainerRef}
                className={`results-scroll-container ${hasOverflow ? 'has-overflow' : ''}`}
            >
                 {isLoading ? (
                <div className="loading-state">
                    <Spin size="large" />
                    <p>Loading your health profile...</p>
                </div>
            ) : recommendedTests.length > 0 ? (
                    <div className="tests-list">


                        {recommendedTests
                            .sort((a, b) => b.weight - a.weight)
                            .map(({test, weight, yearFrequency}, index) => (
                                <Card 
                                    key={test}
                                    onClick={() => handleScheduleTest(test)}
                                    className={`test-card ${!prevTests.find(t => t.test === test) ? 'animate-in' : ''}`}
                                    style={{ 
                                        marginBottom: 16,
                                        animationDelay: !prevTests.find(t => t.test === test) ? `${index * 100}ms` : undefined
                                    }}
                                >
                                    <div className="test-card-content">
                                        <div className="rank-column">
                                            <span className="test-number">#{index + 1}</span>
                                        </div>
                                        <div className="test-info">
                                            <div className="test-header">
                                                <h3>{getProductType(test)?.title}</h3>

                                            </div>
                                            <p className="test-tagline">{getProductType(test)?.tagline}</p>
                                            <div className="test-footer">
                                                <div className="test-footer-tags">
                                                {yearFrequency && (
                                                    <span className="frequency-badge">
                                                        <CalendarOutlined  style={{marginRight: 5}}/>
                                                        {yearFrequency === 1 
                                                            ? 'Annual'
                                                            : yearFrequency < 1
                                                                ? `Every ${Math.round(yearFrequency * 12)} months`
                                                                : `Every ${yearFrequency} years`
                                                        }
                                                    </span>
                                                )}
                                              {getProductType(test)?.acceptsInsurance && (
                                                    <Tooltip title={`Usually covered by insurance`}>
                                                        <span className="insurance-badge"><CheckCircleOutlined /> Insurance</span>
                                                    </Tooltip>
                                                )}


                                                {getProductType(test)?.cost && !getProductType(test)?.acceptsInsurance && (
                                                    <Tooltip title={`${getProductType(test)?.costIsServiceFee ? `~$${getProductType(test)?.avgCost} (varies by location)` : `$${getProductType(test)?.cost}`}`}>
                                                        <span className="insurance-badge cost-badge">
                                                            {getCostLevel(getProductType(test)?.costIsServiceFee ? getProductType(test)?.avgCost : getProductType(test)?.cost)?.level}
                                                        </span>
                                                    </Tooltip>
                                                )}
                                                </div> 

                                          
                                            
                                                    <Button 
                                                        type="default"
                                                    size="middle"
                                                >
                                                    Get Test
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            ))}

                            {!instalabMembership && <Card className="membership-upsell flat">
                                <div className="upsell-content">
                                    <div className="card-title">
                                    <QuestionCircleOutlined style={{ color: '#0ca37f' }} />
                                   Need help managing your tests?
                                    </div>
                                    <p>With the <strong>Instalab Membership</strong>, we'll schedule these tests for you, organize results, and guide follow-ups. Plus:</p>
                                    <ul>
                                        <li className="benefit-item"><CheckCircleOutlined />Access to a preventive medicine and longevity physician</li>
                                        <li className="benefit-item"><CheckCircleOutlined /> Concierge coordination of tests and treatments</li>
                                        <li className="benefit-item"><CheckCircleOutlined /> Special member pricing on popular tests</li>
                                    </ul>
                                    <Button 
                                        type="primary" 
                                        size="large" 
                                        className="contact-concierge-btn"
                                        onClick={onJoinNowClick}
                                     
                                    >
                                        Get Started Now <ArrowRightOutlined style={{ marginLeft: '8px' }} />
                                    </Button>
                                </div>
                            </Card>}
                                          
                    </div>
                ) : (
                    <div className="empty-state">
                        <div className="arrow-up">↑</div>
                        <p>Answer questions about yourself to get a prioritized list of tests to help you stay on top of your health.</p>
                        <div className="arrow-left desktop-only">&larr;</div>
                        <p>If you're already an Instalab patient, please <a href="/login?redirect=/start" className="cta-link">log in</a> to use all available data for your recommendations.</p>
                    </div>
                )}
            </div>
        </div>
    );

    const RadioCard = ({ name, value, label, checked, onChange }) => (
        <div 
            className={`radio-card ${checked ? 'selected' : ''}`}
            onClick={() => onChange({ target: { name, value } })}
        >
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <span>{label}</span>
        </div>
    );

    const CheckboxCard = ({ id, name, value, label, checked, onChange }) => (
        <div 
            className={`checkbox-card ${checked ? 'selected' : ''}`}
            onClick={() => onChange({ 
                target: { 
                    name, 
                    value, 
                    checked: !checked,
                    type: 'checkbox'
                } 
            })}
        >
            <input
                type="checkbox"
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <span>{label}</span>
        </div>
    );

    const getCostLevel = (cost) => {
        if (!cost) return null;
        if (cost < 100) return { level: '$', label: 'Low Cost' };
        if (cost < 300) return { level: '$$', label: 'Moderate Cost' };
        if (cost < 500) return { level: '$$$', label: 'Premium Cost' };
        return { level: '$$$$', label: 'High Cost' };
    };

    const formatDateInput = (input) => {
        // Remove any non-numeric characters
        let numbers = input.replace(/\D/g, '');
        
        // Add slashes after MM and DD
        if (numbers.length > 4) {
            numbers = numbers.slice(0, 2) + '/' + numbers.slice(2, 4) + '/' + numbers.slice(4);
        } else if (numbers.length > 2) {
            numbers = numbers.slice(0, 2) + '/' + numbers.slice(2);
        }
        
        return numbers;
    };


    const isValidDate = (dateString) => {

        if (dateString.length !== 10) return false;

        // First check for the pattern
        if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
            return false;
      
        // Parse the date parts to integers
        var parts = dateString.split("/");
        var day = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[2], 10);
      
        // Check the ranges of month and year
        if(year < 1000 || year > 3000 || month === 0 || month > 12)
            return false;
      
        var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
      
        // Adjust for leap years
        if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
            monthLength[1] = 29;
      
        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
      };

    const handleDateChange = (e) => {
        const rawInput = e.target.value;
        const formattedDate = formatDateInput(rawInput);

        // Update the state with the raw input to allow user edits
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [getQuestionTemplate(QuestionTemplateCode.DOB)?.field]: formattedDate
        }));

        // Only update the state with the formatted date if it's valid
        if (isValidDate(formattedDate)) {
            // console.log("valid date", formattedDate);
            setAnswers(prevAnswers => ({
                ...prevAnswers,
                [getQuestionTemplate(QuestionTemplateCode.DOB)?.field]: formattedDate
            }));

            // Optionally, call handleChange here if needed for other side effects
            handleChange({ 
                target: { 
                    name: getQuestionTemplate(QuestionTemplateCode.DOB)?.field, 
                    value: formattedDate 
                } 
            });
        }
    };

    // DEBUG - comment out for production
    // console.log("questions", questions)
    // console.log("answers", answers)
    // console.log("recommendedTests", recommendedTests)

    return (
        questions &&  answers && <div className="test-recommender-container">

            {PhotoHelper.format({
                jpg: "/img/green.jpg",
                webp: "/img/green.webp",
                className: "intro-bg",
            })}

            <div className="panels-container">
                <div className="questions-panel">
                    <h2 className="desktop-only">Answer Health Questions</h2>
                    <h2 className="mobile-only">Get Your Testing Roadmap</h2>
                    <p className="description mobile-only">Answer a few questions about yourself to get a prioritized list of tests you should do to help you stay on top of your health.</p>
                    <form className="questions-form">

                        <div className="form-group">
                            <label>{getQuestionTemplate(QuestionTemplateCode.DOB)?.content}</label>
                            <p className="description">{getQuestionTemplate(QuestionTemplateCode.DOB)?.caption}</p>
                            <input
                                type="tel"
                                placeholder="MM/DD/YYYY"
                                value={answers[getQuestionTemplate(QuestionTemplateCode.DOB)?.field]}
                                onChange={handleDateChange}
                                className={`form-input ${
                                    answers[getQuestionTemplate(QuestionTemplateCode.DOB)?.field]?.length === 10 && 
                                    !isValidDate(answers[getQuestionTemplate(QuestionTemplateCode.DOB)?.field]) 
                                        ? 'invalid' 
                                        : ''
                                }`}
                                maxLength="10"
                                ref={ageInputRef}
                            />
                        </div>

                        <div className="form-group">
                            <label>{getQuestionTemplate(QuestionTemplateCode.GENDER)?.content}</label>
                            <div className="radio-cards-group">
                                <RadioCard
                                    name={getQuestionTemplate(QuestionTemplateCode.GENDER)?.field}
                                    value="Male"
                                    label="Male"
                                    checked={answers[getQuestionTemplate(QuestionTemplateCode.GENDER)?.field] === 'Male'}
                                    onChange={handleChange}
                                />
                                <RadioCard
                                    name={getQuestionTemplate(QuestionTemplateCode.GENDER)?.field}
                                    value="Female"
                                    label="Female"
                                    checked={answers[getQuestionTemplate(QuestionTemplateCode.GENDER)?.field] === 'Female'}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Which of these medical conditions do you currently have?</label>
                            <div className="checkbox-cards-group">
                                {ownConditions.map((condition) => (
                                    <CheckboxCard
                                        key={condition}
                                        id={`own-${condition}`}
                                        name={getQuestionTemplate(QuestionTemplateCode.CURRENT_MEDICAL_CONDITIONS)?.field}
                                        value={condition}
                                        label={condition.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                        checked={answers[getQuestionTemplate(QuestionTemplateCode.CURRENT_MEDICAL_CONDITIONS)?.field]?.includes(condition)}
                                        onChange={handleChange}
                                    />
                                    ))}
                            </div>
                        </div>


                        <div className="form-group">
                            <label>Which of these medications do you currently take?</label>
                            <div className="checkbox-cards-group">
                                {medications.map((medication) => (
                                    <CheckboxCard
                                        key={medication}
                                        id={`medication-${medication}`}
                                        name={getQuestionTemplate(QuestionTemplateCode.CURRENT_MEDICATIONS)?.field}
                                        value={medication}
                                        label={medication.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                        checked={answers[getQuestionTemplate(QuestionTemplateCode.CURRENT_MEDICATIONS)?.field]?.includes(medication)}
                                        onChange={handleChange}
                                    />
                                    ))}
                            </div>
                        </div>
                        

                        <div className="form-group">
                            <label>Which of these medical conditions has anyone in your immediate family had <u>before age 60</u>?</label>
                            <p className="description">This includes your grandparents, parents, siblings, and children.</p>
                            <div className="checkbox-cards-group">
                                {familyHistoryConditions.map((condition) => (
                                    <CheckboxCard
                                        key={condition}
                                        id={condition}
                                        name="familyHistory"
                                        value={condition}
                                        label={condition.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                        checked={answers[getQuestionTemplate(QuestionTemplateCode.FAMILY_HISTORY)?.field]?.includes(condition)}
                                        onChange={handleChange}
                                    />
                                ))}
                            </div>
                        </div>

                        {answers[getQuestionTemplate(QuestionTemplateCode.FAMILY_HISTORY)?.field]?.includes(MedicalCondition.CANCER) && (
                            <div className="form-group">
                                <label>What types of cancer were diagnosed in your family?</label>
                                <div className="checkbox-cards-group">
                                    {cancerConditions.map((condition) => (
                                        <CheckboxCard
                                            key={condition}
                                            id={condition}
                                            name="familyHistory"
                                            value={condition}
                                            label={condition.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                            checked={answers[getQuestionTemplate(QuestionTemplateCode.FAMILY_HISTORY)?.field]?.includes(condition)}
                                            onChange={handleChange}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}

                    
                        <div className="form-group">
                            <label>{getQuestionTemplate(QuestionTemplateCode.PLANNING_CHILDREN)?.content}</label>
                            <div className="radio-cards-group">
                                <RadioCard
                                    name={getQuestionTemplate(QuestionTemplateCode.PLANNING_CHILDREN)?.field}
                                    value={true}
                                    label="Yes"
                                    checked={answers[getQuestionTemplate(QuestionTemplateCode.PLANNING_CHILDREN)?.field] === true}
                                    onChange={handleChange}
                                />
                                <RadioCard
                                    name={getQuestionTemplate(QuestionTemplateCode.PLANNING_CHILDREN)?.field}
                                    value={false}
                                    label="No"
                                    checked={answers[getQuestionTemplate(QuestionTemplateCode.PLANNING_CHILDREN)?.field] === false}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                       

                        <div className="form-group">
                            <label>{getQuestionTemplate(QuestionTemplateCode.PREVIOUS_SMOKING)?.content}</label>
                            <div className="radio-cards-group">
                                <RadioCard
                                    name={getQuestionTemplate(QuestionTemplateCode.PREVIOUS_SMOKING)?.field}
                                    value={true}
                                    label="Yes"
                                    checked={answers[getQuestionTemplate(QuestionTemplateCode.PREVIOUS_SMOKING)?.field] === true}
                                    onChange={handleChange}
                                />
                                <RadioCard
                                    name={getQuestionTemplate(QuestionTemplateCode.PREVIOUS_SMOKING)?.field}
                                    value={false}
                                    label="No"
                                    checked={answers[getQuestionTemplate(QuestionTemplateCode.PREVIOUS_SMOKING)?.field] === false}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>


                        <div className="form-group">
                            <label>{getQuestionTemplate(QuestionTemplateCode.SLEEP_QUALITY)?.content}</label>
                            <div className="radio-cards-group">
                                {Object.values(SleepQuality).map(quality => (
                                    <RadioCard
                                        key={quality}
                                        name={getQuestionTemplate(QuestionTemplateCode.SLEEP_QUALITY)?.field}
                                        value={quality}
                                        label={quality}
                                        checked={answers[getQuestionTemplate(QuestionTemplateCode.SLEEP_QUALITY)?.field] === quality}
                                        onChange={handleChange}
                                    />
                                ))}
                            </div>
                        </div>


                        <div className="form-group">
                            <label>{getQuestionTemplate(QuestionTemplateCode.HAZARDOUS_EXPOSURE)?.content}</label>
                            <p className="description">{getQuestionTemplate(QuestionTemplateCode.HAZARDOUS_EXPOSURE)?.caption}</p>
                            <div className="radio-cards-group">
                                <RadioCard
                                    name={getQuestionTemplate(QuestionTemplateCode.HAZARDOUS_EXPOSURE)?.field}
                                    value={true}
                                    label="Yes"
                                    checked={answers[getQuestionTemplate(QuestionTemplateCode.HAZARDOUS_EXPOSURE)?.field] === true}
                                    onChange={handleChange}
                                />
                                <RadioCard
                                    name={getQuestionTemplate(QuestionTemplateCode.HAZARDOUS_EXPOSURE)?.field}
                                    value={false}
                                    label="No"
                                    checked={answers[getQuestionTemplate(QuestionTemplateCode.HAZARDOUS_EXPOSURE)?.field] === false}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>


                        <div className="form-group">
                            <label>{getQuestionTemplate(QuestionTemplateCode.POSSIBLE_TOXIC_EXPOSURE)?.content}</label>
                            <p className="description">{getQuestionTemplate(QuestionTemplateCode.POSSIBLE_TOXIC_EXPOSURE)?.caption}</p>
                            <div className="radio-cards-group">
                                <RadioCard
                                    name={getQuestionTemplate(QuestionTemplateCode.POSSIBLE_TOXIC_EXPOSURE)?.field}
                                    value={true}
                                    label="Yes"
                                    checked={answers[getQuestionTemplate(QuestionTemplateCode.POSSIBLE_TOXIC_EXPOSURE)?.field] === true}
                                    onChange={handleChange}
                                />
                                <RadioCard
                                    name={getQuestionTemplate(QuestionTemplateCode.POSSIBLE_TOXIC_EXPOSURE)?.field}
                                    value={false}
                                    label="No"
                                    checked={answers[getQuestionTemplate(QuestionTemplateCode.POSSIBLE_TOXIC_EXPOSURE)?.field] === false}
                                    onChange={handleChange}
                                />
                            </div>
                        </div> 

                    </form>
                </div>

                <div className="results-wrapper">
                    <Affix offsetTop={24} className="desktop-only">
                        <ResultsPanel />
                    </Affix>
                </div>
            </div>
            
            <div className="mobile-only">
                <MobileResultsDrawer
                    headerText="Your Testing Roadmap"
                    hasResults={recommendedTests.length > 0}
                    readyText={`${recommendedTests.length} Tests`}
                    hasUpdates={hasNewRecommendations}
                    children={<ResultsPanel hideTitle={true} />}
                />
            </div>
        </div>
    );
}

export default TestRecommender;
