import React, { useContext, useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { message, Button, Popconfirm, Tooltip } from 'antd';
import { listProviders, removeProviderFromPatient } from '../../services/patient.service.js';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import { UserContext } from '../../contexts/user.context.js';
import { useParams } from 'react-router-dom'
import { AddProviderForm } from './addProviderForm.component.js';
import './adminProviders.scss';

const select = '_id location scheduleLink type author patient provider';

export const AdminProviders = ({ hasPatient=false }) => {
  const { patientId } = useParams()
  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredCount, setFilteredCount] = useState();
  const { setCounts, currentUser } = useContext(UserContext); 
  const [isAddProviderModalOpen, setIsAddProviderModalOpen] = useState(false);


  useEffect(() => {
    fetchProviders();
  }, [hasPatient, patientId]);

  const fetchProviders = async () => {
    setIsLoading(true);
    let fetchedProviders

    if (hasPatient && !patientId) return

    else if (hasPatient && patientId) {
      fetchedProviders = await listProviders(patientId);
    }

    else if (!hasPatient) {
      fetchedProviders = await listProviders();
    }
    setProviders(fetchedProviders);
    setFilteredCount(fetchedProviders.length);
    setIsLoading(false);
  };

  // Function to handle provider removal
  const handleRemoveProvider = async (providerId) => {
    try {
      await removeProviderFromPatient(patientId, providerId);
      message.success('Provider removed successfully');
      fetchProviders(); // Refresh the list after removing the provider
    } catch (error) {
      message.error('Failed to remove provider');
    }
  };

  const getCustomFilter = (Provider, value) => {
    const searchValue = value.replace(/\s/g, '').toLowerCase();
    const firstName = Provider.firstName ? Provider.firstName.toString().toLowerCase() : '';
    const lastName = Provider.lastName ? Provider.lastName.toString().toLowerCase() : '';
    const fullName = (firstName + lastName).replace(/\s/g, '');
    
    return {
      name: () => fullName.includes(searchValue),
    };
  };

  // Determine columns to display based on user role
  const columns = [

    {
      title: 'Provider Name',
      dataIndex: 'firstName',
      render: (text, record) => (
        <>
          {record.firstName} {record.lastName}
        </>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      render: (location) => {
        if (!location) return null
        
        const { streetAddress, streetAddress2, city, state, postalCode } = location
        const fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
        return <Tooltip title={fullAddress}>{city}, {state}</Tooltip>
      },
    }, 
    {
      title: 'Phone',
      dataIndex: 'phone',
      render: (phone) => phone || 'N/A',  // Fallback to 'N/A' if phone is missing
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      render: (providerId) => (
        <Popconfirm
          title="Are you sure you want to remove this provider?"
          onConfirm={() => handleRemoveProvider(providerId)}
          okText="Yes"
          cancelText="No"
        >
          <Button>
            <DeleteOutlined /> Remove
          </Button>
        </Popconfirm>
      ),
    },
    
  ];

  const handleProviderAdded = () => {
    fetchProviders()
  };


  return (
    <div className="admin-providers">
      <PageHeader
        title='Providers'
        count={filteredCount}
        actions={(
            <Button
              type='primary'
              onClick={() => {
                setIsAddProviderModalOpen(true); // Open the new Add Provider modal
              }}
            >
              + Add Provider
            </Button>
          )}
      />

      {/* Add Provider Form Modal */}
      <AddProviderForm
        patientId={patientId} // Pass the patient ID
        open={isAddProviderModalOpen} // Modal visibility control
        setOpen={setIsAddProviderModalOpen} // Control the modal visibility
        onSuccess={handleProviderAdded} // Callback after provider is added
      />

      <FlexibleTable
        isLoading={isLoading}
        records={providers}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        columns={columns}
      />
    </div>
  );
};
