import { Typography } from "antd";
import { StickyBlogHeader } from "../shared/stickyBlogHeader/stickyBlogHeader.component";
import { BlogData } from "../../../data/blog.data";
import { useParams } from "react-router-dom";
import classNames from "classnames";

const { Paragraph } = Typography;

export const SugarPost = () => {
  const { blogId } = useParams();

  return (
    <div className={classNames("blog-post", "seed-oil-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          {BlogData.find(b => b.id === blogId).title}
        </Paragraph>

        <Paragraph className="blog-subheading">
          {BlogData.find(b => b.id === blogId).description}
        </Paragraph>
      </div>

      <StickyBlogHeader 
        authorPhoto={BlogData.find(b => b.id === blogId).authorPhoto}
        authorName={BlogData.find(b => b.id === blogId).authorName}
        publishedAt={BlogData.find(b => b.id === blogId).publishedAt}
        readTime={BlogData.find(b => b.id === blogId).readTime}
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          The Challenge with Sugar Alternatives
        </Paragraph>

        <Paragraph className="blog-description">
          The problem with most sugar alternatives is that they either taste noticeably different from sugar or are too expensive to be practical. Take stevia, for example. It's 200–300 times sweeter than sugar but has a distinctly bitter aftertaste. Then there's saccharin, which often tastes metallic—not exactly what you want in your morning coffee.
        </Paragraph>

        <Paragraph className="blog-description">
          So, what's left? If you're looking for a healthy option that tastes almost identical to sugar, there are two solid options:
        </Paragraph>

        <Paragraph className="blog-title">
          The Best of the Best: Allulose
        </Paragraph>

        <Paragraph className="blog-description">
          If you're okay with paying a little more for a premium option, allulose is the holy grail. It's a rare sugar that tastes nearly identical to sucrose—the sugar we all love—but here's the magic: your body doesn't metabolize it like regular sugar.
        </Paragraph>

        <Paragraph className="blog-description">
          Allulose has a glycemic index of zero, meaning it won't spike your blood sugar, disrupt your insulin, or ramp up inflammation. Clinical studies demonstrate that allulose can be safely consumed without adverse metabolic effects, even in acute doses, and it may even have anti-inflammatory benefits. And unlike many alternatives, it has no weird aftertaste.
        </Paragraph>

        <Paragraph className="blog-description">
          A 2 lb (32 oz) bag costs around $25–$30. While that's pricier than table sugar, the metabolic benefits are worth every penny. If you're aiming for flavor perfection, allulose is your best bet.
        </Paragraph>

        <Paragraph className="blog-title">
          Budget-Friendly Option: Erythritol
        </Paragraph>

        <Paragraph className="blog-description">
          If you're looking for a more affordable option, erythritol is a strong contender. It's slightly less sugar-like than allulose—it can create a subtle cooling effect when used in large amounts—but its taste is clean, sweet, and far better than most alternatives. It's also widely available in grocery stores and costs around $20 for a 2.5 lb (40 oz) bag.
        </Paragraph>

        <Paragraph className="blog-description">
          Like allulose, erythritol doesn't mess with your blood sugar or insulin levels, making it a fantastic option for anyone focused on metabolic health. Research indicates that erythritol is metabolically inert and does not contribute to blood glucose spikes or systemic inflammation.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Barzilay, J., Abraham, L., Heckbert, S., Cushman, M., Kuller, L., Resnick, H., & Tracy, R. (2001). The relation of markers of inflammation to the development of glucose disorders in the elderly: the Cardiovascular Health Study. Diabetes, 50(10), 2384-9. <a onClick={() => window.open('https://doi.org/10.2337/DIABETES.50.10.2384', '_blank')}>https://doi.org/10.2337/DIABETES.50.10.2384</a>
            </li>
            <li>
              Teysseire, F., Bordier, V., Budzinska, A., Van Oudenhove, L., Weltens, N., Beglinger, C., Wölnerhanssen, B., & Meyer-Gerspach, A. (2023). Metabolic Effects and Safety Aspects of Acute D-allulose and Erythritol Administration in Healthy Subjects. Nutrients, 15. <a onClick={() => window.open('https://doi.org/10.3390/nu15020458', '_blank')}>https://doi.org/10.3390/nu15020458</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
