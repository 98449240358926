import { Tabs } from "antd"
import { useState, useEffect, useMemo, useContext } from "react"
import { CardForm } from "../cardForm/cardForm.component"
import { KlarnaForm } from "../klarnaForm/klarnaForm.component"
// import { AffirmForm } from "../affirmForm/affirmForm.component"
import { ExpressCheckoutForm } from "../applePayForm/applePayForm.component"
import "./paymentForm.scss"
import { loadStripe } from '@stripe/stripe-js';
import { UserContext } from "../../contexts/user.context"
import UserHelper from "../../helpers/user.helper"
import { CreditCardOutlined } from '@ant-design/icons'
import { cardLogos } from "../../utils/cardLogos"
import React from "react"
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const PaymentForm = ({
  onSuccess,
  onContinue,
  onNextStep,
  buttonText,
  disclaimerText,
  activeCard,
  setActiveCard,
  flow,
  hasSkip = false,
  hideButton = false,
  productTypeCode,
  step,
  isLoading,
  setIsLoading,
  setFlow
}) => {

    const [canUseApplePay, setCanUseApplePay] = useState(null);
    const [activeTab, setActiveTab] = useState('card');
    const { currentUser } = useContext(UserContext)
    const onlyCard =  UserHelper.isProvider(currentUser)

    const paymentTypes = useMemo(() => 
        onlyCard ? ['card'] : (step?.paymentTypes || ['card', 'klarna', 'applePay']),
        [step?.paymentTypes, onlyCard]
    );

    useEffect(() => {
        const checkApplePayAvailability = async () => {
            try {
                const stripe = await stripePromise;
                if (!stripe) {
                    console.error('Stripe failed to load');
                    setCanUseApplePay(false);
                    return;
                }

                const paymentRequest = stripe.paymentRequest({
                    country: 'US',
                    currency: 'usd',
                    total: {
                        label: 'Demo Payment',
                        amount: 1,
                    }
                });
                
                const result = await paymentRequest.canMakePayment();
                setCanUseApplePay(!!result?.applePay); 
            } catch (error) {
                console.error('Error checking Apple Pay availability:', error);
                setCanUseApplePay(false);
            }
        };

        checkApplePayAvailability().catch(err => {
            console.error('Error checking Apple Pay availability:', err);
            setCanUseApplePay(false);
        });
    }, []);

    useEffect(() => {
        // Check URL parameters for payment_method
        const urlParams = new URLSearchParams(window.location.search);
        const paymentMethod = urlParams.get('payment_type');
        if (paymentMethod) setActiveTab(paymentMethod);
    }, [paymentTypes]);

    const paymentComponents = useMemo(() => ({
        card: {
          key: 'card',
          label: <span className="logo card"><CreditCardOutlined /> Card</span>,
          children: (
            <div className="card-container">
              <CardForm
                onSuccess={onContinue}
                onNextStep={onNextStep}
                buttonText={buttonText}
                disclaimerText={disclaimerText}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                flow={flow}
                hasSkip={hasSkip}
                hideButton={hideButton}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />

            </div>
          ),
        },
        klarna: {
          key: 'klarna',
          label: <span>
            <span className="logo">{cardLogos.klarna && React.cloneElement(cardLogos.klarna)}</span> 
            Klarna
          </span>,
          children: (
            <div className="klarna-container">
              <KlarnaForm
                onNextStep={onNextStep}
                flow={flow}
                step={step}
                productTypeCode={productTypeCode}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setFlow={setFlow}
                onSuccess={onSuccess}
              />
            </div>
          ),
        },

    }), [onSuccess, onNextStep, buttonText, disclaimerText, activeCard, setActiveCard, flow, hasSkip, hideButton, step, productTypeCode, isLoading, setIsLoading]);
    

  return (
    <div className="payment-form">
        
        <div className="apple-pay-container" style={{display: paymentTypes.includes('applePay') && canUseApplePay ? 'block' : 'none'}}>
            <ExpressCheckoutForm
                onNextStep={onNextStep}
                flow={flow}
                productTypeCode={productTypeCode}
                onSuccess={onSuccess}
            />
            <div className="separator">
                <span>Or pay using</span>
            </div>
        </div>

       { paymentTypes.length === 1 ? 
            paymentComponents[paymentTypes[0]].children : 
            <Tabs 
                activeKey={activeTab}
                onChange={setActiveTab}
                items={paymentTypes.map(type => paymentComponents[type])}
            />
        }
    </div>
  )
} 