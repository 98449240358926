import { Button } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getProductTypeByCode } from '../../services/productType.service'
import useWidth from '../../hooks/useWidth.hook'
import Breakpoint from '../../enums/breakpoint.enum'
import { CalendarOutlined } from '@ant-design/icons'
import './confirmPackage.scss'


export const ConfirmPackage = ({ flow }) => {
  const [productTypes, setProductTypes] = useState([])
  const width = useWidth()

  useEffect(() => {
    const fetchProductTypes = async () => {
      const bundles = flow?.products?.filter(product => product.productType.isBundle && product.productType.productTypeCodes)
      
      let allProductTypes = []
      for (const bundle of bundles) {
        const bundleProductTypes = await Promise.all(
          bundle.productType.productTypeCodes.map(code => getProductTypeByCode(code))
        )
        allProductTypes.push(...bundleProductTypes)
      }
      
      setProductTypes(allProductTypes)
    }

    fetchProductTypes()
  }, [flow])

  return (
    productTypes.length > 0 && <div 
      className="package-confirmed"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >
      <div 
        className="package-confirmed-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >

        <div className="next-steps-container">
          {/* <h2 className="next-steps-title">Next Steps</h2> */}
          
          {productTypes.map((productType, index) => (
            <div key={productType.code} className="step-card">
              <div className="step-number">{index + 1}</div>
              <div className="step-content">
                <h4>{productType.title}</h4>
                <p>{productType.tagline}</p>
                <Button
                  className="schedule-button"
                  icon={<CalendarOutlined />}
                  type="default"
                  onClick={() => window.open(`/flow/${productType.flowType}`, '_blank')}
                >
                  Schedule Now
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}