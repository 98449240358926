import { Typography, Row, Col, Input, Button } from 'antd'
import { useParams, useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import { UserContext } from '../../contexts/user.context'
import './redeemGift.scss'
import { getProductTypeByCode } from '../../services/productType.service'
import { getMembershipTypeByCode } from '../../services/membershipType.service'
import { WarningOutlined } from '@ant-design/icons'
import { LoginModal } from '../loginModal/loginModal.component'
import { redeemTransfer } from '../../services/transfer.service'
import Confetti from 'react-confetti'
import ProductTypeCode from '../../enums/productTypeCode.enum'
import FlowType from '../../enums/flowType.enum'
import { getMe } from '../../services/user.service'
const { Title, Text } = Typography

export const RedeemGift = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { token, currentUser, setCurrentUser } = useContext(UserContext)
  const [productTypes, setProductTypes] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [giftCode, setGiftCode] = useState(id)
  const [isFlipped, setIsFlipped] = useState(false)
  const [gift, setGift] = useState(null)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [bundledProducts, setBundledProducts] = useState({})

  const handleRedeem = async () => {

    if (loading) {
      return
    }

    if (!token) {
      setShowLoginModal(true)
      return
    }

    if (!giftCode) {
      setError('Please enter a valid gift code.')
      return
    }

    setLoading(true)
    try {
      const response = await redeemTransfer(giftCode)

      if (response.success) {
        const transferData = response.transfer
        const productTypesData = await Promise.all(
          (transferData.productTypeCodes || []).map(code => getProductTypeByCode(code))
        )
        
        const membershipTypesData = await Promise.all(
          (transferData.membershipTypeCodes || []).map(code => getMembershipTypeByCode(code))
        )

        const bundledProductsData = {}
        for (const product of [...productTypesData, ...membershipTypesData]) {
          if (product.isBundle && product.productTypeCodes) {
            const bundleItems = await Promise.all(
              product.productTypeCodes.map(code => getProductTypeByCode(code))
            )
            
            if (product.productTypeCodes.includes(ProductTypeCode.LONGEVITY_PANEL) && 
                transferData.productTypeCodes.includes(ProductTypeCode.MOBILE_BLOOD_DRAW)) {             
              const longevityPanel = bundleItems.find(item => item.code === ProductTypeCode.LONGEVITY_PANEL);
              if (longevityPanel) {
                longevityPanel.title = `${longevityPanel.title} with At-Home Blood Draw`;
              }
            }
            
            bundledProductsData[product.code] = bundleItems
          }
        }
        setBundledProducts(bundledProductsData)
        
        setGift(transferData)
        setProductTypes(mergeProductTypes([...productTypesData, ...membershipTypesData]))
        setIsFlipped(true)
        setError(null)
        setCurrentUser(await getMe({ select: 'cashBalance, credits' }))
        
      } else {
        setError(<><WarningOutlined /> {response.message}</>)
      }
    } catch (err) {
      setError(<><WarningOutlined /> Invalid gift code. Please try again.</>)
    } finally {
      setLoading(false)
    }
  }


  const mergeProductTypes = (productTypes) => {
    if (!productTypes) return [];
    
    const hasMobileBloodDraw = productTypes.some(pt => pt.code === ProductTypeCode.MOBILE_BLOOD_DRAW);
    
    // Create a new array to avoid mutating the input
    let mergedProducts = [...productTypes];

    // Handle bundles first
    mergedProducts = mergedProducts.map(product => {
      if (product.isBundle && product.productTypeCodes?.includes(ProductTypeCode.LONGEVITY_PANEL) && hasMobileBloodDraw) {
        // Add mobile blood draw to bundle that contains longevity panel
        return {
          ...product,
          productTypeCodes: [...product.productTypeCodes, ProductTypeCode.MOBILE_BLOOD_DRAW]
        };
      }
      return product;
    });

    // Find standalone Longevity Panel (not in any bundle)
    const isLongevityPanelInBundle = mergedProducts.some(product => 
      product.isBundle && product.productTypeCodes?.includes(ProductTypeCode.LONGEVITY_PANEL)
    );
    
    const standaloneLongevityPanel = mergedProducts.find(pt => 
      pt.code === ProductTypeCode.LONGEVITY_PANEL && !isLongevityPanelInBundle
    );

    if (standaloneLongevityPanel && hasMobileBloodDraw) {
      // Remove individual Longevity Panel and Mobile Blood Draw
      mergedProducts = mergedProducts.filter(pt => 
        pt.code !== ProductTypeCode.LONGEVITY_PANEL && 
        pt.code !== ProductTypeCode.MOBILE_BLOOD_DRAW
      );

      // Add combined product
      mergedProducts.push({
        ...standaloneLongevityPanel,
        title: 'Comprehensive Blood Panel with At-Home Blood Draw',
        flowType: FlowType.LONGEVITY_TEST,
        isBundle: true,
        productTypeCodes: [ProductTypeCode.LONGEVITY_PANEL, ProductTypeCode.MOBILE_BLOOD_DRAW]
      });
    }


    // Remove individual products that are part of any bundle
    return mergedProducts.filter(product => {
      const isPartOfBundle = mergedProducts.some(bundle => 
        bundle.isBundle && bundle.productTypeCodes?.includes(product.code)
      );
      return !isPartOfBundle || product.isBundle;
    });
  };


  const handleGetStarted = () => {
    if (!token) {
      setShowLoginModal(true)
      return
    }

    const product = productTypes?.find(pt => pt)
    if (product?.path) {
      navigate(product.path)
    } else if (product?.flowType) {
      navigate(`/flow/${product.flowType}`)
    } else {
      navigate('/')
    }
  }

  const handleLoginSuccess = async () => {
    setShowLoginModal(false)
    handleRedeem()
  }

  return (
    <div className="redeem-gift-page">
      <LoginModal 
        open={showLoginModal}
        onCancel={() => setShowLoginModal(false)}
        onLoginSuccess={handleLoginSuccess}
      />
      <div className={`gift-container`}>
        <div className="gift-card-container">

            <Row className="gift-content" gutter={[48, 0]}>
              <Col xs={24} md={12} className="gift-text">
                <div className="gift-text-content">
                  <Title level={1}>
                    Let's redeem your gift
                  </Title>
                  <Text className="gift-subtitle">
                    Enter your code to unlock your gift:
                  </Text>
                  <Input
                    size="large"
                    placeholder="Enter gift code"
                    defaultValue={id}
                    className="gift-code-input"
                    status={error ? 'error' : ''}
                    onChange={(e) => setGiftCode(e.target.value)}
                    value={giftCode}
                  />
                  {error && <Text className="error-message">{error}</Text>}
                  <Button 
                    type="default"
                    size="large"
                    className="redeem-button"
                    onClick={handleRedeem}
                    loading={loading}
                  >
                    Redeem Gift
                  </Button>
                </div>
              </Col>
              <Col xs={24} md={12} className="gift-image">
                <div className={`gift-card ${isFlipped ? 'flipped' : ''}`}>
                  <div className="card-front">
                    {!isFlipped && (
                      <img src="/img/giftcard.png" alt="Gift card" />
                    )}
                  </div>

                  {isFlipped && (
                    <Confetti
                      recycle={false}
                      numberOfPieces={200}
                      gravity={0.3}
                    />
                  )}

                  
                  <div className="card-back"> 
                    {productTypes && (
                      <Row className="product-content">
                        <Col span={24} className="product-details">
                          <div className="gift-box">
                            <Title level={3} className="gift-title">
                              🎉 You've received:
                            </Title>
                            {productTypes.map((product, index) => (
                              <div key={index} className="product-item">
                                <Title level={2} className="product-name">
                                  {product.title}{product.isAnnual ? ' (1 Year)' : ''}
                                </Title>
                                <div className="product-items-container">
                                  {product.isBundle && bundledProducts[product.code] ? (
                                    <div className="bundle-items">
                                      {bundledProducts[product.code].map((item, idx) => (
                                        <div key={idx} className="bundle-item">
                                          <Text strong className="bundle-item-title">{item.title}</Text>
                                          {item.tagline && (
                                            <Text className="bundle-item-tagline">
                                              {item.tagline}
                                            </Text>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <>
                                      <Text className="tagline gift-message">
                                        {product.tagline}
                                      </Text>
                                      {product.photos?.[0]?.webp && (
                                        <div className="gift-image-wrapper">
                                          <img 
                                            src={product.photos[0].webp} 
                                            alt={product.title} 
                                            className="product-image"
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                                  {index < productTypes.length - 1 && (
                                    <div className="product-divider" />
                                  )}
                                </div>
                              </div>
                            ))}

                            {(gift?.reason || gift?.senderName) && (
                              <div className="gift-note">
                                {gift?.reason && (
                                  <Text className="gift-message">
                                    "{gift.reason.split('\n').map((line, i) => (
                                      <span key={i}>
                                        {line}
                                        {i < gift.reason.split('\n').length - 1 && <br />}
                                      </span>
                                    ))}"
                                  </Text>
                                )}
                                {gift?.senderName && (
                                  <Text className="gift-sender">
                                    From: {gift.senderName}
                                  </Text>
                                )}
                              </div>
                            )}
                            <Button 
                              type="primary" 
                              size="large"
                              className="get-started-button"
                              onClick={handleGetStarted}
                            >
                              Get Started
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
        

        </div>
      </div>
    </div>
  )
}

