import React, { useRef, useState, useEffect } from 'react'
import { Modal, Form, Input, Select, message, Tag, Space, Button, Dropdown } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import { addProvider, getProvider, updateProvider } from '../../services/provider.service';
import "./providerForm.scss"
import Permission from '../../enums/permission.enum';
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper';
import PhoneInput from '../phoneInput/phoneInput.component';
import { listFacilities } from '../../services/facility.service'
import { PlusOutlined } from '@ant-design/icons';

const { Item } = Form

export const ProviderForm = ({ open, setOpen, onSuccess, providerId, select, populate=[] }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt
  const [facilities, setFacilities] = useState([])
  const [selectedFacilities, setSelectedFacilities] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (open) {
      fetchFacilities()
      if (providerId) setProviderFields()
    } else {
      form.resetFields()
      setHasAttempt(false)
    }
  }, [open])

  const fetchFacilities = async () => {
    try {
      const facilities = await listFacilities({sort: 'name'})
      setFacilities(facilities.map(f => ({
        label: f.name,
        value: f._id
      })))
    } catch (err) {
      message.error('Failed to load facilities')
    }
  }

  const onCancel = () => {
    setOpen(false)
  }
  
  const onFail = () => {
    setHasAttempt(true)
    message.error('Enter valid provider data')
  }

  const setProviderFields = async () => {
    const provider = await getProvider(providerId, {
      select: 'firstName lastName email phone npi slack permissions facilities',
      populate: ['facilities']
    });

    form.setFieldsValue({
      firstName: provider.firstName,
      lastName: provider.lastName,
      email: provider.email,
      phone: provider.phone,
      npi: provider.npi,
      slack: provider.slack,
      permissions: provider.permissions,
    });

    if (provider.facilities) {
      console.log(provider.facilities)
      const facilityObjects = provider.facilities.map(f => ({
        label: f.name,
        value: f._id
      }));
      setSelectedFacilities(facilityObjects);
    }
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const {
        firstName,
        lastName,
        email,
        phone,
        npi,
        slack,
        permissions,
        facilities,
      } = form.getFieldsValue()
      
      const params = {
        fields: {
          firstName,
          lastName,
          email,
          phone,
          npi,
          slack,
          permissions,
          facilities,
        },
        select,
        populate
      }
      const response = providerId ? await updateProvider(providerId, params) : await addProvider(params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(providerId ? 'Provider updated' : 'Provider added')
      setOpen(false)
      if (!providerId) {
        form.resetFields()
      }
    } catch (err) {
      let msg = providerId ? 'Failed to update provider' : 'Failed to add provider'
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email'
      }
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  const handleAddFacility = (facility) => {
    if (!selectedFacilities.find(f => f.value === facility.value)) {
      const newFacilities = [...selectedFacilities, facility];
      setSelectedFacilities(newFacilities);
      form.setFieldValue('facilities', newFacilities.map(f => f.value));
    }
  };

  const handleRemoveFacility = (facilityToRemove) => {
    const newFacilities = selectedFacilities.filter(f => f.value !== facilityToRemove.value);
    setSelectedFacilities(newFacilities);
    form.setFieldValue('facilities', newFacilities.map(f => f.value));
  };


  return (
    
      <Modal 
        open={open} 
        title={providerId ? 'Edit Provider' : 'Add Provider'}
        onCancel={onCancel}
        footer={null}
        className="provider-modal"
      >
        <Form
          form={form}
          onFinish={onOk}
          onFinishFailed={onFail}
          layout='vertical'
          className="provider-form"
        >
          <div style={{ display: 'flex', gap: '16px' }}>
            <Item 
              label="First Name"
              name="firstName"
              rules={[RuleHelper.isRequired]}
              validateTrigger='onSubmit'
              style={{ flex: 1 }}
            >
              <Input 
                placeholder="First Name" 
                onChange={() => {
                  if (hasAttemptRef.current) FormHelper.fetchHasError(form)
                }}
              />
            </Item>

            <Item 
              label="Last Name"
              name="lastName"
              rules={[RuleHelper.isRequired]}
              validateTrigger='onSubmit'
              style={{ flex: 1 }}
            >
              <Input 
                placeholder="Last Name" 
                onChange={() => {
                  if (hasAttemptRef.current) FormHelper.fetchHasError(form)
                }}
              />
            </Item>
          </div>

          <Item 
            label="Email Address"
            name="email"
            onInput={e => e.target.value = e.target.value.toLowerCase()}
            rules={[
              // RuleHelper.isRequired,
              RuleHelper.isEmail,
              RuleHelper.isUniqueEmail({
                fieldName: 'email',
                userId: providerId,
              })
            ]}
            validateTrigger='onSubmit'
          >
            <Input 
              placeholder="Email Address" 
              onChange={() => {
                if (hasAttemptRef.current) {
                  FormHelper.fetchHasError(form)
                }
              }}
            />
          </Item>

          <Item 
            label="Phone Number"
            name="phone"
            rules={[
              // RuleHelper.isRequired,
              RuleHelper.isPhone,
            ]}
            validateTrigger='onSubmit'
          >
            <PhoneInput 
              onChange={() => {
                if (hasAttemptRef.current) {
                  FormHelper.fetchHasError(form)
                }
              }}
            />
          </Item>


          <Item 
            label="NPI"
            name="npi"
          >
            <Input placeholder="NPI" />
          </Item>

          <Item
            label="Facilities"
            name="facilities"
          >
            <Space wrap size={8} style={{ width: '100%' }}>
              {selectedFacilities.map(facility => (
                <Tag 
                  key={facility.value}
                  closable
                  onClose={() => handleRemoveFacility(facility)}
                  style={{ margin: 0 }}
                >
                  {facility.label}
                </Tag>
              ))}
              <Dropdown
                open={dropdownOpen}
                onOpenChange={setDropdownOpen}
                trigger={['click']}
                dropdownRender={() => (
                  <div style={{ 
                    background: 'white', 
                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                    borderRadius: '4px',
                    padding: '4px'
                  }}>
                    <Select
                      style={{ width: '250px' }}
                      showSearch
                      placeholder="Search facilities"
                      options={facilities.filter(f => !selectedFacilities.find(sf => sf.value === f.value))}
                      filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={(value, option) => {
                        handleAddFacility(option);
                        setDropdownOpen(false);
                      }}
                    />
                  </div>
                )}
              >
                <Button type="dashed" icon={<PlusOutlined />} size="small">
                  Add Facility
                </Button>
              </Dropdown>
            </Space>
          </Item>
          
          <Item 
            label="Slack"
            name="slack"
          >
            <Input placeholder="Slack" />
          </Item>
          
          <Item 
            label="Permissions"
            name='permissions'
          >
            <Select
              mode="tags"
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
              options={Object.values(Permission).map(value => {
                return {
                  label: value,
                  value,
                }
              })}
            />
          </Item>


          <ContinueButton
            text='Save Provider'
            isSubmitting={isSubmitting}
          />
        </Form>
      </Modal>
   
  )
}