import LatestPosts from "../latestPosts/latestPosts.component.js"
import Panels from '../panels/panels.component.js'

export const ConfirmRequest = () => {

  return (
    <div className="confirm-step">

      <Panels />

      <LatestPosts />
    </div>
  )
}