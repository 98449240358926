import Role from '../enums/role.enum.js'
import ProductTypeCode from "../enums/productTypeCode.enum.js"
import PlanCode from "../enums/planCode.enum.js"
import MembershipTypeCode from "../enums/membershipTypeCode.enum.js"
import { getMe } from '../services/user.service'

const UserHelper = {
  isAdmin: (user) => {
    return user?.role === Role.ADMIN
  },
  isPatient: (user) => {
    return user?.role === Role.PATIENT
  },
  isPhlebotomist: (user) => {
    return user?.role === Role.PHLEBOTOMIST
  },
  isProvider: (user) => {
    return user?.role === Role.PROVIDER
  },
  isStaff: (user) => {
    return user?.role === Role.STAFF
  },
  hasCredit: (user, productType) => {
    if (!user?.credits) return false;
  
    const creditDict = {
      [ProductTypeCode.LONGEVITY_PANEL]: [ProductTypeCode.LONGEVITY_PANEL],
      [ProductTypeCode.ATHLETE_PANEL]: [PlanCode.ATHLETE, ProductTypeCode.ATHLETE_PANEL],
      [ProductTypeCode.CONSULT_LONGEVITY]: [ProductTypeCode.CONSULT_LONGEVITY],
      [ProductTypeCode.CONSULT_HEART_HEALTH]: [ProductTypeCode.CONSULT_HEART_HEALTH],
      [ProductTypeCode.CONSULT_METABOLIC_HEALTH]: [ProductTypeCode.CONSULT_METABOLIC_HEALTH],
      [ProductTypeCode.WATCHPAT_ONE]: [ProductTypeCode.WATCHPAT_ONE],
      [ProductTypeCode.OMEGA_3_ADDON]: [ProductTypeCode.OMEGA_3_ADDON],
      [ProductTypeCode.APOE_ADDON]: [ProductTypeCode.APOE_ADDON],
      [ProductTypeCode.OMEGACHECK]: [ProductTypeCode.OMEGACHECK],
      [ProductTypeCode.HEAVY_METALS]: [ProductTypeCode.HEAVY_METALS],
      [ProductTypeCode.GALLERI]: [ProductTypeCode.GALLERI],
      [ProductTypeCode.MOBILE_BLOOD_DRAW]: [ProductTypeCode.MOBILE_BLOOD_DRAW],
      [ProductTypeCode.PRENUVO]: [ProductTypeCode.PRENUVO],
      [ProductTypeCode.FERTILITY]: [ProductTypeCode.FERTILITY],
      [ProductTypeCode.TESTOSTERONE_PANEL_KIT]: [ProductTypeCode.TESTOSTERONE_PANEL_KIT],
      [ProductTypeCode.HEART_HEALTH_TEST_KIT]: [ProductTypeCode.HEART_HEALTH_TEST_KIT],
      [MembershipTypeCode.LONGEVITY_12MO]: [MembershipTypeCode.LONGEVITY_12MO],
      [MembershipTypeCode.PREMIUM_12MO]: [MembershipTypeCode.PREMIUM_12MO],
      [ProductTypeCode.WATCHPAT_ONE]: [ProductTypeCode.WATCHPAT_ONE],
      [ProductTypeCode.CUSTOM_PANEL]: [ProductTypeCode.CUSTOM_PANEL],
      [ProductTypeCode.HEART_HEALTH_GENETIC_TEST]: [ProductTypeCode.HEART_HEALTH_GENETIC_TEST],
      [ProductTypeCode.CTCALCIUM]: [ProductTypeCode.CTCALCIUM],
      [ProductTypeCode.DEXA_BODYCOMP]: [ProductTypeCode.DEXA_BODYCOMP],
      
    };
  
    // Handle array of product types
    if (Array.isArray(productType)) {
      return productType.every(type => {
        return creditDict[type]
          ? Array.isArray(creditDict[type])
            ? creditDict[type].some(credit => user.credits.includes(credit))
            : user.credits.includes(creditDict[type])
          : false;
      });
    }
    
    // Handle single product type (existing logic)
    return creditDict[productType]
      ? Array.isArray(creditDict[productType])
        ? creditDict[productType].some(credit => user.credits.includes(credit))
        : user.credits.includes(creditDict[productType])
      : false;
  },
  hasPartialCredit: (user, productTypes) => {
    if (!user?.credits) return false;

    if (!Array.isArray(productTypes)) return false

    for (const productType of productTypes) {
      if (UserHelper.hasCredit(user, productType)) {
        return true
      }
    }

    return false
  },
  refreshUser: async (user) => {
    const response = await getMe({
      select: '_id role credits cashBalance email firstName lastName tracker location isShareable isAthlete permissions chargePersonalCard customer memberships',
      populate: [{
        path: 'memberships',
        select: 'status startAt endAt monthlyPrice price stripeId card membershipType',
      }]
    })
    return response
  },
  getName: (user) => {
    if (!user) return null
    if (user.npi) return `Dr. ${user.firstName} ${user.lastName}`
    return `${user.firstName} ${user.lastName}`
  }


}

export default UserHelper