
import { getFileById} from "../../services/file.service";
import { message, Button } from "antd";
import { useState, useEffect } from "react";
import { EyeOutlined } from '@ant-design/icons';

const FileLink = ({ fileId, showLink=true, showButton=false }) => {

    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null)

    useEffect(() => {
        fetchFile(fileId)
    }, [fileId])

    const fetchFile = async (fileId) => {
        try {
            const fileReponse = await getFileById(fileId);
            setFile(fileReponse.data)
        } catch (error) {
            message.error('Failed to fetch file');
        }
    }

    const viewFile = async (fileId) => {
        try {
            setLoading(true)
            const fileReponse = await getFileById(fileId);
            setFile(fileReponse.data)
            window.open(fileReponse.data.link, '_blank');
        } catch (error) {
            message.error('Failed to open file');
        }
    };


    return file && (
        <>
            {showLink && <a href="#" className="link" onClick={() => viewFile(fileId)}>{file?.fileName}</a>}
            {showButton && <Button type="default" icon={<EyeOutlined />} onClick={() => viewFile(fileId)}>{file?.fileName}</Button>}
        </>
    )
}

export default FileLink;