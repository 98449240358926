import { Form, Button, Row, Col, Radio, Tabs, Image, Spin, Popover } from "antd"
import "./giftSelect.scss"
import { WarningFilled, } from '@ant-design/icons'
import { useState, useEffect, useRef, useContext } from "react"
import FormHelper from "../../helpers/form.helper"
import RuleHelper from "../../../../helpers/rule.helper"
import dayjs from 'dayjs'
import { listProductTypes } from "../../../../services/productType.service"
import ProductTypeCode from "../../../../enums/productTypeCode.enum"
import "../singleSelect/singleSelect.scss" // Import the single select styles
import { UserContext } from "../../../../contexts/user.context"
import { CheckCircleOutlined } from '@ant-design/icons'
const { Item } = Form


const giftCategories = {
  tests: [
   ProductTypeCode.GIFT_LONGEVITY_PANEL,  
   ProductTypeCode.GIFT_GALLERI,
   ProductTypeCode.GIFT_HEART_HEALTH_PANEL,
   ProductTypeCode.GIFT_FERTILITY_PANEL,
   ProductTypeCode.GIFT_TESTOSTERONE_PANEL,
   ProductTypeCode.GIFT_TRUAGE_COMPLETE,
   ProductTypeCode.GIFT_SLEEP_APNEA_TEST,
  ],
  giftCards: [
    ProductTypeCode.GIFT_CARD,
    ProductTypeCode.GIFT_CARD_50,
    ProductTypeCode.GIFT_CARD_100,
    ProductTypeCode.GIFT_CARD_250,
    ProductTypeCode.GIFT_CARD_500,
    ProductTypeCode.GIFT_CARD_1000,
  ],
  bundles: [
    ProductTypeCode.GIFT_BUNDLE_ESSENTIALS_JR,
    ProductTypeCode.GIFT_BUNDLE_LONGEVITY,
  ]
}


export const GiftSelect = ({
  step,
  onSubmit,
  skeleton,
  form,
  isSubmitting,
  hasAttempt,
  flow,
}) => {
  const [rules, setRules] = useState({})
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt
  const [productTypes, setProductTypes] = useState()
  const { currentUser } = useContext(UserContext)

  const [activeTab, setActiveTab] = useState(() => {
    if (flow?.giftProductTypeCodes?.[0]) {
      // Find which category contains the product code
      if (giftCategories?.giftCards?.some(item => item === flow.giftProductTypeCodes[0])) {
        return 'giftCards';
      }
      if (giftCategories?.tests?.some(item => item === flow.giftProductTypeCodes[0])) {
        return 'tests';
      }
      if (giftCategories?.bundles?.some(item => item === flow.giftProductTypeCodes[0])) {
        return 'bundles';
      }
    }
    return 'bundles'; // Default tab
  });
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    fetchRules()
  }, [step])

  useEffect(() => {
    fetchProductTypes()

    const keyDownHandler = event => {
      if (event.target.classList.contains('__PrivateStripeElement-input')) {
        return;
      }
      
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };
    
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
      form.resetFields();
    };
  }, []);

  useEffect(() => {
    if (flow?.giftProductTypeCodes) {
      form.setFieldValue('giftProductTypeCodes', flow?.giftProductTypeCodes[0]);
    }
  }, [flow])

  useEffect(() => {
    if (!productTypes) return;
    
    setImagesLoaded(false);
    
    const imageUrls = productTypes
      .filter(pt => pt?.photos?.length > 0)
      .map(pt => pt.photos[0].webp);

    if (imageUrls.length === 0) {
      setImagesLoaded(true);
      return;
    }

    const imagePromises = imageUrls.map(url => {
      return new Promise((resolve) => {
        const img = new Image();
        
        // Set a timeout to resolve after 5 seconds if image hasn't loaded
        const timeoutId = setTimeout(() => {
          resolve();
        }, 5000);

        img.onload = () => {
          clearTimeout(timeoutId);
          resolve();
        };

        img.onerror = () => {
          clearTimeout(timeoutId);
          resolve();
        };

        img.src = url;
      });
    });

    Promise.all(imagePromises)
      .then(() => {
        setImagesLoaded(true);
      })
      .catch(() => {
        setImagesLoaded(true);
      });

    // Cleanup timeout if component unmounts
    return () => setImagesLoaded(false);
  }, [productTypes]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const selectedGift = getProductType(values.giftProductTypeCodes);
      if (selectedGift) {
        onSubmit(values);
      }
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.GIFT_GALLERI,
            ProductTypeCode.GIFT_LONGEVITY_PANEL,
            ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW,
            ProductTypeCode.GIFT_TESTOSTERONE_PANEL,
            ProductTypeCode.GIFT_FERTILITY_PANEL, 
            ProductTypeCode.GIFT_HEART_HEALTH_PANEL,
            ProductTypeCode.GIFT_CORE_MEMBERSHIP,
            ProductTypeCode.GIFT_PREMIUM_MEMBERSHIP,
            ProductTypeCode.GIFT_SLEEP_APNEA_TEST,  
            ProductTypeCode.GIFT_CARD_50,
            ProductTypeCode.GIFT_CARD_100,
            ProductTypeCode.GIFT_CARD_250,
            ProductTypeCode.GIFT_CARD_500,
            ProductTypeCode.GIFT_CARD_1000,
            ProductTypeCode.GIFT_TRUAGE_COMPLETE,
            ProductTypeCode.GIFT_BUNDLE_LONGEVITY,
            ProductTypeCode.LONGEVITY_PANEL,
            ProductTypeCode.CTCALCIUM,
            ProductTypeCode.CONSULT_LONGEVITY,
            ProductTypeCode.MOBILE_BLOOD_DRAW,
            ProductTypeCode.GIFT_CARD,
            ProductTypeCode.GIFT_BUNDLE_ESSENTIALS,
            ProductTypeCode.GIFT_BUNDLE_ESSENTIALS_JR,
            ProductTypeCode.DEXA_BODYCOMP,
            ProductTypeCode.VO2MAX,
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType?.code === code)
  }

  const fetchRules = () => {
    setRules({
      giftProductTypeCodes: [
        { 
          ...RuleHelper.isRequired, 
          message: <><WarningFilled />&nbsp; Please select an option</> 
        }
      ]
    });
  }

  const renderField = (field) => {

    return (
      <Radio.Group
        value={form.getFieldValue('giftProductTypeCodes')}
        onChange={(e) => {
          form.setFieldValue('giftProductTypeCodes', e.target.value);
          if (hasAttemptRef.current) {
            FormHelper.fetchHasError(form)
          }
          handleSubmit();           
        }}
      >
        <Row gutter={[18, 18]}>
          {field.options.map(({ value, title, tagline, price, isBundle, productTypeCodes }) => (
            <Col xs={24} sm={12}  key={value} className="product-grid-item">
              <Radio 
                value={value} 
                className={Array.isArray(form.getFieldValue('giftProductTypeCodes')) 
                  ? form.getFieldValue('giftProductTypeCodes')?.includes(value) ? 'selected' : ''
                  : form.getFieldValue('giftProductTypeCodes') === value ? 'selected' : ''
                }
                checked={Array.isArray(form.getFieldValue('giftProductTypeCodes')) 
                  ? form.getFieldValue('giftProductTypeCodes')?.includes(value)
                  : form.getFieldValue('giftProductTypeCodes') === value
                }
                onClick={() => {
                  // Always trigger submit when clicked, even if already selected
                  handleSubmit();
                }}
              >
                <div className="product-select-label">
                  {getProductType(value)?.photos.length > 0 && (
                    <div className="option-image">
                      <Image
                        src={getProductType(value)?.photos[0]?.webp}
                        alt={title}
                        preview={false}
                        placeholder={
                          <div style={{ 
                            backgroundColor: '#f5f5f5',
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                            <Spin />
                          </div>
                        }
                      />
                    </div>
                  )}
                  <div className={`option-title-container ${activeTab}`}>
                    <div className="option-title">{title}</div>
                    {isBundle ? 
                      <div className="gift-bundle-description">
                      <div className="option-description">{getProductType(value)?.tagline}</div>

                        {productTypeCodes.map(pt => {
                          const product = getProductType(pt);
                          return (
                            <div key={pt} className="bundle-item">
                              {/* <CheckCircleFilled />  */}
                              <CheckCircleOutlined/>
                              <Popover 
                                content={
                                  <div className="bundle-item-description">
                                    {/* <h4>{product?.title}</h4> */}
                                    <p>{product?.tagline}</p>
                                  </div>
                                }
                                title={null}
                                trigger="hover"
                                placement="right"
                              >
                                <span className="bundle-item-title">{product?.title}</span>
                              </Popover>
                            </div>
                          );
                        })}
                      </div> 
                      :
                      <div className="option-description">{tagline}</div>
                    }
                  </div>
                  <div className="price-button-container">
                    <div className="option-price">${price}</div>
                  </div>
                </div>
              </Radio>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    )}

  return (
    <div className="gift">
      {!imagesLoaded ? (
        <div className="gift-loading">
          <Spin size="large" />
          <div style={{ marginTop: 16 }}>Loading gift options...</div>
        </div>
      ) : (
        <Form
          form={form}
          className="multiple-input-form"
          layout="vertical"
          initialValues={{
            giftActivationDate: dayjs(),
            ...(currentUser?.firstName && currentUser?.lastName && {
              giftSenderName: `${currentUser.firstName} ${currentUser.lastName}`,
              ...(currentUser?.email && {
                giftSenderEmail: currentUser.email
              })
            })
          }}
        >
          <Tabs
            activeKey={activeTab}
            onChange={setActiveTab}
            items={[
              {
                key: 'bundles',
                label: 'Gift Packages',
                children: (
                  <div className="field-container selector-container">
                    <Item
                      name="giftProductTypeCodes"
                      className="single-select-item"
                      rules={rules['giftProductTypeCodes']}
                      validateTrigger='onSubmit'
                    >
                      {renderField({ options: giftCategories.bundles.map(bundle => ({
                        title: getProductType(bundle)?.title,
                        value: getProductType(bundle)?.code,
                        price: getProductType(bundle)?.cost,
                        tagline: getProductType(bundle)?.tagline,
                        isBundle: getProductType(bundle)?.isBundle,
                        productTypeCodes: getProductType(bundle)?.productTypeCodes
                      })) })}
                    </Item>
                  </div>
                ),
              },
              {
                key: 'giftCards',
                label: 'Gift Cards',
                children: (
                  <div className="field-container selector-container">
                    <Item
                      name="giftProductTypeCodes"
                      className="single-select-item"
                      rules={rules['giftProductTypeCodes']}
                    >
                      <Radio.Group
                        value={form.getFieldValue('giftProductTypeCodes')}
                        onChange={(e) => {
                          form.setFieldValue('giftProductTypeCodes', e.target.value);
                          if (hasAttemptRef.current) {
                            FormHelper.fetchHasError(form)
                          }
                          handleSubmit();           
                        }}
                      >
                        <Row gutter={[18, 18]}>
                          <Col xs={24} sm={12} className="product-grid-item">

                            <div className="item-container">
                              <div className="product-select-label">
                                {getProductType(ProductTypeCode.GIFT_CARD)?.photos.length > 0 && (
                                  <div className="option-image">
                                    <Image
                                      src={getProductType(ProductTypeCode.GIFT_CARD)?.photos[0]?.webp}
                                      alt="Gift Card"
                                      preview={false}
                                      placeholder={
                                        <div style={{ 
                                          backgroundColor: '#f5f5f5',
                                          height: '100%',
                                          width: '100%',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center'
                                        }}>
                                          <Spin />
                                        </div>
                                      }
                                    />
                                  </div>
                                )}
                                <div className="option-title-container giftCards">
                                  <div className="option-title">Instalab Gift Card</div>
                                  <div className="option-description">Select an amount below</div>
                                </div>
                                <div className="price-button-container gift-card">
                                  <Radio.Group 
                                    onChange={(e) => {
                                      form.setFieldValue('giftProductTypeCodes', e.target.value);
                                      if (hasAttemptRef.current) {
                                        FormHelper.fetchHasError(form);
                                      }
                                      handleSubmit();
                                    }}
                                    value={form.getFieldValue('giftProductTypeCodes')}
                                  >
                                    {[
                                      ProductTypeCode.GIFT_CARD_50,
                                      ProductTypeCode.GIFT_CARD_100,
                                      ProductTypeCode.GIFT_CARD_250,
                                      ProductTypeCode.GIFT_CARD_500,
                                      ProductTypeCode.GIFT_CARD_1000
                                    ].map(code => (
                                      <Radio.Button key={code} value={code}>
                                        ${getProductType(code)?.cost}
                                      </Radio.Button>
                                    ))}
                                  </Radio.Group>
                                </div>
                              </div>
                              </div>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </Item>
                  </div>
                ),
              },
              {
                key: 'tests',
                label: 'Individual Tests',
                children: (
                  <div className="field-container selector-container">
                    <Item
                      name="giftProductTypeCodes"
                      className="single-select-item"
                      rules={rules['giftProductTypeCodes']}
                      validateTrigger='onSubmit'
                    >
                      {renderField({ options: giftCategories.tests.map(test => ({
                        title: getProductType(test)?.title,
                        value: getProductType(test)?.code,
                        price: getProductType(test)?.cost,
                        tagline: getProductType(test)?.tagline,
                      })) })}
                    </Item>
                  </div>
                ),
              },

            ]}
          />

          {/* <FlowStepFooter 
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            buttonText="Continue"
            
          /> */}
        </Form>
      )}
    </div>
  )
}