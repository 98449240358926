import PrescriptionStatus from "../enums/prescriptionStatus.enum"

const PrescriptionHelper = {
  getPausedAt: (prescription) => {
    const pausedActivity = prescription.activity?.find(({ status }) => status === PrescriptionStatus.PAUSED);
    const pausedAt = pausedActivity?.createdAt;
    return pausedAt
  },
}

export default PrescriptionHelper