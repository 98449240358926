import {
  CalendarOutlined,
  UserOutlined,
  DollarCircleOutlined,
  TableOutlined,
  FileSearchOutlined,
  ExperimentOutlined,
  ReadOutlined,
  LoginOutlined,
  GiftOutlined,
  LogoutOutlined,
  EditOutlined,
  ShopOutlined,
  ProfileOutlined,
  HomeOutlined,
  MessageOutlined,
  FileDoneOutlined,
  CheckSquareOutlined,  
  BankOutlined,
  SendOutlined,
  HeartOutlined,
  SettingOutlined,
  StarOutlined,
  VideoCameraOutlined,
  PlusCircleOutlined,
  FileOutlined,
  ToolOutlined,
  CustomerServiceOutlined
} from '@ant-design/icons'
import { ReactComponent as TubeOutlined } from '../assets/svg/blood-vial.svg'
import { ReactComponent as DoctorOutlined } from "../assets/svg/doctor.svg"
import FlowType from '../enums/flowType.enum'
import Role from '../enums/role.enum';
import Permission from '../enums/permission.enum';
import FrontChatHelper from './fronchat.helper';
import MembershipHelper from './membership.helper'
import { Tag } from 'antd';
const LayoutHelper = {

  getMenuItems: (counts, currentUser, membership, navigate, consults) => {
    if (!counts) return

    FrontChatHelper.identifyUser(currentUser);


    if (currentUser?.role === Role.ADMIN) {

      const apptItems = [
        {
          key: 'calendar',
          label: (
            <>
              - Master Calendar
              <div className="menu-item-count">{counts.futureAppointments?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/calendar'],
          onClick:() => navigate('/admin/calendar')
        },
        {
          key: 'appointments',
          label: (
            <>
              - Appointments
              <div className="menu-item-count">{counts.draws?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/appointments'],
          onClick:() => navigate('/admin/appointments')
        },       
        // {
        //   key: 'Longevity-Consults',
        //   label: (
        //     <>
        //       - Consultations
        //       <div className="menu-item-count">{counts.consults?.toLocaleString()}</div>
        //     </>
        //   ),
        //   urls: ['/admin/consults'],
        //   onClick:() => navigate('/admin/consults')
        // }, 
        {
          key: 'Lab Orders',
          label: (
            <>
              - Lab Orders
              <div className="menu-item-count">{counts.orders?.toLocaleString()}</div>
            </>
          ),
          // icon: <ProfileOutlined />,
          urls: ['/admin/orders'],
          onClick:() => navigate('/admin/orders')
        }, 
 
        {
          key: 'Events',
          label: (
            <>
              - Events
              <div className="menu-item-count">{counts.events?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/events'],
          onClick:() => navigate('/admin/events')
        }, 
      ]

      const userItems = [
        { 
          key: 'Patients', 
          urls: ['/', '/admin/patients'],
          onClick: () => navigate('/'),
          label: (
          <>
            - Patients
            <div className="menu-item-count">{counts.patients?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Members', 
          urls: ['/admin/members'],
          onClick: () => navigate('/admin/members'),
          label: (
          <>
            - Members
            <div className="menu-item-count">{counts.members?.toLocaleString()}</div>
          </>
          )
        },
       
        { 
          key: 'Phlebotomists', 
          urls: ['/admin/phlebotomists'],
          onClick: () => navigate('/admin/phlebotomists'),
          label: (
          <>
            - Phlebotomists
            <div className="menu-item-count">{counts.phlebotomists?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Providers', 
          urls: ['/admin/providers'],
          onClick: () => navigate('/admin/providers'),
          label: (
          <>
            - Providers
            <div className="menu-item-count">{counts.providers?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Staff', 
          urls: ['/admin/staffs'],
          onClick: () => navigate('/admin/staffs'),
          label: (
          <>
            - Staff
            <div className="menu-item-count">{counts.staff?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Admins', 
          urls: ['/admin/admins'],
          onClick: () => navigate('/admin/admins'),
          label: (
          <>
            - Admins
            <div className="menu-item-count">{counts.admins?.toLocaleString()}</div>
          </>
          )
        },
        {
          key: 'Facilities',
          label: (
            <>
              - Facilities
              <div className="menu-item-count">{counts.facilities?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/facilities'],
          onClick:() => navigate('/admin/facilities')
        }, 
      ]

      const resultItems = [
        {
          key: 'Test-Results',
          label: (
            <>
              - Test Results
              <div className="menu-item-count">{counts.results?.toLocaleString()}</div>
            </>
          ),
          // icon: <TableOutlined />,
          urls: ['/admin/results'],
          onClick:() => navigate('/admin/results')
        }, {
          key: 'reports',
          label: (
            <>
              - Reports
              <div className="menu-item-count">{counts.reports?.toLocaleString()}</div>
            </>
          ),
          // icon: <FileSearchOutlined />,
          urls: ['/admin/reports'],
          onClick:() => navigate('/admin/reports')
        }, 
        {
          key: 'Biomarkers',
          label: (
            <>
              - Biomarkers
              <div className="menu-item-count">{counts.tests?.toLocaleString()}</div>
            </>
          ),
          // icon: <ExperimentOutlined />,
          urls: ['/admin/tests'],
          onClick:() => navigate('/admin/tests')
        }, 
        {
          key: 'Files',
          label: (
            <>
              - File Archives
              <div className="menu-item-count">{counts.files?.toLocaleString()}</div>
            </>
          ),
          // icon: <FileOutlined />,
          urls: ['/admin/files'],
          onClick:() => navigate('/admin/files')
        }
      ]

      const productItems = [
        {
          key: 'Product-Orders',
          label: (
            <>
              - All Products
              <div className="menu-item-count">{counts.products?.toLocaleString()}</div>
            </>
          ),
          // icon: <ShopOutlined />,
          urls: ['/admin/products'],
          onClick:() => navigate('/admin/products')
        },

        {
          key: 'Test-Kits',
          label: (
            <>
              - Test Kits
              <div className="menu-item-count">{counts.kits?.toLocaleString()}</div>
            </>
          ),
          // icon: <ShopOutlined />,
          urls: ['/admin/kits'],
          onClick:() => navigate('/admin/kits')
        },

        {
          key: 'Prescriptions',
          label: (
            <>
              - Prescriptions
              <div className="menu-item-count">{counts.prescriptions?.toLocaleString()}</div>
            </>
          ),
          // icon: <PrescriptionOutlined/>,
          urls: ['/admin/prescriptions'],
          onClick:() => navigate('/admin/prescriptions')
        }, 
        {
          key: 'Memberships',
          label: (
            <>
              - Memberships
              <div className="menu-item-count">{counts.memberships?.toLocaleString()}</div>
            </>
          ),
          // icon: <KeyOutlined />,
          urls: ['/admin/memberships'],
          onClick:() => navigate('/admin/memberships')
        }, 
        {
          key: 'Gifts',
          label: (
            <>
              - Gifts
              <div className="menu-item-count">{counts.gifts?.toLocaleString()}</div>
            </>
          ),
          // icon: <GiftOutlined />,
          urls: ['/admin/gifts'],
          onClick:() => navigate('/admin/gifts')
        },
        {
          key: 'Referrals',
          label: (
            <>
              - Referrals
              <div className="menu-item-count">{counts.referrals?.toLocaleString()}</div>
            </>
          ),
          // icon: <SendOutlined />,
          urls: ['/admin/referrals'],
          onClick:() => navigate('/admin/referrals')
        },
  

        // { 
        //   key: 'Membership-Types', 
        //   urls: ['/admin/membership-types'],
        //   onClick: () => navigate('/admin/membership-types'),
        //   label: (
        //   <>
        //     - Membership Pricing
        //     <div className="menu-item-count">{counts.membershipTypes?.toLocaleString()}</div>
        //   </>
        //   )
        // },
        // { 
        //   key: 'Product-Types', 
        //   urls: ['/admin/product-types'],
        //   onClick: () => navigate('/admin/product-types'),
        //   label: (
        //   <>
        //     - Product Pricing
        //     <div className="menu-item-count">{counts.productTypes?.toLocaleString()}</div>
        //   </>
        //   )
        // }
      ]

      const starItems = [
        { 
          key: 'Patients', 
          urls: ['/', '/admin/patients'],
          onClick: () => navigate('/'),
          label: (
          <>
            - Patients
            <div className="menu-item-count">{counts.patients?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Members', 
          urls: ['/admin/members'],
          onClick: () => navigate('/admin/members'),
          label: (
          <>
            - Members
            <div className="menu-item-count">{counts.members?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Heart Health', 
          urls: ['/admin/hh'],
          onClick: () => window.open('/admin/hh', '_blank'),
          label: (
          <>
            - Heart Health
            <div className="menu-item-count">{counts.hhMembers?.toLocaleString()}</div>
          </>
          )
        },
        {
          key: 'calendar',
          label: (
            <>
              - Master Calendar
              <div className="menu-item-count">{counts.futureAppointments?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/calendar'],
          onClick:() => navigate('/admin/calendar')
        },
        {
          key: 'Test Kits',
          label: (
            <>
              - Test Kits
              <div className="menu-item-count">{counts.kits?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/kits'],
          onClick:() => navigate('/admin/kits')
        }, 
        {
          key: 'Test-Results',
          label: (
            <>
              - Test Results
              <div className="menu-item-count">{counts.results?.toLocaleString()}</div>
            </>
          ),
          // icon: <TableOutlined />,
          urls: ['/admin/results'],
          onClick:() => navigate('/admin/results')
        }, 
        // {
        //   key: 'reports',
        //   label: (
        //     <>
        //       - Reports
        //       <div className="menu-item-count">{counts.reports?.toLocaleString()}</div>
        //     </>
        //   ),
        //   // icon: <FileSearchOutlined />,
        //   urls: ['/admin/reports'],
        //   onClick:() => navigate('/admin/reports')
        // }, 
      ]

      return [
      {
        key: 'Starred',
        label: 'Starred',
        icon: <StarOutlined />,
        children: starItems
      }, 
  
      {
        key: 'Users',
        label: 'Users',
        icon: <UserOutlined />,
        children: userItems
      }, 
      {
        key: 'Appointments',
        label: 'Appointments',
        icon: <CalendarOutlined />,
        children: apptItems
      },
      {
        key: 'Results',
        label: 'Results',
        icon:  <ExperimentOutlined />,
        children: resultItems
      },
      {
        key: 'Products',
        label: 'Orders',
        icon: <ShopOutlined />,
        children: productItems
      },
      {
        key: 'Tasks',
        label: (
          <>
            Concierge Tasks
            <div className="menu-item-count">{counts.tasks?.toLocaleString()}</div>
          </>
        ),
        icon: <CheckSquareOutlined />,
        urls: ['/admin/tasks'],
        onClick:() => navigate('/admin/tasks')
      },

      {
        key: 'Billing',
        label: (
          <>
            Billing
            <div className="menu-item-count">{counts.charges?.toLocaleString()}</div>
          </>
        ),
        icon: <DollarCircleOutlined />,
        urls: ['/billing'],
        onClick:() => navigate('/billing')
      }, 
      // {
      //   key: 'Logout',
      //   label: 'Logout',
      //   icon: <LogoutOutlined />,
      //   urls: ['/logout'],
      //   onClick:() => navigate('/logout')
      // }
      ]
    } 
    else if (currentUser?.role === Role.PROVIDER) {
      let providerItems = [
        
      {
        key: 'Patients',
        label: (
          <>
            Patients
            <div className="menu-item-count">{counts.patients?.toLocaleString()}</div>
        </>
        ),
        icon: <UserOutlined />,
        urls: ['/provider/patients'],
        onClick:() => navigate('/provider/patients')
      }, 
      {
        key: 'Blood-Draws',
        label: (
          <>
            Appointments
            <div className="menu-item-count">{counts.draws?.toLocaleString()}</div>
          </>
        ),
        icon: <CalendarOutlined />,
        urls: ['/provider/appointments'],
        onClick:() => navigate('/provider/appointments')
      },       
      
      counts.consults > 0 && {
        key: 'Consults',
        label: (
          <>
            Consults
            <div className="menu-item-count">{counts.consults?.toLocaleString()}</div>
          </>
        ),
        icon: <VideoCameraOutlined />,
        urls: ['/provider/consults'],
        onClick:() => navigate('/provider/consults')
      }, 
      {
        key: 'Kits',
        label: (
          <>
            Test Kits
            <div className="menu-item-count">{counts.kits?.toLocaleString()}</div>
          </>
        ),
        icon: <TubeOutlined />,
        urls: ['/provider/kits'],
        onClick:() => navigate('/provider/kits')
      }, 

      {
        key: 'Test-Results',
        label: (
          <>
            Test Results
            <div className="menu-item-count">{counts.results?.toLocaleString()}</div>
          </>
        ),
        icon: <TableOutlined />,
        urls: ['/provider/results'],
        onClick:() => navigate('/provider/results')
      }, 
      {
        key: 'reports',
        label: (
          <>
            Reports
            <div className="menu-item-count">{counts.reports?.toLocaleString()}</div>
          </>
        ),
        icon: <FileSearchOutlined />,
        urls: ['/provider/reports'],
        onClick:() => navigate('/provider/reports')
      },
      // {
      //   key: 'referrals',
      //   label: (
      //     <>
      //       Referrals 
      //       <div className="menu-item-count">{counts.referrals?.toLocaleString()}</div>
      //     </>
      //   ),
      //   icon: <SendOutlined />,
      //   urls: ['/provider/referrals'],
      //   onClick:() => navigate('/provider/referrals')
      // }
      ]

      if (currentUser?.chargePersonalCard) {
        providerItems.push({
          key: 'Billing',
          label: (
            <>
              Billing
              <div className="menu-item-count">{counts.charges?.toLocaleString()}</div>
            </>
          ),
          icon: <DollarCircleOutlined />,
          urls: ['/billing'],
          onClick:() => navigate('/billing')
        })
      }

      if (currentUser?.permissions?.includes(Permission.PRESCRIPTIONS)) {
        providerItems.push({
          key: 'Prescriptions',
          label: (
            <>
              Prescriptions
              <div className="menu-item-count">{counts.prescriptions?.toLocaleString()}</div>
            </>
          ),
          icon: <EditOutlined />,
          urls: ['/provider/prescriptions'],
          onClick:() => navigate('/provider/prescriptions')
        })
      }
    
      providerItems = [        
      {
        key: 'Test-Catalog',
        label: (
          <>
            Order Test
          </>
        ),
        icon: <ShopOutlined/>,
        urls: ["/", '/provider/catalog'],
        onClick:() => navigate('/provider/catalog')
      },  
      // {
      //   key: 'Availability',
      //   label: (
      //     <>
      //       Check Availability
      //     </>
      //   ),
      //   icon: <SearchOutlined />,
      //   urls: [`/pro-flow/${FlowType.PRO_AVAILABILITY}`],
      //   onClick:() => navigate(`/pro-flow/${FlowType.PRO_AVAILABILITY}`)
      // },  

      {
        type: 'divider'
      },

        ...providerItems,
        {
          type: 'divider'
        },
        {
          key: 'Contact',
          label: (
            <>
              Help
            </>
          ),
          icon:  <MessageOutlined />,
          onClick:() => {
            window.FrontChat("show")
          }
        }, 
        {
          key: 'Logout',
          label: 'Logout',
          icon: <LogoutOutlined />,
          urls: ['/logout'],
          onClick:() => navigate('/logout')
        }
      ]

      return providerItems
    } 
    else if (currentUser?.role === Role.PHLEBOTOMIST) {
      return [{
        key: 'Tasks',
        label: (
          <>
            Concierge Tasks
            <div className="menu-item-count">{counts.tasks?.toLocaleString()}</div>
          </>
        ),
        icon: <CheckSquareOutlined />,
        urls: ['/phlebotomist/tasks'],
        onClick:() => navigate('/phlebotomist/tasks')
      }]
    }
    else if (currentUser?.role === Role.STAFF) {
      return [{
        key: 'Tasks',
        label: (
          <>
            Concierge Tasks
            <div className="menu-item-count">{counts.tasks?.toLocaleString()}</div>
          </>
        ),
        icon: <CheckSquareOutlined />,
        urls: ['/staff/tasks'],
        onClick:() => navigate('/staff/tasks')
      }, 
      { 
        key: 'Heart Health',
        label: (
          <>
            Heart Health
          </>
        ),
        icon: <HeartOutlined />,
        urls: ['/staff/hh'],
        onClick:() => window.open('/staff/hh', '_blank')
      },

      {
        key: 'Lab Orders',
        label: (
          <>
            Lab Orders
            {/* <div className="menu-item-count">{counts.orders?.toLocaleString()}</div> */}
          </>
        ),
        icon: <ProfileOutlined />,
        urls: ['/staff/orders'],
        onClick:() => navigate('/staff/orders')
      },
      { 
        key: 'Appointments', 
        urls: ['/staff/appointments'],
        onClick: () => navigate('/staff/appointments'),
        icon: <CalendarOutlined/> ,
        label: (
        <>
          Appointments
        </>
        )
      }, 
      { 
        key: 'Phlebotomists', 
        urls: ['/staff/phlebotomists'],
        onClick: () => navigate('/staff/phlebotomists'),
        icon: <UserOutlined/> ,
        label: (
        <>
          Phlebotomists
        </>
        )
      },
      { 
        key: 'Providers', 
        urls: ['/staff/providers'],
        onClick: () => navigate('/staff/providers'),
        icon: <DoctorOutlined/>,
        label: (
        <>
          Providers
          <div className="menu-item-count">{counts.providers?.toLocaleString()}</div>
        </>
        )
      },
      {
        key: 'Facilities',
        icon: <BankOutlined/>,
        label: (
          <>
            Facilities
            <div className="menu-item-count">{counts.facilities?.toLocaleString()}</div>
          </>
        ),
        urls: ['/staff/facilities'],
        onClick:() => navigate('/staff/facilities')
      }, 
      {
        key: 'Referrals',
        label: (
          <>
            Referrals
            <div className="menu-item-count">{counts.referrals?.toLocaleString()}</div>
          </>
        ),
        icon: <SendOutlined />,
        urls: ['/staff/referrals'],
        onClick:() => navigate('/staff/referrals')
      },
      {
        type: 'divider'
      }, {
        key: 'Logout',
        label: 'Logout',
        icon: <LogoutOutlined />,
        urls: ['/logout'],
        onClick:() => navigate('/logout')
      }]
    }
    else if (currentUser?.role === Role.PATIENT && currentUser?.email) {

      let careItems = [
        {
          key: 'order-test',
          label: <>- Order Test</>,
          urls: ["/patient/catalog"],
          onClick:() => navigate('/patient/catalog'),
        },
        {
          key: 'book-consult',
          label: <>- Longevity Consult</>,
          urls: [`/flow/${FlowType.LONGEVITY_CONSULT}`],
          onClick:() => navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`),
        },
        MembershipHelper.getActiveHHMembership(currentUser?.memberships) && {
          key: 'book-hh-consult',
          label: <>- Heart Health Consult</>,
          urls: [`/flow/${FlowType.HEART_MEMBER_CONSULT}`],
          onClick:() => navigate(`/flow/${FlowType.HEART_MEMBER_CONSULT}`),
        },
        // {
        //   key: 'request-referral',
        //   label: <>- Specialist Referral</>,
        //   urls: [`/flow/${FlowType.REFERRAL}`],
        //   onClick:() => navigate(`/flow/${FlowType.REFERRAL}`),
        // },
        {
          key: 'message-concierge',
          label: <>- Care Concierge</>,
          onClick:() => window.FrontChat("show")
        },
        (!membership || !MembershipHelper.isActive(membership)) && {
          key: 'join-as-member',
          label: <>- Join as Member</>,
          // urls: ['/patient/memberships'],
          onClick:() => navigate('/patient/memberships'),

        }

      ];


      let orderItems = [
        {
          key: 'blood-draws',
          label: "- Appointments",
          // icon: <CalendarOutlined/>,
          urls: [ "/patient/appointments"],
          onClick: () => navigate("/patient/appointments")
        },
        // {
        //   key: 'consultations',
        //   label: '- Consults',
        //   urls: ["/patient/consults"],
        //   // icon: <DoctorOutlined/>,
        //   onClick: () => navigate("/patient/consults"),
        // },
        {
          key: 'test-kits',
          label: "- Test Kits",
          urls: [ "/patient/kits"],
          // icon: <TubeOutlined/>,
          onClick: () => navigate("/patient/kits")
        },

        {
          key: 'Treatments',
          label: (
            <>
              - Prescriptions
            </>
          ),
          // icon: <PrescriptionOutlined/>,
          urls: ['/patient/treatments'],
          onClick:() => navigate('/patient/treatments')
        },
      ];


      //   {
      //     key: 'Profile',
      //     label: (
      //       <>
      //         - Profile
      //       </>
      //     ),
      //     // icon: <IdcardOutlined />,
      //     urls: ['/patient/profile'],
      //     onClick:() => navigate('/patient/profile')
      //   },
      //   {
      //     key: 'Membership',
      //     label: (
      //       <>
      //         - Membership
      //       </>
      //     ),
      //     // icon: <IdcardOutlined />,
      //     urls: ['/patient/memberships'],
      //     onClick:() => navigate('/patient/memberships')
      //   },
      //   {
      //     key: 'Billing',
      //     label: (
      //       <>
      //         - Payments
      //       </>
      //     ),
      //     // icon: <DollarCircleOutlined />,
      //     urls: ['/billing'],
      //     onClick:() => navigate('/billing')
      //   }
      // ];
    
      let myHealthItems=[];

      if (counts.results?.toLocaleString() > 0) {
          myHealthItems = [...myHealthItems, {
          key: 'Test-Results',
          label: (
            <>
              Test Results
            </>
          ),
          icon: <TableOutlined />,
          urls: ['/patient/results'],
          onClick:() => navigate('/patient/results')
        }]
      }

      if (counts?.reports>0) {
        myHealthItems = [...myHealthItems, 
          {
            key: 'Latest-Report',
            label: 'Latest Report',
            icon: <FileSearchOutlined />,
            urls: ['/patient/report'],
            onClick:() => navigate('/patient/report')
          },
          {
            key: 'Game-Plan',
            label: 'Game Plan',
            icon: <CheckSquareOutlined />,
            urls: ['/patient/plan'],
            onClick:() => navigate('/patient/plan')
          },
        ]
      }


      myHealthItems.push(
        {
        key: 'Medical-Information',
        label: 'Health Profile',
        icon: <UserOutlined/>,
        urls: ['/patient/medical'],
        onClick:() => navigate('/patient/medical')
      },
      {
        key: 'Files',
        label: (
          <>
            Health Files
          </>
        ),
        icon: <FileOutlined />,
        urls: ['/patient/files'],
        onClick:() => navigate('/patient/files')
      })

      const toolItems = [
        {
          key: 'DEXA Analysis',
          urls: ['/tools/dexa'],
          onClick: () => window.open('/tools/dexa', '_blank'),
          label: (
            <>
              - DEXA Analysis
            </>
          )
        },
        {
          
            key: 'Exposure', 
            urls: ['/exposure/launch'],
            onClick: () => window.open('/exposure/launch', '_blank'),
            label: (
            <>
              - Heart Attack Risk
            </>
            )
        },
        {
          key: 't-decline',
          urls: ['/testosteorne-projection'],
          onClick: () => window.open('/testosterone-projection', '_blank'),
          label: (
            <>
              - Testosterone Decline
            </>
          )
        }
      ]

      // sidebar layout
      let items = [
        
      {
        key: 'Home',
        label: <>Home</>,
        icon: <HomeOutlined/>,
        urls: ['/', "/patient/home"],
        onClick:() => navigate('/patient/home'),
      },
      {
        key: 'order-test-sidebar',
        label: 'Order Test',
        icon: <ShopOutlined/>,
        urls: ["/patient/catalog"],
        onClick:() => navigate('/patient/catalog'),
      },
      {
        key: 'Gift-Cards',
        label: 'Gift Instalab',
        icon: <GiftOutlined/>,
        onClick: () => navigate(`/flow/${FlowType.GIFT}`)
      },
      {
        type: 'divider'
      },
      ...myHealthItems,
      {
        type: 'divider'
      },
      {
        key: 'orders',
        label: 'My Orders',
        icon: <ProfileOutlined />,
        children: [...orderItems]
      },
      {
        key: 'tools',
        label: (
          <>
            Tools
          </>
        ),
        icon: <ToolOutlined/>,
        children: [...toolItems]
      },
      {
        key: 'get-care',
        label: 'Get Care',
        icon: <PlusCircleOutlined/>,
        children: [...careItems]
      },

        {
          key: 'account',
          label: 'Settings',
          icon: <SettingOutlined/>,
          onClick: () => navigate('/settings')
        },
        {
          key: 'help',
          label: (
            <>
             Help
            </>
          ),
          icon:  <MessageOutlined />,
          onClick: () => navigate('/help')
          // onClick:() => window.FrontChat("show")
        },         


      {
        key: 'Logout',
        label: 'Logout',
        icon: <LogoutOutlined />,
        urls: ['/logout'],
        onClick:() => navigate('/logout')
      }]

      return items
    } 
    else {
      return [{ 
        key: 'Home',
        label: 'Home',
        icon: <HomeOutlined />,
        urls: ['/'],
        onClick:() => navigate('/')
      }, 
      {
        key: 'test-catalog',
        label: 'Test Catalog',
        icon: <TubeOutlined/>,
        urls: ['/catalog'],
        onClick:() => navigate('/catalog'), 
      }, 
      {
        key: 'Concierge',
        label: 'Concierge',
        icon: <CustomerServiceOutlined/>,
        onClick: () => navigate('/concierge')
      },
      {
        key:'heart-health-program',
        label: 'Heart Health',
        icon: <HeartOutlined/>,
        urls: ['/heart'],
        onClick:() => navigate('/heart'),
      },
      // {
      //   key: 'physician',
      //   label: 'Talk to Physician',
      //   icon: <DoctorOutlined/>,
      //   onClick: () => navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`)
      // },  

      {
        key: 'Gift-Cards',
        label: 'Gift Instalab',
        icon: <GiftOutlined/>,
        onClick: () => navigate(`/flow/${FlowType.GIFT}`)
      },


      {
        type: 'divider'
      }, 
      {
        key: 'Login',
        label: 'Login',
        icon: <LoginOutlined />,
        urls: ['/login'],
        onClick:() => navigate('/login')
      }, {
        type: 'divider'
      }, 
      { 
        key: 'help',
        label: 'Help',
        icon: <MessageOutlined />,
        onClick:() => window.FrontChat("show")
      }, 

            {
        key: 'Research',
        label: 'Research',
        icon: <ReadOutlined />,
        urls: ['/research'],
        onClick:() => window.open('/research', '_blank')
      }, {
        key: 'Terms',
        label: 'Terms',
        icon: <FileDoneOutlined />,
        urls: ['/terms'],
        onClick:() => navigate('/terms')
      }, 
      // {
      //   key: 'Privacy',
      //   label: 'Privacy',
      //   icon: <FileDoneOutlined />,
      //   urls: ['/privacy'],
      //   onClick:() => navigate('/privacy')
      // }
      ]
    }
  }
}

export default LayoutHelper
