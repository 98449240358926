import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, Input, Button, message } from 'antd'
import './signup.scss'
import { UserContext } from '../../contexts/user.context.js';
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper';
import StorageKey from '../../enums/storageKey.enum.js';
import { buildUser } from '../../services/user.service';

const { Item } = Form

export const Signup = ({ isModal, onSignupSuccess, redirectPath, title }) => {
  const [form] = Form.useForm()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState()
  const { setToken, token } = useContext(UserContext)
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    if (!isModal) {
      document.title = 'Instalab | Create Account'
      document.description = 'Create a new account.'
    }
  }, [isModal])

  useEffect(() => {
    if (token) {
      if (onSignupSuccess) {
        onSignupSuccess()
      }
      const redirect = isModal ? redirectPath : searchParams.get('redirect')
      navigate(redirect || '/')
    }
  }, [token, isModal, redirectPath])

  const onFail = () => {
    setHasAttempt(true)
    message.error('Please fill in all required fields correctly')
  }

  const onFinish = async () => {
    setIsLoading(true)
    try {
      const values = form.getFieldsValue()
      const response = await buildUser({
        fields: values,
        select: '_id email firstName lastName role',
        populate: []
      })
      
      localStorage.setItem(StorageKey.TOKEN, response.token)
      setToken(response.token)
      message.success('Account created!')
    } catch (err) {
      message.error(err.response?.data?.message || 'Failed to create account')
    }
    setIsLoading(false)
  };

  return <div className={`signup-page ${isModal ? 'modal-signup' : ''}`}>
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFail}
      layout='vertical'
      className="signup-form"
    >
      <h1>{title || 'Create Account'}</h1>
      
      <Item 
        label="First Name"
        name="firstName"
        rules={[RuleHelper.isRequired]}
        validateTrigger='onSubmit'
      >
        <Input 
          placeholder="First Name" 
          onChange={() => {
            if (hasAttemptRef.current) {
              FormHelper.fetchHasError(form)
            }
          }}
        />
      </Item>

      <Item 
        label="Last Name"
        name="lastName"
        rules={[RuleHelper.isRequired]}
        validateTrigger='onSubmit'
      >
        <Input 
          placeholder="Last Name" 
          onChange={() => {
            if (hasAttemptRef.current) {
              FormHelper.fetchHasError(form)
            }
          }}
        />
      </Item>

      <Item 
        label="Email Address"
        name="email"
        onInput={e => e.target.value = e.target.value.toLowerCase()}
        rules={[
          RuleHelper.isRequired,
          RuleHelper.isEmail,
          RuleHelper.isUniqueEmail({
            fieldName: 'email',
          })
        ]}
        validateTrigger='onSubmit'
      >
        <Input 
          placeholder="Email Address" 
          onChange={() => {
            if (hasAttemptRef.current) {
              FormHelper.fetchHasError(form)
            }
          }}
        />
      </Item>

      <Item 
        label="Password"
        name="password"
        rules={[
          RuleHelper.isRequired,
          { min: 8, message: 'Password must be at least 8 characters' }
        ]}
        validateTrigger='onSubmit'
      >
        <Input.Password 
          placeholder="Password" 
          onChange={() => {
            if (hasAttemptRef.current) {
              FormHelper.fetchHasError(form)
            }
          }}
        />
      </Item>

      <Item>
        <Button 
          htmlType='submit'
          type="primary" 
          loading={isLoading}
        >
          Create Account
        </Button>
      </Item>
    </Form>
  </div>
} 